import { createProvider } from '@frontend/store';
import { SendFormStep, SendFormsOrigin } from '../types';
import { FormsSendingExperienceStore, AppointmentInfo } from './types';

/**
 * This store (context) is used to hold the state of the WritebackWidget
 */
export const { Provider: SendFormsStoreProvider, useStore: useSendFormsStore } =
  createProvider<FormsSendingExperienceStore>()((set, get) => ({
    formOrPacketToSend: undefined,
    setFormOrPacketToSend: (document) => set({ formOrPacketToSend: document }),

    solicitedLink: '',
    setSolicitedLink: (solicitedLink) => set({ solicitedLink }),

    submissionToken: '',
    setSubmissionToken: (submissionToken) => set({ submissionToken }),

    isCreatingTransientForm: false,
    setIsCreatingTransientForm: (isCreatingTransientForm) => set({ isCreatingTransientForm }),

    currentStep: SendFormStep.SELECT_CONTACT,
    setCurrentStep: (step) => set({ currentStep: step }),

    steps: [],
    setSteps: (steps) => set({ steps }),

    getStepData: () => {
      const { currentStep, steps } = get();
      return {
        currentStep,
        steps,
      };
    },

    isSending: false,
    setIsSending: (isSending) => set({ isSending }),

    isGeneratingSolicitedLink: false,
    setIsGeneratingSolictedLink: (isGeneratingSolicitedLink) => set({ isGeneratingSolicitedLink }),

    personId: '',
    setPersonId: (personId) => set({ personId }),

    firstName: '',
    setFirstName: (firstName) => set({ firstName }),

    lastName: '',
    setLastName: (lastName) => set({ lastName }),

    fullName: '',
    setFullName: (fullName) => set({ fullName }),

    appointmentInfo: null,
    setAppointmentInfo: (info: AppointmentInfo) =>
      set(() => ({
        appointmentInfo: { ...info },
      })),
    clearAppointmentInfo: () =>
      set({
        appointmentInfo: null,
      }),

    messageMode: '',
    setMessageMode: (messageMode) => set({ messageMode }),

    appointmentId: '',
    setAppointmentId: (appointmentId) => set({ appointmentId }),

    acceptedFiles: [],
    setAcceptedFiles: (files) => set({ acceptedFiles: files }),

    rejectedFiles: [],
    setRejectedFiles: (files) => set({ rejectedFiles: files }),

    clearStore: (origin) => {
      set((state) => {
        const hasAtLeastOneLocation = state.formsEnabledLocationIds.length > 0;
        const hasMultipleLocations = state.formsEnabledLocationIds.length > 1;
        let newCurrentStep = SendFormStep.SELECT_CONTACT;
        if (origin === SendFormsOrigin.CONTACTS) {
          newCurrentStep = SendFormStep.SELECT_DOCUMENT;
        }

        if (hasMultipleLocations) {
          newCurrentStep = SendFormStep.SELECT_LOCATION;
        }

        return {
          solicitedLink: '',
          submissionToken: '',
          isCreatingTransientForm: false,
          currentStep: newCurrentStep,
          isSending: false,
          isGeneratingSolicitedLink: false,
          firstName: '',
          lastName: '',
          appointmentInfo: null,
          messageMode: '',
          appointmentId: '',
          acceptedFiles: [],
          rejectedFiles: [],
          isUploadInProgress: false,
          email: '',
          emailBody: '',
          emailSubject: 'Please complete these forms',
          showMessageComponent: true,
          fullName: '',
          selectedDeliveryOption: undefined,
          locationId: hasAtLeastOneLocation ? state.formsEnabledLocationIds[0] : '',
        };
      });
    },

    email: '',
    setEmail: (email) => set({ email }),

    emailBody: '',
    setEmailBody: (emailBody) => set({ emailBody }),

    emailSubject: 'Please complete these forms',
    setEmailSubject: (emailSubject) => set({ emailSubject }),

    getEmailPayload: () => {
      const { email, emailSubject, emailBody } = get();
      return {
        email,
        emailSubject,
        emailBody,
      };
    },

    showMessageComponent: true,
    setShowMessageComponent: (showMessageComponent) => set({ showMessageComponent }),

    isUploadInProgress: false,
    setIsUploadInProgress: (isUploadInProgress) => set({ isUploadInProgress }),

    temporarilyHideModal: false,
    setTemporarilyHideModal: (temporarilyHideModal) => set({ temporarilyHideModal }),

    selectedDeliveryOption: undefined,
    setSelectedDeliveryOption: (deliveryOption) => set({ selectedDeliveryOption: deliveryOption }),

    locationId: '',
    setLocationId: (locationId) => set({ locationId }),

    areFormsEnabledLocationIdsSet: false,
    formsEnabledLocationIds: [],
    setFormsEnabledLocationIds: (locationIds) =>
      set({
        formsEnabledLocationIds: locationIds,
        areFormsEnabledLocationIdsSet: true,
      }),

    getGenerateLinkPayload: () => {
      const { formOrPacketToSend, acceptedFiles } = get();
      return {
        formOrPacketToSend,
        acceptedFiles,
      };
    },
  }));
