import { useTranslation } from '@frontend/i18n';
import { useInvalidateDiscoverReaderQuery } from '@frontend/payments-collection-flow';
import { useMerchant } from '@frontend/payments-hooks';
import { RegisterTerminals } from '@frontend/payments-register-terminal';
import { Button, useModalControl } from '@frontend/design-system';
import { TerminalAddressModal, TerminalAddressModalMode } from './terminal-address';

export const TerminalSettingsAction = () => {
  const { t } = useTranslation('payments');
  const { merchant, locationId, paymentsUrl, stripeLocationId } = useMerchant();
  const { modalProps, openModal, closeModal } = useModalControl();
  const {
    modalProps: addressModalProps,
    openModal: openAddressModal,
    closeModal: closeAddressModal,
  } = useModalControl();

  const { invalidateQuery } = useInvalidateDiscoverReaderQuery(locationId || '');

  const onRegisterTerminalClick = () => {
    if (merchant?.hasTerminalLocation) {
      openModal();
    } else {
      openAddressModal();
    }
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
    openModal();
  };

  return (
    <>
      <Button
        variant='secondary'
        onClick={onRegisterTerminalClick}
        size={'small'}
        css={{ width: 'max-content' }}
        iconName='plus'
      >
        {t('Register Terminal')}
      </Button>
      <RegisterTerminals
        modalProps={modalProps}
        closeModal={closeModal}
        onTerminalRegistered={invalidateQuery}
        paymentsUrl={paymentsUrl}
        locationId={locationId}
        stripeLocationId={stripeLocationId}
      />
      <TerminalAddressModal
        modalProps={addressModalProps}
        onSubmit={handleAddressModalClose}
        mode={TerminalAddressModalMode.Add}
      />
    </>
  );
};
