import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { PortingTypes } from '@frontend/api-porting';
import { scopedFilterAndSort } from '@frontend/filters';
import { i18next, Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ScopedLocationFilterMenu } from '@frontend/location-filter-menu';
import { usePhoneLocationFilterShallowStore } from '@frontend/phone';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { PhoneNumberSchemaService } from '@frontend/schema';
import { useAppScopeStore, useOnScopeChange } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Button,
  Chip,
  ConfirmationModal,
  Modal,
  SwitchChipGroup,
  Table,
  TableColumnConfig,
  Text,
  TextLink,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { CapabilitiesType, PhoneStatusType, NewUsageType } from '../../types';
import { TableFilterPanel } from './table-filter-panel';
import { usePhoneNumbersTableMutations } from './use-phone-numbers-table-mutations';
import { WeaveNumberModal } from './weave-number-modal';

export const PhoneNumbersTable = () => {
  const { t } = useTranslation('porting');
  const [tableFilters, setTableFilters] = useState<PortingTypes.PhoneNumberType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const weaveNumberModalControl = useModalControl();
  const { selectedLocationIds, accessibleLocationData, getScopeName, selectedGroupId, isSingleTypeScope } =
    useAppScopeStore();
  const { locationFilters, setLocationFilters } = usePhoneLocationFilterShallowStore(
    'locationFilters',
    'setLocationFilters'
  );
  const filteredLocations = locationFilters.phoneNumbersIndex;

  useOnScopeChange(() => {
    setLocationFilters({ phoneNumbersIndex: [] });
  });

  const locations = useMemo<PortingTypes.LocationInfo[]>(() => {
    const locationDataMap = accessibleLocationData;
    return selectedLocationIds.map((locationId) => {
      const locationData = locationDataMap[locationId];
      return {
        locationId,
        locationName: locationData?.name ?? '',
        locationSlug: locationData?.slug ?? '',
      };
    });
  }, [selectedLocationIds, accessibleLocationData]);
  const locationIds = locations.map((location) => location.locationId);
  const alerts = useAlert();
  const [selectedRow, setSelectedRow] = useState<PortingTypes.NumberInfo | null>(null);
  const { modalProps: registerModalProps, triggerProps: registerTriggerProps } = useModalControl();
  const { modalProps: setDefaultSmsProps, triggerProps: setDefaultSmsTriggerProps } = useModalControl();
  const { modalProps: tenDlcInfoModalProps, triggerProps: tenDlcInfoTriggerProps } = useModalControl();
  const { setLocationSms, register } = usePhoneNumbersTableMutations(
    selectedRow?.locationId ? [selectedRow.locationId] : []
  );

  const {
    data: phoneNumbersData = [],
    isLoading: isDataLoading,
    isRefetching: isDataRefetching,
  } = useQuery({
    queryKey: ['phoneNumbers', locationIds],
    queryFn: () =>
      PhoneNumberSchemaService.ListPhoneNumbersForLocations({ locationIds }).then((res) => res.phoneNumbers),
    staleTime: 0,
  });

  const extendedPhoneNumbersData = useMemo(
    () =>
      phoneNumbersData.map((item) => ({
        ...item,
        name: item.phoneNumber.nationalNumber?.toString() ?? '',
        locationId: item.locationId,
      })),
    [phoneNumbersData]
  );

  const registerNumber = () =>
    register.mutate(
      {
        phoneNumber: selectedRow?.allStatusDetails.phoneNumber ?? '',
        locationId: selectedRow?.locationId ?? '',
        cnp: selectedRow?.allStatusDetails.cnp,
      },
      {
        onSuccess: (responseDetails) => {
          const { statusDetails } = responseDetails;
          const { status, details } = statusDetails;
          if (status === PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERED) {
            alerts.success({
              message: `10DLC registration process initiated.`,
            });
          } else if (
            status === PhoneStatusType.PHONE_REGISTRATION_STATUS_PENDING ||
            status === PhoneStatusType.PHONE_REGISTRATION_STATUS_IN_PROGRESS
          ) {
            alerts.warning({
              message: `Phone number registration is pending. Please check back later.`,
            });
          } else {
            alerts.error({
              message: `${details ? `${details}` : t('System failed to register phone number. Please try again.')}`,
            });
          }
        },
        onError: () => {
          alerts.error(t('System failed to register phone number. Please try again.'));
        },
      }
    );

  const setDefaultSms = () =>
    setLocationSms.mutate(
      { phoneNumberId: selectedRow?.id ?? '', locationId: selectedRow?.locationId ?? '' },
      {
        onSuccess: () => {
          alerts.success(t('Default SMS number assigned successfully.'));
        },
        onError: (error: any) => {
          let errorMessage = t('System failed to update phone number. Please try again.');
          const errorDataString = error?.response?.data?.message;
          const errorData = errorDataString ? JSON.parse(errorDataString) : {};
          const cause = errorData.cause || t('Unknown cause');
          const message = errorData.messages?.[0] || t('No additional details provided.');

          if (message === 'error assigning default sms to department phone number') {
            errorMessage = t(
              'The phone number cannot be set as the default because it is currently in use by another department.'
            );
          } else {
            errorMessage = `${t('System failed to update phone number.')} ${t('Cause')}: ${cause} - ${t(
              'Details'
            )}: ${message}`;
          }

          alerts.error(errorMessage);
        },
      }
    );

  function getPhoneNumberType(data: PortingTypes.NumberInfo) {
    const { faxProvider, smsProvider, voiceProvider } = data.capabilities;
    if (faxProvider) {
      return PortingTypes.PhoneNumberType.Fax;
    }
    if (smsProvider || voiceProvider) {
      return PortingTypes.PhoneNumberType.VoiceOrSMS;
    }
    throw new Error('No valid type found');
  }

  function getNewPhoneNumberTypes(data: PortingTypes.NumberInfo): PortingTypes.NewPhoneNumberType[] {
    const { faxProvider, smsProvider, voiceProvider } = data.capabilities;
    const types: PortingTypes.NewPhoneNumberType[] = [];

    if (faxProvider) types.push(PortingTypes.NewPhoneNumberType.Fax);
    if (smsProvider) types.push(PortingTypes.NewPhoneNumberType.SMS);
    if (voiceProvider) types.push(PortingTypes.NewPhoneNumberType.Voice);

    return types;
  }

  const filteredPhoneNumbersData = useMemo(() => {
    const noFilterConditions =
      !searchValue && !tableFilters.length && (!filteredLocations.length || !selectedGroupId) && !selectedTypes.length;

    const filtered = scopedFilterAndSort({
      data: extendedPhoneNumbersData,
      filteredScopeIds: filteredLocations,
      scopeIds: selectedLocationIds,
      scopeIdAccessor: (item) => [item.locationId],
    });

    if (noFilterConditions) {
      return filtered;
    }
    const searchText = searchValue?.toLocaleLowerCase() ?? '';
    const phoneNumberSearchText = searchText.replace(/[()-\s]/g, '');

    return filtered.filter((item) => {
      const types = getNewPhoneNumberTypes(item);

      const matchesType =
        !selectedTypes.length ||
        selectedTypes.some((type) => types.map((t) => t.toLowerCase()).includes(type.toLowerCase()));

      const matchesFilter = !tableFilters.length || tableFilters.includes(getPhoneNumberType(item));
      const matchesLocation =
        !selectedGroupId || !filteredLocations.length || filteredLocations.includes(item.locationId);
      const matchesSearch =
        !searchValue ||
        item.locationId === searchValue ||
        (phoneNumberSearchText && item.phoneNumber.nationalNumber?.toString().includes(phoneNumberSearchText)) ||
        getScopeName(item.locationId).toLocaleLowerCase().includes(searchText);

      return matchesType && matchesFilter && matchesLocation && matchesSearch;
    });
  }, [extendedPhoneNumbersData, tableFilters, searchValue, filteredLocations, selectedTypes]);

  const newColConfig: TableColumnConfig<PortingTypes.NumberInfo & { name: string; locationId: string }>[] = [
    {
      id: 'phoneNumber',
      Header: t('Phone Numbers'),
      accessor: ({ phoneNumber, usageType }) => ({ phoneNumber, usageType }),
      cellRenderer: ({ phoneNumber, usageType }) => (
        <>
          {formatPhoneNumber(phoneNumber?.nationalNumber?.toString() ?? '')}
          {usageType === NewUsageType.USAGE_TYPE_WEAVE_NUMBER && (
            <Chip css={{ maxWidth: 'fit-content', marginLeft: theme.spacing(1) }}>{t('Temp Number')}</Chip>
          )}
        </>
      ),
    },
    ...(!!selectedGroupId
      ? [
          {
            id: 'locationName',
            Header: t('Location'),
            accessor: (item: PortingTypes.NumberInfo) => item.locationId,
            cellRenderer: (item: string) => <Chip.SingleChip>{getScopeName(item)}</Chip.SingleChip>,
          },
        ]
      : []),
    {
      id: 'capabilities',
      Header: t('Capabilities'),
      accessor: ({ capabilities, defaultSms }: { capabilities: CapabilitiesType; defaultSms: boolean }) => {
        if (!capabilities) {
          return { capabilities: [], defaultSms };
        }
        const capabilityMapping: Record<keyof CapabilitiesType, string> = {
          faxProvider: t('Fax'),
          voiceProvider: t('Voice'),
          smsProvider: t('SMS'),
        };
        const mappedCapabilities = (Object.keys(capabilityMapping) as Array<keyof CapabilitiesType>)
          .filter((key) => capabilities[key])
          .map((key) => capabilityMapping[key]);
        return { capabilities: mappedCapabilities, defaultSms };
      },
      cellRenderer: ({ capabilities, defaultSms }) => (
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          {capabilities.map((capability: string) => (
            <Chip
              variant={capability === 'SMS' && defaultSms ? 'primary' : 'neutral'}
              key={capability.toLowerCase()}
              leftElement={capability === 'SMS' && defaultSms ? <Icon name='star' size={16} /> : undefined}
              tooltipLabel={
                capability === 'SMS' && defaultSms && t('This is the default SMS number for this location.')
              }
            >
              {capability}
            </Chip>
          ))}
        </div>
      ),
    },
    {
      disableSortBy: true,
      id: 'status',
      Header: () => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Text size='medium' css={{ color: theme.colors.neutral40 }} as='span'>
            {t('10DLC Status')}
          </Text>
          <Button iconName='info' variant='secondary' onClick={tenDlcInfoTriggerProps.onClick} />
        </div>
      ),
      accessor: (item) => item,
      cellRenderer: (item) => {
        const smsProvider = item?.capabilities?.smsProvider;
        const isParentLocation = !isSingleTypeScope && selectedGroupId === item.locationId;

        const statusData = !smsProvider
          ? {
              label: t('N/A'),
              chip: (
                <Chip
                  variant='neutral'
                  tooltipLabel={t('This number is not SMS capable, so it cannot be registered for 10DLC.')}
                >
                  {t('N/A')}
                </Chip>
              ),
            }
          : isParentLocation
          ? statusMapping[PhoneStatusType.PHONE_REGISTRATION_STATUS_UNREGISTERABLE]
          : statusMapping[item?.allStatusDetails?.status as PhoneStatusType] || {
              label: t('N/A'),
              chip: (
                <Chip
                  variant='neutral'
                  tooltipLabel={t('This number is not SMS capable, so it cannot be registered for 10DLC.')}
                >
                  {t('N/A')}
                </Chip>
              ),
            };

        return statusData.chip;
      },
    },
  ];

  return (
    <>
      <Table
        isLoading={isDataLoading || isDataRefetching}
        colConfig={newColConfig}
        data={filteredPhoneNumbersData ?? []}
        isPaginated
        customToolbarRender={() => (
          <div
            style={{
              display: 'flex',
              gap: theme.spacing(2),
              alignItems: 'center',
            }}
          >
            {!!selectedGroupId && (
              <ScopedLocationFilterMenu
                selectedOptions={filteredLocations}
                onChange={(locations) => {
                  setLocationFilters({ phoneNumbersIndex: locations });
                }}
              />
            )}
            <SwitchChipGroup
              css={{ margin: theme.spacing(0.5) }}
              setValue={setSelectedTypes}
              value={selectedTypes}
              checkboxMode={true}
            >
              <SwitchChipGroup.Option value='voice'>{t('Voice')}</SwitchChipGroup.Option>
              <SwitchChipGroup.Option value='sms'>{t('SMS')}</SwitchChipGroup.Option>
              <SwitchChipGroup.Option value='fax'>{t('Fax')}</SwitchChipGroup.Option>
            </SwitchChipGroup>
          </div>
        )}
        wrapperStyle={css`
          padding: ${theme.spacing(0, 2)};
          .table-toolbar {
            padding: 0;
            > div:nth-last-of-type(2) {
              margin-left: auto;
            }
          }
        `}
        globalTrackingId='phone_numbers'
        hasGlobalSearch
        globalSearchConfig={{
          searchHandler: (searchValue) => setSearchValue(searchValue.length < 3 ? '' : searchValue),
        }}
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No phone numbers'),
          description: () =>
            !!phoneNumbersData?.length ? (
              <Text color='light'>{t('There are no phone numbers to display with this filter setting.')}</Text>
            ) : (
              <Text color='light'>{t('Your numbers will appear in this list once they transfer to Weave')}</Text>
            ),
        }}
        manualFiltersRender={(modalProps) => <TableFilterPanel {...modalProps} onChange={setTableFilters} />}
        rowActions={{
          actions: [
            {
              Icon: () => <Icon name='forms' />,
              label: ({ allStatusDetails, defaultSms }) => {
                return defaultSms ? getStatusMessage(allStatusDetails?.status) : t('Register for 10DLC');
              },
              hoverLabel: ({ allStatusDetails }) => {
                return getStatusMessage(allStatusDetails?.status);
              },
              showLabelWhenDisabled: true,
              disabled: ({ allStatusDetails }) =>
                allStatusDetails?.status !== PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERABLE_QUICKLY &&
                allStatusDetails?.status !== PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERABLE_SLOWLY &&
                allStatusDetails?.status !== PhoneStatusType.PHONE_REGISTRATION_STATUS_FAILED,
              hide: ({ allStatusDetails }) =>
                !allStatusDetails?.status ||
                allStatusDetails.status === PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERED,
              onClick: (row) => {
                setSelectedRow(row);
                registerTriggerProps.onClick();
              },
            },
            {
              Icon: () => <Icon name='star' />,
              hoverLabel: t(
                'Number must first be registered to 10DLC and have the 10DLC Status of “Registered” in this table.'
              ),
              showLabelWhenDisabled: true,
              label: t('Make Default SMS Number'),
              disabled: ({ capabilities, allStatusDetails }) =>
                !capabilities.smsProvider ||
                allStatusDetails?.status !== PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERED,
              hide: ({ defaultSms, allStatusDetails }) => defaultSms || !allStatusDetails?.status,
              onClick: (row) => {
                setSelectedRow(row);
                setDefaultSmsTriggerProps.onClick();
              },
            },
          ],
        }}
      />
      <WeaveNumberModal {...weaveNumberModalControl.modalProps} />
      <ConfirmationModal
        {...registerModalProps}
        title={t('Register Non-Default SMS Number?')}
        message={t(
          'Registering an SMS number is only necessary if the number will be used for business texting purposes.'
        )}
        destructive={
          selectedRow?.allStatusDetails?.status === PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERABLE_SLOWLY
        }
        maxWidth={600}
        onConfirm={() => {
          registerNumber();
        }}
        confirmLabel={t('Yes, Register')}
        cancelLabel={t('No, Cancel')}
        confirmTrackingId='settings-phoneNumbers&Porting-register10dlc-btn'
      />
      <ConfirmationModal
        {...setDefaultSmsProps}
        title={t('Are you sure?')}
        message={t('Are you sure you want to switch your default SMS number?')}
        onConfirm={() => {
          setDefaultSms();
        }}
        confirmTrackingId='settings-phoneNumbers&Porting-switchDefaultSMS-btn'
      />
      <Modal {...tenDlcInfoModalProps}>
        <Modal.Header onClose={tenDlcInfoModalProps.onClose}>{t('10DLC Status')}</Modal.Header>
        <Modal.Body>
          <Trans t={t}>
            <div>
              <Text css={{ display: 'inline' }}>
                Using a 10DLC registered number for SMS texting is now required by mobile carriers. Learn more
              </Text>{' '}
              <TextLink
                size='large'
                target='_blank'
                href='https://www.weavehelp.com/hc/en-us/articles/10424273788827-Register-your-Text-Messaging-Brand-through-Weave'
              >
                here.
              </TextLink>
            </div>
          </Trans>
        </Modal.Body>
        <Modal.Footer secondary={{ label: t('Close'), onClick: tenDlcInfoModalProps.onClose }} />
      </Modal>
    </>
  );
};

const getStatusMessage = (status: PhoneStatusType) => {
  if (!status) return i18next.t('This number is not SMS capable, so it cannot be registered for 10DLC.');
  switch (status) {
    case PhoneStatusType.PHONE_REGISTRATION_STATUS_UNSPECIFIED:
      return i18next.t('Unspecified status. Please contact Weave Support for assistance.');
    case PhoneStatusType.PHONE_REGISTRATION_STATUS_UNREGISTERABLE:
      return i18next.t('Unable to register. Please contact Weave Support for assistance.');
    case PhoneStatusType.PHONE_REGISTRATION_STATUS_PENDING:
      return i18next.t(
        '10DLC Registration pending approval. This third party review takes roughly three to ten business days to complete, and rejection is possible.'
      );
    case PhoneStatusType.PHONE_REGISTRATION_STATUS_REJECTED:
      return i18next.t('Registration rejected. Please contact Weave Support for assistance.');
    case PhoneStatusType.PHONE_REGISTRATION_STATUS_FAILED:
      return i18next.t('Please try registering again. If it still fails, contact Weave Support for assistance.');
    default:
      return '';
  }
};

const statusMapping: Record<PhoneStatusType, { label: string; chip: JSX.Element }> = {
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_UNSPECIFIED]: {
    label: i18next.t('Unspecified'),
    chip: (
      <Chip variant='neutral' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_UNSPECIFIED)}>
        {i18next.t('Unspecified', { ns: 'porting' })}
      </Chip>
    ),
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERED]: {
    label: i18next.t('Registered'),
    chip: <Chip variant='success'>{i18next.t('Registered', { ns: 'porting' })}</Chip>,
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERABLE_QUICKLY]: {
    label: i18next.t('Registerable'),
    chip: <Chip variant='indigo'>{i18next.t('Registerable', { ns: 'porting' })}</Chip>,
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_REGISTERABLE_SLOWLY]: {
    label: 'Registerable',
    chip: <Chip variant='indigo'>{i18next.t('Registerable', { ns: 'porting' })}</Chip>,
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_UNREGISTERABLE]: {
    label: i18next.t('Unregisterable'),
    chip: (
      <Chip
        variant='eggplant'
        tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_UNREGISTERABLE)}
      >
        {i18next.t('Unregisterable', { ns: 'porting' })}
      </Chip>
    ),
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_PENDING]: {
    label: i18next.t('Pending'),
    chip: (
      <Chip variant='seaweed' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_PENDING)}>
        {i18next.t('Pending', { ns: 'porting' })}
      </Chip>
    ),
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_FAILED]: {
    label: i18next.t('Failed'),
    chip: (
      <Chip variant='critical' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_FAILED)}>
        {i18next.t('Failed', { ns: 'porting' })}
      </Chip>
    ),
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_REJECTED]: {
    label: i18next.t('Rejected'),
    chip: (
      <Chip variant='critical' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_REJECTED)}>
        {i18next.t('Rejected', { ns: 'porting' })}
      </Chip>
    ),
  },
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_IN_PROGRESS]: {
    label: i18next.t('Pending'),
    chip: (
      <Chip variant='seaweed' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_PENDING)}>
        {i18next.t('Pending', { ns: 'porting' })}
      </Chip>
    ),
  },
  // Canceled will be handled as Unspecified
  [PhoneStatusType.PHONE_REGISTRATION_STATUS_CANCELED]: {
    label: i18next.t('Unspecified'),
    chip: (
      <Chip variant='neutral' tooltipLabel={getStatusMessage(PhoneStatusType.PHONE_REGISTRATION_STATUS_UNSPECIFIED)}>
        {i18next.t('Unspecified', { ns: 'porting' })}
      </Chip>
    ),
  },
} as Record<PhoneStatusType, { label: string; chip: JSX.Element }>;
