import { ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { Button, ButtonProps, Heading, Modal } from '@frontend/design-system';
import { RegisterTerminalModalSteps, useRegisterTerminalModal } from './hooks';

const styles = {
  list: css`
    list-style-type: number;
  `,
  modalActions: css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(3, 3, 0)};
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    align-items: center;
  `,
  actionsLeft: css``,
  actionsRight: css`
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  actionsButton: css`
    width: auto;
  `,
  skipButton: css`
    color: ${theme.colors.primary50};
    font-weight: ${theme.font.weight.bold};
  `,
};

type RegisterTerminalActionProps = ButtonProps & {
  onClick?: () => void;
};
interface RegisterTerminalStepProps {
  title: string;
  children: ReactNode;
  isAdvanceDisabled?: boolean;
  Action: React.FC<RegisterTerminalActionProps>;
  goNextSectionStep?: () => void;
  onClickAdvance?: () => void;
  goBack?: () => void;
  backLabel?: string;
}

export type RegisterTerminalModalStepProps<T extends object = object> = {
  goBack?: () => void;
  goToStep?: (step: RegisterTerminalModalSteps) => void;
  onClickAdvance?: () => void; // Callback for when the advance button is clicked
  isAdvanceDisabled?: boolean;
  Action?: React.FC<RegisterTerminalActionProps>;
} & T;

type RegisterTerminalStepModalWrapperProps = {
  title: string;
  Action: React.FC<RegisterTerminalActionProps>;
  children: ReactNode;

  /**
   * Use to indiciate next step. Use when not overriding with `onClickAdvance`.
   */
  nextStep?: RegisterTerminalModalSteps;

  /**
   * Optional. Use to add "skip" button.
   */
  nextSectionStep?: RegisterTerminalModalSteps;

  isAdvanceDisabled?: boolean; // Disable the advance button
  /**
   * goBack
   * - false will hide button
   * - if undefined, will use useMultiStepModal().goBack
   */
  goBack?: (() => void) | false;

  /**
   * goToStep
   * - if undefined, will use useRegisterTerminalModal().goToStep
   */
  goToStep?: (step: RegisterTerminalModalSteps) => void;

  /**
   * If undefined, will use goToStep + nextStep
   */
  onClickAdvance?: () => void; // Optional callback for custom advance action

  backLabel?: string; // Optional label for the back button
};

export const RegisterTerminalStepModalWrapper = ({
  nextStep,
  nextSectionStep,
  children,
  onClickAdvance: onClickAdvanceOverride,
  goBack: goBackOverride,
  goToStep: goToStepOverride,
  ...rest
}: RegisterTerminalStepModalWrapperProps) => {
  const { goToStep: goToStepDefault } = useRegisterTerminalModal();
  const { goBack: goBackDefault } = useMultiStepModal();

  const goToStep = useMemo(() => goToStepOverride ?? goToStepDefault, [goToStepDefault, goToStepOverride]);

  const goBack = useMemo(() => {
    if (goBackOverride === false) {
      return;
    }

    return goBackOverride || goBackDefault;
  }, [goBackDefault, goBackOverride]);

  const goNextSectionStep = useMemo(() => {
    if (nextSectionStep) {
      return () => {
        goToStep(nextSectionStep);
      };
    }

    return;
  }, [nextSectionStep, goToStep]);

  const onClickAdvance = useCallback(() => {
    if (typeof onClickAdvanceOverride === 'function') {
      return onClickAdvanceOverride(); // Call the override function if provided
    }

    if (goToStep && nextStep) {
      return goToStep(nextStep); // Fallback to default behavior using goToStep and nextStep
    }

    console.warn('RegisterTerminalModalStep -> onClickAdvance had no actionable callback');
  }, [onClickAdvanceOverride, goToStep, nextStep]);

  return (
    <RegisterTerminalStep
      {...rest}
      goBack={goBack}
      goNextSectionStep={goNextSectionStep}
      onClickAdvance={onClickAdvance}
    >
      {children}
    </RegisterTerminalStep>
  );
};

/**
 * Base component / entirely prop driven.
 * Extend to create a new flow or use directly
 */
const RegisterTerminalStep: RegisterTerminalStepNamespace = ({
  title,
  children,
  Action,
  isAdvanceDisabled,
  onClickAdvance,
  goBack,
  goNextSectionStep,
  backLabel,
}: RegisterTerminalStepProps) => {
  const { t } = useTranslation('payments');

  return (
    <>
      <Modal.Body css={{ flex: 'none' }}>
        <Heading level={3}>{title}</Heading>
        {children}
      </Modal.Body>
      <div className='register-terminal__footer' css={styles.modalActions}>
        <div css={styles.actionsLeft}>
          {typeof goNextSectionStep === 'function' && (
            <Button variant='tertiary' onClick={() => goNextSectionStep()} css={styles.skipButton}>
              {t('Skip this step')}
            </Button>
          )}
        </div>
        <div css={styles.actionsRight}>
          {typeof goBack === 'function' && (
            <Button variant='secondary' onClick={() => goBack()} css={styles.actionsButton} size='large'>
              {backLabel || t('Back')}
            </Button>
          )}

          <Action onClick={onClickAdvance} disabled={isAdvanceDisabled} />
        </div>
      </div>
    </>
  );
};

export const NextAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'secondary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Next')}
    </Button>
  );
};

export const ContinueAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'primary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Continue')}
    </Button>
  );
};

export const RegisterAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'primary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Register')}
    </Button>
  );
};

export const CompleteAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'primary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Done')}
    </Button>
  );
};

export const TryAgainAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'primary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Try Again')}
    </Button>
  );
};

export const RegisterAnotherAction = ({ onClick, variant, size, ...props }: RegisterTerminalActionProps) => {
  const { t } = useTranslation('payments');

  return (
    <Button
      {...props}
      size={size ?? 'large'}
      variant={variant ?? 'secondary'}
      onClick={() => onClick?.()}
      css={styles.actionsButton}
    >
      {t('Register Another Terminal')}
    </Button>
  );
};

type RegisterTerminalStepNamespace = React.FC<RegisterTerminalStepProps> & {
  Next: React.FC<RegisterTerminalActionProps>;
  Continue: React.FC<RegisterTerminalActionProps>;
  Register: React.FC<RegisterTerminalActionProps>;
  Complete: React.FC<RegisterTerminalActionProps>;
  TryAgain: React.FC<RegisterTerminalActionProps>;
  RegisterAnother: React.FC<RegisterTerminalActionProps>;
};

RegisterTerminalStep.Next = NextAction;
RegisterTerminalStep.Continue = ContinueAction;
RegisterTerminalStep.Register = RegisterAction;
RegisterTerminalStep.Complete = CompleteAction;
RegisterTerminalStep.TryAgain = TryAgainAction;
RegisterTerminalStep.RegisterAnother = RegisterAnotherAction;

export { RegisterTerminalStep };
