import { useEffect } from 'react';
import { useSearch } from '@tanstack/react-location';
import { Collections_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import {
  DynamicFieldKey_Enum as DynamicFieldKeys,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { BulkMessagingService } from '@frontend/api-bulk-messaging';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import { EmailComposer, OnSaveChanges, OnTemplateNameChange } from '@frontend/content-composer';
import { useDynamicFields } from '@frontend/dynamic-fields-hooks';
import { useEmailTemplates } from '@frontend/email-templates';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useAlert } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator } from '../../hooks';

const hiddenSubjectDynamicFields = [
  DynamicFieldKeys.BUSINESS_GROUP_ADDRESS,
  DynamicFieldKeys.BUSINESS_GROUP_EMAIL,
  DynamicFieldKeys.ONLINE_BILL_PAY_LINK,
  DynamicFieldKeys.PREFERENCE_MANAGEMENT_LINK,
  DynamicFieldKeys.SCHEDULE_LINK,
  DynamicFieldKeys.UNKNOWN,
  DynamicFieldKeys.UNSUBSCRIBE_LINK,
];

const hiddenTextDynamicFields = [
  DynamicFieldKeys.PREFERENCE_MANAGEMENT_LINK,
  DynamicFieldKeys.UNKNOWN,
  DynamicFieldKeys.UNSUBSCRIBE_LINK,
];

// TODO: Fix directly loading or reloading the page with a templateId specified. Currently the page is blank.
export const BulkEmailComposer = () => {
  const alert = useAlert();
  const { t } = useTranslation('bulk-messaging');
  const search = useSearch() as { templateId?: string; isSystemTemplate?: boolean };
  const templateId = search.templateId;
  const isSystemTemplate = !!search.isSystemTemplate;
  const store = useBulkEmailEditorShallowStore(
    'locationIds',
    'setLocationIds',
    'setSubject',
    'setTemplateHtml',
    'setTemplateJson',
    'subject',
    'templateJson'
  );

  const { data: customTemplate, isLoading: isCustomTemplateLoading } = EmailTemplateQueries.useGetEmailTemplate(
    { templateId: templateId || '' },
    {
      enabled: !!templateId && templateId !== 'new' && !isSystemTemplate,
      onError: () => {
        alert.error(t('There was an error fetching the template.'));
      },
    }
  );

  const { data: systemTemplate, isLoading: isSystemTemplateLoading } = EmailTemplateQueries.useGetSystemEmailTemplate(
    { templateId: templateId || '' },
    {
      enabled: !!templateId && templateId !== 'new' && isSystemTemplate,
      onError: () => {
        alert.error(t('There was an error fetching the template.'));
      },
    }
  );

  const template = isSystemTemplate ? systemTemplate : customTemplate;
  const isLoading = isSystemTemplate ? isSystemTemplateLoading : isCustomTemplateLoading;

  const initializeTemplateStoreData = () => {
    if (!store.locationIds.length && template?.locationIds) store.setLocationIds(template.locationIds);
    if (template?.subject) store.setSubject(template.subject);
    store.setTemplateHtml(template?.templateHtml ?? '');
    store.setTemplateJson(template?.templateJson ?? '');
  };

  useEffect(() => {
    if (template) initializeTemplateStoreData();
  }, [template]);

  const navigateTo = useBulkMessagingNavigator(CampaignType.EMAIL, true);

  // BACK CLICK LOGIC
  const onBack = () => {
    if (templateId === 'new') {
      // the respective store fields are already empty
      // navigates to the new email landing page
      navigateTo.new();
    } else if (templateId) {
      // resets the respective store fields
      // navigates to the all templates page
      navigateTo.templates();
      store.setSubject('');
      store.setTemplateHtml('');
      store.setTemplateJson('');
    } else {
      // don't need to reset the store fields
      // navigates to the email editor page
      navigateTo.editor();
    }
  };

  const { selectedLocationIds: locationIds, selectedOrgId } = useAppScopeStore();
  const { createTemplate, updateTemplate, updateTemplateLocations } = useEmailTemplates();

  // APPLY CHANGES LOGIC
  const handleTemplateLocations = () => {
    const sortLocationIds = (locationIds: string[]) => JSON.stringify(locationIds.sort((a, b) => a.localeCompare(b)));
    const locationIdsHasChanged = sortLocationIds(store.locationIds) !== sortLocationIds(locationIds);

    if (locationIdsHasChanged) {
      updateTemplateLocations({
        templateId,
        locationIds,
      });
    }
  };

  const onApplyChanges = (data: OnSaveChanges) => {
    updateTemplate(data);
    handleTemplateLocations();
  };

  // SAVE LOGIC
  const onSave = async (
    { subject, templateHtml, templateId, templateJson, title }: OnSaveChanges,
    applyChanges: boolean
  ) => {
    store.setSubject(subject);
    store.setTemplateHtml(templateHtml);
    store.setTemplateJson(templateJson);
    try {
      const { thumbnail } = await BulkMessagingService.SchemaHtmlThumbnailService.GenerateThumbnail({
        html: templateHtml,
      });
      if (thumbnail) {
        store.setThumbnailImage(`data:image/png;base64,${thumbnail}`);
      }
    } catch (error) {
      console.error('Error generating email campaign thumbnail', error);
    }

    if (applyChanges) {
      onApplyChanges({ subject, templateHtml, templateId, templateJson, title });
    }

    navigateTo.editor();
  };

  // TEMPLATE NAME SAVE LOGIC
  const onTemplateNameSave = (data: OnTemplateNameChange) =>
    createTemplate({ ...data, collection: Collections_Enum.BULK_MESSAGING });

  // DYNAMIC FIELDS LOGIC
  const templateTypeSlug = TemplateType_Slug.BULK_MESSAGE;
  const { dynamicFields } = useDynamicFields([templateTypeSlug]);
  const subjectDynamicFields = dynamicFields.filter(
    (field) => field.val && !hiddenSubjectDynamicFields.includes(field.val)
  );
  const textDynamicFields = dynamicFields.filter((field) => field.val && !hiddenTextDynamicFields.includes(field.val));

  return (
    <EmailComposer
      composerMode='marketing'
      createTemplateAccess
      editTemplateAccess={!!(template?.orgId && template?.orgId === selectedOrgId)}
      isLoading={isLoading}
      locationIds={store.locationIds}
      onBack={onBack}
      onSave={onSave}
      onTemplateNameSave={onTemplateNameSave}
      subject={store.subject}
      subjectDynamicFields={subjectDynamicFields}
      templateJson={store.templateJson}
      templateTitle={template?.title}
      templateTypeSlug={templateTypeSlug}
      textDynamicFields={textDynamicFields}
      trackingPrefix={BulkEmailPrefixes.Composer}
    />
  );
};
