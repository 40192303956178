import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { customROITimePeriods } from '../constants';
import { ARRoiOverviewCard } from './ar-roi-overview-card';
import { BTRoiOverviewCard } from './bt-roi-overview-card';
import { MCTRoiOverviewCard } from './mct-roi-overview-card';
import { VisibleCards } from './types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isAppointmentRemindersRoiEnabled?: boolean;
  isBulkTextingRoiEnabled?: boolean;
}

export const RoiOverviewCards = ({ isAppointmentRemindersRoiEnabled, isBulkTextingRoiEnabled, ...rest }: Props) => {
  const [visibleCards, setVisibleCards] = useState<VisibleCards>({});

  const visibleCardsCount = useMemo(() => {
    return Object.values(visibleCards).filter(Boolean).length;
  }, [visibleCards]);

  const commonProps = {
    hideSMSCount: visibleCardsCount > 2,
    timePeriod: customROITimePeriods.lastQuarter,
    handleUpdateVisibleCards: (card: keyof VisibleCards, value?: boolean) => {
      setVisibleCards((prev) => ({
        ...prev,
        [card]: value,
      }));
    },
  };

  return (
    <div
      css={[styles.wrapper(!!visibleCardsCount), visibleCardsCount < 3 ? styles.threeCards : styles.twoCards]}
      {...rest}
    >
      <MCTRoiOverviewCard {...commonProps} />
      {isBulkTextingRoiEnabled && <BTRoiOverviewCard {...commonProps} />}
      {isAppointmentRemindersRoiEnabled && <ARRoiOverviewCard {...commonProps} />}
    </div>
  );
};

const styles = {
  wrapper: (showView?: boolean) => css`
    display: ${showView ? 'flex' : 'none'};
    gap: ${theme.spacing(3)};
    flex-wrap: wrap;
  `,

  // TODO :: Remove after GA of Bulk Texting ROI
  twoCards: css`
    .roi-overview-card-button {
      width: 100%;

      @container (min-width: 760px) {
        width: calc(100% / 2 - ${theme.spacing(3)});
      }
    }

    .roi-overview-card-data-items {
      justify-content: space-around;

      @container (min-width: 420px) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }
  `,

  threeCards: css`
    .roi-overview-card-button {
      width: 100%;

      @container (min-width: 630px) {
        width: calc(100% / 2 - ${theme.spacing(3)});
      }

      @container (min-width: 960px) {
        width: calc(100% / 3 - ${theme.spacing(3)});
      }
    }

    .roi-overview-card-data-items {
      justify-content: space-around;
      flex-wrap: wrap;

      p {
        text-align: center;

        @container (min-width: 305px) {
          text-align: left;
        }
      }

      @container (min-width: 305px) {
        justify-content: space-between;
      }
    }
  `,
};
