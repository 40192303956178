import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Node } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { useTranslation, i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { isNodeType, NodeType, NodeTypes } from '@frontend/node-flow';
import { WeaveLocationGroup } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, Heading, RadioCardField, Text, useAlert, useFormField } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useCallObjectsData } from '../../use-call-objects-data';
import { CallGroupStep } from './call-group-step';
import { CallQueueStep } from './call-queue-step';
import { CallRouteStep } from './call-route-step';
import { DeviceStep } from './device-step';
import { ForwardingNumberStep } from './forwarding-number-step';
import { PhoneHoursStep } from './phone-hours-step';
import { PhoneTreeStep } from './phone-tree-step';
import { PlayMessageStep } from './play-message-step';
import { getRepeatPhoneTreeName, PhoneTreeAncestors, RepeatStep } from './repeat-step';
import { VoicemailStep } from './voicemail-step';

type StepOption = { type: NodeTypes; title: string; description: string; iconName: IconName };

const STEP_OPTIONS: {
  hoursAndAvailability: StepOption[];
  directRouting: StepOption[];
  messagingAndInformation: StepOption[];
} = {
  hoursAndAvailability: [
    {
      type: NodeType.OfficeHours,
      title: i18next.t('Phone Hours', { ns: 'phone' }),
      description: i18next.t('Define hours to route calls differently by time of day.', { ns: 'phone' }),
      iconName: 'clock',
    },
  ],
  directRouting: [
    {
      type: NodeType.CallGroup,
      title: i18next.t('Call Group', { ns: 'phone' }),
      description: i18next.t('Multiple devices ring simultaneously or in cascading order.', { ns: 'phone' }),
      iconName: 'call-group',
    },
    {
      type: NodeType.CallQueue,
      title: i18next.t('Call Queue', { ns: 'phone' }),
      description: i18next.t('Callers wait until your team is able to answer the call.', { ns: 'phone' }),
      iconName: 'call-queue',
    },
    {
      type: NodeType.PhoneTree,
      title: i18next.t('Phone Tree', { ns: 'phone' }),
      description: i18next.t('Give your callers options to choose from then define the call routing.', { ns: 'phone' }),
      iconName: 'phone-tree',
    },
    {
      type: NodeType.ForwardDevice,
      title: i18next.t('Device', { ns: 'phone' }),
      description: i18next.t('Directly ring a specific device.', { ns: 'phone' }),
      iconName: 'cell-phone',
    },
    {
      type: NodeType.ForwardNumber,
      title: i18next.t('Forwarding Number', { ns: 'phone' }),
      description: i18next.t('Send the call to a phone number.', { ns: 'phone' }),
      iconName: 'send-forward',
    },
    {
      type: NodeType.Repeat,
      title: i18next.t('Repeat Phone Tree', { ns: 'phone' }),
      description: i18next.t('Repeat a Phone Tree.', { ns: 'phone' }),
      iconName: 'automated-message',
    },
    {
      type: NodeType.CallRoute,
      title: i18next.t('Call Route', { ns: 'phone' }),
      description: i18next.t('Send the call to another Call Route.', { ns: 'phone' }),
      iconName: 'call-route',
    },
  ],
  messagingAndInformation: [
    {
      type: NodeType.VoicemailBox,
      title: i18next.t('Voicemail', { ns: 'phone' }),
      description: i18next.t('Allow your callers to leave a voicemail.', { ns: 'phone' }),
      iconName: 'voicemail',
    },
    {
      type: NodeType.PlayMessage,
      title: i18next.t('Play Message', { ns: 'phone' }),
      description: i18next.t('Play an informative audio message to the caller.', { ns: 'phone' }),
      iconName: 'play',
    },
  ],
} as const;

export type CallObjectState = Pick<Node, 'callObject'> & { type?: NodeTypes; dialOptions?: string[] };
export type AddStepPanelProps = {
  initialState?: CallObjectState;
  settingsTenantLocation?: WeaveLocationGroup;
  tenantId: string;
  callObjectsData: ReturnType<typeof useCallObjectsData>;
  onCancelClick: () => void;
  onProceedClick: (updates: CallObjectState) => void;
  onBackClick: () => void;
  onClose: () => void;
};

export const AddStepPanel = ({
  initialState,
  omitStepTypes,
  callObjectsData,
  onClose,
  onAddStep,
  getAncestorPhoneTrees,
}: {
  initialState?: CallObjectState;
  omitStepTypes: NodeTypes[];
  callObjectsData: ReturnType<typeof useCallObjectsData>;
  onClose: () => void;
  onAddStep: (props: CallObjectState) => void;
  getAncestorPhoneTrees: () => PhoneTreeAncestors;
}) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const [state, setState] = useState<CallObjectState | undefined>(initialState);
  const [stepIndex, setStepIndex] = useState(!!initialState?.callObject?.primitiveName ? 1 : 0);
  const alerts = useAlert();
  const [isEditMode] = useState(() => !!initialState?.callObject?.primitiveName);

  const handleSelectStepProceedClick = (type: NodeTypes) => {
    const typeIsDifferent = state?.type !== type;
    if (!typeIsDifferent) {
      setStepIndex(1);
      return;
    }

    const defaultCallObject = {
      primitiveId: '',
      primitiveName: '',
      instructionId: '',
      instructionSetId: '',
    };

    // Repeat is a special case where if there is only one phone tree ancestor node, we don't need to show the next
    // step and we can just add the step
    if (type === NodeType.Repeat) {
      const ancestorPhoneTrees = getAncestorPhoneTrees();

      if (Object.keys(ancestorPhoneTrees).length === 1) {
        const phoneTreeNode = Object.values(ancestorPhoneTrees)[0].node;

        onAddStep({
          type,
          callObject: {
            ...defaultCallObject,
            primitiveName: getRepeatPhoneTreeName(phoneTreeNode),
            primitiveId: '',
            repeatExpansion: {
              phoneTreeNodeId: phoneTreeNode.id,
            },
          },
        });
        return;
      }
    }

    setState({
      type,
      // @ts-expect-error this is complaining because it expects one of the expansion type properties since the
      // generate typescript is wrong. The expansion properties are not required.
      callObject: defaultCallObject,
    });
    setStepIndex(1);
  };

  const handleAddStep = (updates: CallObjectState) => {
    const totalUpdates = { ...state, ...updates };

    onAddStep(totalUpdates);
  };

  const handleBack = () => {
    setStepIndex(0);
  };

  const handleCancelClick = () => {
    if (!isEditMode) {
      onClose();
    } else {
      setStepIndex(1);
      setState(initialState);
    }
  };

  const commonProps = useMemo(
    () => ({
      initialState: state,
      settingsTenantLocation,
      tenantId,
      callObjectsData,
      onClose,
      onCancelClick: handleCancelClick,
      onProceedClick: handleAddStep,
      onBackClick: handleBack,
    }),
    [onClose, handleAddStep, handleBack, tenantId, state, callObjectsData]
  );

  const filteredStepOptions = {
    hoursAndAvailability: STEP_OPTIONS.hoursAndAvailability.filter((option) => !omitStepTypes.includes(option.type)),
    directRouting: STEP_OPTIONS.directRouting.filter((option) => !omitStepTypes.includes(option.type)),
    messagingAndInformation: STEP_OPTIONS.messagingAndInformation.filter(
      (option) => !omitStepTypes.includes(option.type)
    ),
  };

  const getStepComponent = () => {
    if (!state?.type) {
      alerts.error('Unknown step type');
      return null;
    }
    switch (state.type) {
      case NodeType.CallGroup:
        return <CallGroupStep {...commonProps} />;
      case NodeType.CallQueue:
        return <CallQueueStep {...commonProps} />;
      case NodeType.ForwardDevice:
        return <DeviceStep {...commonProps} />;
      case NodeType.ForwardNumber:
        return <ForwardingNumberStep {...commonProps} />;
      case NodeType.VoicemailBox:
        return <VoicemailStep {...commonProps} />;
      case NodeType.PlayMessage:
        return <PlayMessageStep {...commonProps} />;
      case NodeType.PhoneTree:
        return <PhoneTreeStep {...commonProps} />;
      case NodeType.CallRoute:
        return <CallRouteStep {...commonProps} />;
      case NodeType.OfficeHours:
        return <PhoneHoursStep {...commonProps} />;
      case NodeType.Repeat: {
        const ancestorPhoneTrees = getAncestorPhoneTrees();
        return <RepeatStep {...commonProps} phoneTreeNodes={ancestorPhoneTrees} />;
      }
      default:
        return (
          <SelectStep
            stepOptions={filteredStepOptions}
            initialStep={state?.type}
            isEditMode={isEditMode}
            onClose={onClose}
            onProceedClick={handleSelectStepProceedClick}
            onCancelClick={handleCancelClick}
          />
        );
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        height: 100%;
        overflow-y: scroll;
        padding: ${theme.spacing(0.5)};
      `}
    >
      {stepIndex === 0 && (
        <SelectStep
          stepOptions={filteredStepOptions}
          initialStep={state?.type}
          isEditMode={isEditMode}
          onClose={onClose}
          onProceedClick={handleSelectStepProceedClick}
          onCancelClick={handleCancelClick}
        />
      )}
      {stepIndex === 1 && <>{getStepComponent()}</>}
    </div>
  );
};

export const ButtonBar = ({
  primaryButtonLabel,
  primaryButtonDisabled,
  backButtonLabel,
  onPrimaryButtonClick,
  onCancelClick,
  onBackClick,
}: {
  primaryButtonLabel: string;
  primaryButtonDisabled?: boolean;
  backButtonLabel?: string;
  onPrimaryButtonClick: () => void;
  onCancelClick: () => void;
  onBackClick?: () => void;
}) => {
  const { t } = useTranslation('phone');

  return (
    <div
      css={css`
        display: flex;
        justify-content: ${onBackClick ? 'space-between' : 'flex-end'};
        margin-top: auto;
      `}
    >
      {onBackClick && (
        <Button size='large' iconName='back' variant='tertiary' onClick={onBackClick}>
          {!!backButtonLabel ? backButtonLabel : t('Back')}
        </Button>
      )}
      <div
        css={css`
          display: flex;
          gap: ${theme.spacing(2)};
        `}
      >
        <Button size='large' variant='secondary' onClick={onCancelClick}>
          {t('Cancel')}
        </Button>
        <Button size='large' disabled={primaryButtonDisabled} variant='primary' onClick={onPrimaryButtonClick}>
          {primaryButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export const HeaderBar = ({
  title,
  description,
  onClose,
  ...rest
}: {
  title: string;
  description?: string;
  onClose: () => void;
}) => {
  return (
    <div
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
      {...rest}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Heading level={2}>{title}</Heading>
        <Button variant='secondary' iconName='x-small' onClick={onClose} />
      </div>
      {description && <Text>{description}</Text>}
    </div>
  );
};

const SelectStep = ({
  onClose,
  onProceedClick,
  onCancelClick,
  initialStep,
  stepOptions,
  isEditMode,
}: {
  initialStep?: string;
  stepOptions: typeof STEP_OPTIONS;
  isEditMode: boolean;
  onClose: () => void;
  onProceedClick: (type: NodeTypes) => void;
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation('phone');

  const cardFieldProps = useFormField({
    type: 'radio',
    required: true,
    value: initialStep,
  });

  return (
    <>
      <HeaderBar
        title={isEditMode ? t('Change Step') : t('Add Step')}
        description={isEditMode ? t('Change this step in the call flow.') : t('Add the next step to the call flow.')}
        onClose={onClose}
      />

      <Text weight='bold'>{t('Hours and Availability')}</Text>
      <RadioCardField {...cardFieldProps} name='field1' css={cardFieldStyles}>
        {stepOptions.hoursAndAvailability.map((option) => (
          <RadioCardField.Option
            key={option.type}
            css={cardStyles}
            value={option.type}
            title={option.title}
            description={option.description}
            iconName={option.iconName}
          />
        ))}
        <span></span>
      </RadioCardField>

      <Text weight='bold'>{t('Direct Routing')}</Text>
      <RadioCardField {...cardFieldProps} name='field1' css={cardFieldStyles}>
        {stepOptions.directRouting.map((option) => (
          <RadioCardField.Option
            key={option.type}
            css={cardStyles}
            value={option.type}
            title={option.title}
            description={option.description}
            iconName={option.iconName}
          />
        ))}
      </RadioCardField>

      <Text weight='bold'>{t('Messaging and Information')}</Text>
      <RadioCardField {...cardFieldProps} name='field1' css={cardFieldStyles}>
        {stepOptions.messagingAndInformation.map((option) => (
          <RadioCardField.Option
            key={option.type}
            css={cardStyles}
            value={option.type}
            title={option.title}
            description={option.description}
            iconName={option.iconName}
          />
        ))}
      </RadioCardField>

      <ButtonBar
        primaryButtonLabel={t('Continue')}
        primaryButtonDisabled={!cardFieldProps.value}
        onPrimaryButtonClick={() => {
          const value = cardFieldProps.value;
          if (isNodeType(value)) {
            onProceedClick(value);
          }
        }}
        onCancelClick={onCancelClick}
      />
    </>
  );
};
const cardStyles = css`
  flex: unset;
  width: 300px;
`;
const cardFieldStyles = css`
  div:first-child {
    display: flex;
    flex-wrap: wrap;
  }
`;
