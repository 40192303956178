import { css } from '@emotion/react';
import { ForwardingNumber } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers_service.pb';
import { ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import { PhoneNumbersV1 } from '@frontend/api-phone-numbers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  SkeletonLoaders,
  useAlert,
  useForm,
  Button,
  Heading,
  useModalControl,
  Modal,
  Text,
} from '@frontend/design-system';
import { AddForwardingNumberModal } from '../../../add-forwarding-number-modal';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const ForwardingNumberStep = ({
  tenantId,
  initialState,
  settingsTenantLocation,
  callObjectsData,
  onClose,
  onCancelClick,
  onProceedClick,
  onBackClick,
}: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { modalProps: infoModalProps, openModal: openInfoModal, closeModal: closeInfoModal } = useModalControl();

  const { modalProps: createModalProps, triggerProps: createTriggerProps } = useModalControl();

  const { updateQuery } = PhoneNumbersV1._QueryUpdaters.useQueryUpdaters();

  const { forwardingNumbers, forwardingNumbersIsLoading } = callObjectsData;

  const { getFieldProps, isComplete, values } = useForm({
    fields: {
      forwardingNumberId: {
        required: true,
        type: 'dropdown',
        value: initialState?.callObject?.primitiveId,
      },
      ringDuration: {
        type: 'dropdown',
        required: true,
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: String(initialState?.callObject?.forwardingNumberExpansion?.timeout ?? '0'),
      },
    },
  });

  const locationId = settingsTenantLocation?.locationId ?? '';

  const handleProceedClick = () => {
    const number = forwardingNumbers.find((number) => number?.id === values.forwardingNumberId);

    if (!number) {
      alerts.error('Unknown forwarding number data');
      return;
    }

    const name = getForwardingNumberDisplayName(number);

    onProceedClick({
      callObject: {
        primitiveId: number.id,
        primitiveName: name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        forwardingNumberExpansion: {
          timeout: Number(values.ringDuration),
        },
      },
    });
  };

  const getForwardingNumberDisplayName = (forwardingNumber?: ForwardingNumber) =>
    forwardingNumber ? `${forwardingNumber.name} (${forwardingNumber.number})` : '';

  const handleOnCreateSuccess = (forwardingNumber: ForwardingNumberTypes.FwdNumberModel) => {
    const ForwardingNumberID = forwardingNumber.id;

    // @ts-expect-error This is complaining thinking that name and value do not exist on the event target,
    // but they do.
    getFieldProps('forwardingNumberId').onChange({ target: { name: 'forwardingNumberId', value: ForwardingNumberID } });

    updateQuery({
      endpointName: 'ListForwardingNumbers',
      updater: (oldData) => {
        const updated = {
          forwardingNumbers: [
            ...(oldData?.forwardingNumbers ?? []),
            // Add the new forwarding number to the list.
            // NOTE: the data returned from the API in the mutation is from a different service than the service
            // that returns the data from the list endpoint and so the data here needs to be transformed.
            {
              id: forwardingNumber.id,
              name: forwardingNumber.name,
              number: forwardingNumber.number,
            },
          ],
        };

        return updated;
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Forwarding Number')} onClose={onClose} />

      <SkeletonLoaders.Loader isLoading={forwardingNumbersIsLoading} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Select forwarding number')}
          {...getFieldProps('forwardingNumberId')}
          placeholder={t('Select forwarding number')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          <DropdownField.Option
            css={css`
              padding: 0;
            `}
            key={'create-forwarding-number'}
            value=''
            isSelectable={false}
          >
            <Button
              iconName='plus'
              variant='tertiary'
              onClick={() => createTriggerProps.onClick()}
              css={css`
                width: 100%;
                justify-content: start;
              `}
            >
              {t('Add Forwarding Number')}
            </Button>
          </DropdownField.Option>
          {forwardingNumbers.map((number) => (
            <DropdownField.Option key={number.id} value={number.id}>
              {getForwardingNumberDisplayName(number)}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </SkeletonLoaders.Loader>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Heading level={3}>{t('Ring Duration')}</Heading>
        <Button iconName='info-small' variant='secondary' onClick={openInfoModal} />
      </div>
      <SkeletonLoaders.Loader isLoading={forwardingNumbersIsLoading} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Ring duration')}
          {...getFieldProps('ringDuration')}
          placeholder={t('Ring duration')}
          helperText={t('6 seconds usually equals 1 ring')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          {[6_000, 12_000, 18_000, 24_000, 30_000, 36_000, 42_000, 48_000, 54_000, 60_000].map((duration) => (
            <DropdownField.Option key={duration} value={String(duration)}>
              {`${t('{{duration}} seconds', { duration: duration / 1000 })}`}
            </DropdownField.Option>
          ))}
          <DropdownField.Option key={'0'} value={'0'}>
            {t('Ring until answered')}
          </DropdownField.Option>
        </DropdownField>
      </SkeletonLoaders.Loader>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!isComplete}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onCancelClick}
        onBackClick={onBackClick}
      />

      <Modal {...infoModalProps} minWidth={400}>
        <Modal.Header onClose={closeInfoModal}>{t('Ring Duration')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'The length of time a call rings before the system takes the caller to the next step in the Call Route.'
            )}
          </Text>
        </Modal.Body>
      </Modal>

      <Modal asChild {...createModalProps}>
        <AddForwardingNumberModal
          fwdNumbers={forwardingNumbers.map((item) => ({
            id: item.id,
            name: item.name,
            number: item.number,
            tenantId: tenantId,
          }))}
          disallowedNumbers={[]}
          locationID={locationId}
          onSuccess={handleOnCreateSuccess}
          closeModal={createModalProps.onClose}
        />
      </Modal>
    </>
  );
};
