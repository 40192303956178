import { SettingType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/settings/v1/settings.pb';
import { Bool } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { FaxQueries } from '@frontend/api-fax';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { FormsSubmission } from '@frontend/api-forms';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { MessagingSettingsV1 } from '@frontend/api-messaging-settings';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useListDraftsCountQuery } from '@frontend/api-sms-draft';
import {
  AnalyticsFeatureFlags,
  useHasAppointmentAnalyticsFeature,
  useIsPAEligibleAccount,
  useShowCallIntelNav,
} from '@frontend/analytics';
import { getUser } from '@frontend/auth-helpers';
import { NavItem } from '@frontend/components';
import { ContactsHooks } from '@frontend/contacts';
import { useFeatureAccess } from '@frontend/feature-access';
import {
  pendoTags as formsPendoTags,
  useFormsDigitizationFeatureFlag,
  routes as formRoutes,
  routeUtils as formRouteUtils,
} from '@frontend/forms';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon, IconName } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useBulkDraftShallowStore } from '@frontend/messages';
import { useMerchantPayoutsPausingStatus, useShouldShowPayoutsPausingAlert } from '@frontend/payments';
import { useMerchant } from '@frontend/payments-hooks';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useSchedulingLocationInfo } from '@frontend/schedule';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { DeepReadonly } from '@frontend/types';
import { theme } from '@frontend/theme';

const NavIcon = ({ name, ...rest }: { name: IconName }) => <Icon name={name} size={20} {...rest} />;

export const usePrimaryNavContent = () => {
  const { t } = useTranslation('primaryNav');
  const { locationId } = useLocationDataShallowStore('locationId');

  const hasCallIntelAccess = useShowCallIntelNav();
  const tokens = useTranslationTokens();
  const hasAppointAnalyticsFeature = useHasAppointmentAnalyticsFeature();
  const { isPAEligibleAccount } = useIsPAEligibleAccount();
  const { getCFLocationMap } = useSchedulingLocationInfo();

  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const user = getUser();

  // Customization Flags
  const hasPhoneSystemAccess = useHasPhoneSystemAccess({ locationIds: selectedLocationIds });

  // Feature Flags

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const faxBlockFeatureFlag = getFeatureFlagValue('fax-2-0-block');
  const faxArchiveFeatureFlag = getFeatureFlagValue('fax-2-0-archive');
  const ourPartnersPage = getFeatureFlagValue('our-partners-page');
  const autoRecallAnalyticsDisabled = getFeatureFlagValue(AnalyticsFeatureFlags.disableAutoRecallInAnalytics);

  const { canUseFormsDigitization } = useFormsDigitizationFeatureFlag();
  const { hasQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  const onlineSchedulingCFLocationMap = getCFLocationMap('onlinescheduling');
  const isOnlineSchedulingEnabledForAnyLocation = Object.values(onlineSchedulingCFLocationMap).some((value) => !!value);

  const hasListHomeIconAccess = ContactsHooks.useHasListHomeIconAccess();

  const actionableTabVisibilityFF = getFeatureFlagValue('nwx:inbox-actionable-tab', 'ANY');
  const { data: actionableTabIsEnabled, isLoading: isEnableActionableTabLoading } =
    MessagingSettingsV1.Queries.useListQuery(
      {
        settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
        orgId: selectedOrgId,
        locationIds: selectedLocationIds,
      },
      {
        enabled: !!actionableTabVisibilityFF,
        select: (data) => {
          return data.settings.some(({ setting }) => {
            if ('inboxLocationSetting' in setting) {
              return setting.inboxLocationSetting.enableActionableTab ?? false;
            }
            return false;
          });
        },
      }
    );

  const isActionableFeatureEnabled = actionableTabIsEnabled && actionableTabVisibilityFF;

  // Counts
  const { data: inboxUnreadCount } = SMSDataV3.Queries.useListThreadsCountQuery({
    httpOptions: {
      onFetchError: (error) => {
        if (error.message.includes('too many')) {
          console.warn(error);
        } else {
          throw error;
        }
      },
    },
    request: {
      locationId,
      groupIds: selectedLocationIds,
      statuses: isActionableFeatureEnabled ? undefined : [SMSDataV3.Types.KnownThreadStatus.NEW],
      actionable: isActionableFeatureEnabled ? Bool.BOOL_TRUE : Bool.BOOL_UNSPECIFIED,
    },
    options: {
      select: (data) => data.count,
      enabled: !!selectedLocationIds[0] && !isEnableActionableTabLoading,
    },
  });
  const voicemailsUnreadCount = PhoneCallsQueries.useVoicemailsUnreadCount();
  const { initialYear: initialBulkDraftYear } = useBulkDraftShallowStore('initialYear');
  const bulkDraftYear = initialBulkDraftYear ? Number(initialBulkDraftYear) : new Date().getFullYear();
  const { hasAccess: hasBulkTextAccess } = useFeatureAccess('commx-bulk-messaging', Permission.BULK_TEXTING_MANAGE);
  const { hasAccess: hasBulkEmailAccess } = useFeatureAccess(
    'commx-email-marketing-v2',
    Permission.EMAIL_MARKETING_MANAGE
  );
  const { hasAccess: hasFaxAccess } = useFeatureAccess('fax-2-0', Permission.FAX_INBOX_MANAGE);

  const { isFeatureHiddenInAllLocations: hideReviewsPage } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: true,
      customizationFlag: Feature.REVIEWS_HOME_ICON,
    });

  const { data: faxUnreadCount } = FaxQueries.useCountFaxes(selectedLocationIds, {
    enabled: hasFaxAccess && selectedLocationIds.length > 0,
  });
  const { data: faxDraftUnreadCount } = FaxDraftQueries.useGetDraftCount(selectedLocationIds[0], selectedLocationIds, {
    enabled: hasFaxAccess && selectedLocationIds.length > 0 && !!selectedLocationIds[0],
  });
  const { count: bulkMessageDraftCount } = BulkMessagingQueries.useListCampaignDrafts(
    {
      locationIds: selectedLocationIds,
      orgId: selectedOrgId,
      year: bulkDraftYear,
    },
    hasBulkEmailAccess,
    hasBulkTextAccess
  );
  const { data: smsDraftCount } = useListDraftsCountQuery({
    orgId: selectedOrgId,
    userId: user?.userID ?? '',
    locationId: selectedLocationIds[0],
    groupIds: selectedLocationIds,
  });

  const { data: schedulesCount } = ManualSmsScheduledV1.Queries.useListThreadsCountQuery({
    httpOptions: {
      onFetchError: (error) => {
        if (error.message.includes('too many')) {
          console.warn(error);
        } else {
          throw error;
        }
      },
    },
    request: {
      locationIds: selectedLocationIds,
    },
  });

  const { hasAccountAlert: hasPaymentAccountAlert } = useMerchant();

  const { status: paymentAccountAlertType } = useMerchantPayoutsPausingStatus();

  const shouldShowPaymentsAccountAlert = useShouldShowPayoutsPausingAlert();

  const formsSubmissionCount = FormsSubmission.useSubmissionsUnreadCount();

  return [
    {
      icon: (props) => <NavIcon {...props} name='home' />,
      label: t('Home'),
      root: '/home',
      subNavItems: [
        {
          label: t('My Dashboard'),
          relativePath: '/dashboard',
          trackingId: 'app-nav-item-dashboard',
          description: 'Your personalized dashboard',
        },
        {
          label: t('Settings'),

          path: '#settings/organization' as NavItem['subNavItems'][number]['path'],
          show: true,
          trackingId: 'app-nav-item-portal',
        },
        {
          label: t('Our Partners'),
          relativePath: '/partners',
          show: ourPartnersPage,
          trackingId: 'app-nav-item-our-partners',
        },
      ],
      show: true,
    },
    {
      hasCount: !!inboxUnreadCount,
      icon: (props) => <NavIcon {...props} name='inbox' />,
      label: t('Messages'),
      root: '/messages',
      subNavItems: [
        { count: inboxUnreadCount, label: t('Inbox'), relativePath: '/inbox', trackingId: 'nav-messages-inbox' },
        {
          count: schedulesCount?.count,
          isPassiveCount: true,
          label: t('Scheduled'),
          relativePath: '/scheduled',
          trackingId: 'nav-messages-scheduled',
        },
        {
          count: smsDraftCount?.count,
          isPassiveCount: true,
          label: t('Drafts'),
          relativePath: '/drafts',
          trackingId: 'nav-messages-drafts',
        },
        { label: t('Archived'), relativePath: '/archived', trackingId: 'nav-messages-archived' },
        { label: t('Blocked'), relativePath: '/blocked', trackingId: 'nav-messages-blocked' },
      ],
      settings: {
        path: '/messaging',
        label: '',
      },
    },
    {
      hasCount: !!voicemailsUnreadCount,
      icon: (props) => <NavIcon {...props} name='phone' />,
      show: hasPhoneSystemAccess,
      root: '/calls',
      label: t('Calls'),
      subNavItems: [
        { label: t('Recent Calls'), relativePath: '/recent-calls', trackingId: 'nav-calls-recent-calls' },
        {
          count: voicemailsUnreadCount,
          label: t('Voicemails'),
          relativePath: '/voicemails',
          trackingId: 'nav-calls-voicemails',
        },
        { label: t('Call Queue Data'), relativePath: '/call-queue-stats', trackingId: 'nav-calls-call-queue-data' },
      ],
      settings: {
        path: '/phone/main',
        label: '',
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='calendar' />,
      label: t('Schedule'),
      root: '/schedule',
      subNavItems: [
        { label: t('Calendar'), relativePath: '/calendar', trackingId: 'nav-schedule-calendar' },
        {
          label: t('Request History'),
          relativePath: '/request-history',
          show: isOnlineSchedulingEnabledForAnyLocation,
          trackingId: 'nav-schedule-request-history',
        },
        {
          show: hasQuickFillFeature,
          label: t('Quick Fill History'),
          relativePath: '/quickfill-history',
          trackingId: 'nav-schedule-quickfill-history',
        },
      ],
      settings: {
        path: '/schedule',
        label: '',
      },
    },
    {
      hasCount: !!hasPaymentAccountAlert && shouldShowPaymentsAccountAlert,
      hasCountColor: paymentAccountAlertType === 'error' ? theme.colors.critical50 : theme.colors.warning50,
      icon: (props) => <NavIcon {...props} name='payments' />,
      label: t('Payments'),
      root: '/payments',
      subNavItems: [
        { label: t('Requests'), relativePath: '/invoices', trackingId: 'nav-payments-invoices' },
        { label: t('Payouts'), relativePath: '/payouts', trackingId: 'nav-payments-payouts' },
        { label: t('Payment Plans'), relativePath: '/payment-plans', trackingId: 'nav-payments-payment-plans' },
      ],
      settings: {
        path: '/payments',
        label: '',
      },
    },
    {
      hasCount: !!formsSubmissionCount.data?.count,
      icon: (props) => <NavIcon {...props} name='forms' />,
      label: t('Forms'),
      root: '/forms',
      subNavItems: [
        {
          count: formsSubmissionCount.data?.count,
          label: t('Submissions'),
          relativePath: '/submissions',
          trackingId: formsPendoTags.sideNav.submissions,
        },
        {
          label: t('My Forms'),
          relativePath: '/library',
          path: `/forms/${formRouteUtils.getRoutePathAsString(formRoutes.library.formsV2({ relativePath: true }))}`,
          trackingId: formsPendoTags.sideNav.myForms,
        },
        {
          label: t('Kiosk Mode'),
          relativePath: '/kiosk',
          trackingId: formsPendoTags.sideNav.kiosk,
        },
        {
          label: t('Templates'),
          relativePath: '/templates',
          path: `/forms/${formRouteUtils.getRoutePathAsString(
            formRoutes.templates.templatesList({ relativePath: true })
          )}`,
          trackingId: formsPendoTags.sideNav.templates,
        },
        {
          label: t('Builder'),
          relativePath: '/builder/new',
          trackingId: formsPendoTags.sideNav.builder,
        },
        {
          label: t('Request Digitization'),
          relativePath: '/digitization',
          show: canUseFormsDigitization,
          trackingId: formsPendoTags.sideNav.requestDigitization,
        },
      ],
      settings: {
        path: '/digital-forms',
        label: '',
        show: true,
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='fax' />,
      label: t('Fax'),
      root: '/fax',
      show: hasFaxAccess,
      hasCount: !!faxUnreadCount?.faxCount,
      subNavItems: [
        {
          count: faxUnreadCount?.faxCount,
          label: t('Fax Inbox'),
          relativePath: '/inbox',
          trackingId: `${FaxPrefixes.Nav}-inbox`,
        },
        {
          label: t('Drafts'),
          relativePath: '/drafts',
          count: faxDraftUnreadCount?.count,
          isPassiveCount: true,
          trackingId: `${FaxPrefixes.Nav}-drafts`,
        },

        ...(faxArchiveFeatureFlag
          ? [
              {
                label: t('Archived'),
                relativePath: '/archived',
                count: faxUnreadCount?.archivedFaxCount,
                isPassiveCount: true,
                trackingId: `${FaxPrefixes.Nav}-archived`,
              },
            ]
          : []),
        { label: t('Fax Contacts'), relativePath: '/contacts', trackingId: `${FaxPrefixes.Nav}-contacts` },
        ...(faxBlockFeatureFlag
          ? [
              {
                count: faxUnreadCount?.blockedFaxCount,
                isPassiveCount: true,
                label: t('Blocked'),
                relativePath: '/blocked',
                trackingId: `${FaxPrefixes.Nav}-blocked`,
              },
            ]
          : []),
        {
          label: t('Send New Fax'),
          relativePath: '/drafts/new',
          show: false,
          trackingId: `${FaxPrefixes.Nav}-send-new-fax`,
        },
        {
          label: t('Send From Template'),
          relativePath: '/drafts/new-from-template',
          show: false,
          trackingId: `${FaxPrefixes.Nav}-send-from-template`,
        },
      ],
      settings: {
        path: '/fax',
        label: '',
      },
    },
    {
      icon: (props) => <NavIcon {...props} name='contacts' />,
      label: tokens.contacts,
      root: '/contacts',
      subNavItems: [
        { label: tokens.allContacts, relativePath: '/all-contacts', trackingId: 'contact-2.0-list-menu-all' },
        {
          label: t('Lists'),
          relativePath: '/lists',
          trackingId: 'contact-2.0-list-menu-lists',
          show: hasListHomeIconAccess,
        },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='reviews-dash' />,
      label: t('Reviews'),
      root: '/reviews',
      subNavItems: [
        { label: t('All Reviews'), relativePath: '/all-reviews', trackingId: 'nav-reviews-all-reviews' },
        { label: t('Google'), relativePath: '/google', trackingId: 'nav-reviews-google' },
        { label: t('Facebook'), relativePath: '/facebook', trackingId: 'nav-reviews-facebook' },
        { label: t('Private'), relativePath: '/private', trackingId: 'nav-reviews-private' },
      ],
      settings: {
        path: '/reviews',
        label: '',
        show: true,
      },
      show: !hideReviewsPage,
    },
    {
      icon: (props) => <NavIcon {...props} name='analytics' />,
      label: t('Analytics'),
      root: '/analytics',
      subNavItems: [
        { label: t('Overview'), relativePath: '/dashboard', trackingId: 'nav-analytics-overview' },
        {
          label: t('Practice'),
          relativePath: '/practice/main',
          show: isPAEligibleAccount,
          trackingId: 'nav-analytics-practice',
        },
        {
          label: t('Call Intelligence'),
          relativePath: '/call-intelligence/main',
          show: hasCallIntelAccess,
          trackingId: 'nav-analytics-call-intelligence',
        },
        {
          label: t('Auto Recall'),
          relativePath: '/auto-recall',
          show: !autoRecallAnalyticsDisabled,
          trackingId: 'nav-analytics-auto-recall',
        },
        {
          label: t('Phone'),
          relativePath: '/phone',
          trackingId: 'nav-analytics-phone',
        },
        {
          label: t('Messaging'),
          relativePath: '/messaging',
          trackingId: 'nav-analytics-messaging',
        },
        {
          label: t('Appointment'),
          relativePath: '/appointment',
          show: hasAppointAnalyticsFeature,
          trackingId: 'nav-analytics-appointments',
        },
        {
          label: t('Reviews'),
          relativePath: '/reviews',
          show: !hideReviewsPage,
          trackingId: 'nav-analytics-reviews',
        },
      ],
    },
    {
      icon: (props) => <NavIcon {...props} name='bulk-messaging' />,
      label: t('Marketing'),
      root: '/bulk',
      show: hasBulkEmailAccess || hasBulkTextAccess,
      subNavItems: [
        { label: t('All Campaigns'), relativePath: '/all-campaigns/email', trackingId: 'nav-marketing-all-campaings' },
        {
          label: t('Drafts'),
          relativePath: '/drafts',
          count: bulkMessageDraftCount,
          isPassiveCount: true,
          trackingId: 'nav-marketing-drafts',
        },
      ],
    },
  ] as const satisfies DeepReadonly<NavItem[]>;
};
