import { useState } from 'react';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import {
  Crop,
  LogoSection as LogoSectionType,
} from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { ComposerSection } from '../../types';
import { convertImageToMedia, convertMediaToImage, getFrameStyle } from '../../utils';
import { LogoOutput } from '../outputs';
import { LogoSettings } from '../settings';
import { LogoTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';
import { AddImageButton, ImageItem, UploadOrSelectImageModal } from './image-elements';

export const LogoSection: ComposerSection<LogoSectionType> = ({ frame, height, image }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const { t } = useTranslation('content-composer');
  const { isSelected, setProps } = useComposerSection<LogoSectionType>();

  const { modalProps, triggerProps } = useModalControl();

  const handleCrop = (crop: Crop) => {
    setSelectedIndex(undefined);
    setProps((props) => {
      if (selectedIndex === undefined) return;
      const newImage = image ? { ...image, crop } : undefined;
      props.image = newImage;
    });
  };

  return (
    <BaseComposerSection
      title={t('Logo')}
      canCopy={false}
      css={getFrameStyle(frame)}
      onClick={() => {
        setSelectedIndex(undefined);
      }}
    >
      <Text
        as='div'
        weight='bold'
        css={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          },
          !image && {
            minHeight: 320,
          },
        ]}
      >
        {image ? (
          <ImageItem
            height={height}
            image={image}
            onCrop={(crop) => handleCrop(crop)}
            onRemove={() => setProps((props) => (props.image = undefined))}
            onReplace={triggerProps.onClick}
            selectedImage={selectedIndex === 0 && isSelected}
            selectImage={() => setSelectedIndex(0)}
            type='logo'
          />
        ) : (
          <AddImageButton
            text={t('Add Logo')}
            onClick={() => {
              setSelectedIndex(0);
              triggerProps.onClick();
            }}
          />
        )}
      </Text>
      <UploadOrSelectImageModal
        selectedImages={[image].map((image) => (image ? convertImageToMedia(image) : undefined))}
        selectedIndex={0}
        modalProps={modalProps}
        onSelect={(files: (Media | undefined)[]) => {
          const firstFile = files[0];
          const newImage = convertMediaToImage(firstFile);
          setProps((props) => (props.image = newImage));
        }}
      />
    </BaseComposerSection>
  );
};

LogoSection.craft = {
  custom: {
    controlOutputRender: true,
  },
  props: {
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    height: '64px',
    sectionType: 'LogoSection',
  },
  related: {
    output: LogoOutput,
    settings: LogoSettings,
    tool: LogoTool,
  },
};
