import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyPhoneHours = lazy(() => import('./svg-components/phone-hours'));

const PhoneHours = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyPhoneHours {...rest} />
  </Suspense>
);

export default PhoneHours;
