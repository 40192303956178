import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { CollectPaymentModalBody, CollectPaymentModalSteps } from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { MultiStepModal, PaymentsMultiStepModal } from '@frontend/payments-multistep-modal';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ModalControlModalProps } from '@frontend/design-system';
import { CreateInvoice, PreviewInvoice, SelectInvoice } from './modal-steps';
import { PersonInvoiceModalSteps, PersonInvoiceModalStep } from './use-person-invoice-payment-modal';

type PaymentModalProps = ModalControlModalProps & {
  personId: string;
  person?: PersonTypes.Person;
  initialStep?: PersonInvoiceModalStep | CollectPaymentModalSteps;
};

export const PaymentModal = ({ personId, person, initialStep, ...rest }: PaymentModalProps) => {
  const { t } = useTranslation('payments');
  const { selectedLocationIds } = useAppScopeStore();
  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: selectedLocationIds,
  });

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: selectedLocationIds, personId: personId ?? '' },
    { enabled: !person && !!personId }
  );

  const personData = person ? PersonsV3.PersonHelpers.convertPersonToPersonV3(person) : personQuery.data;
  const personName = personData?.firstName || '';

  return (
    <PaymentsMultiStepModal
      modalProps={rest}
      closeModal={rest.onClose}
      initialStep={initialStep || PersonInvoiceModalSteps.SelectInvoice}
      css={{ maxHeight: 750 }}
      maxWidth={parseInt(theme.spacing(82))}
      stepperWidth={parseFloat(theme.spacing(20))}
    >
      <MultiStepModal.Step
        id={PersonInvoiceModalSteps.SelectInvoice}
        title={t(`{{personName}}'s Outstanding Requests`, { personName })}
      >
        <SelectInvoice personId={personId} />
      </MultiStepModal.Step>
      <MultiStepModal.Step id={PersonInvoiceModalSteps.PreviewInvoice} title={t('Invoice')}>
        <PreviewInvoice />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={PersonInvoiceModalSteps.CreateInvoice}
        title={newRequestUI ? t('Creating Payment Request') : t('Create Invoice')}
        blueHeader={newRequestUI}
      >
        {personData && <CreateInvoice person={personData} />}
      </MultiStepModal.Step>
      <CollectPaymentModalBody />
    </PaymentsMultiStepModal>
  );
};
