import { DashboardWidgetFC } from '@frontend/grid-dashboard';
import { FormsSharedWidget } from './forms-shared-widget';

/**
 * @dashboard-widget
 *
 * id: forms-sent
 * title: Forms Sent
 * description: Show forms sent throughout the week and a graph indicating their delivery timeline.
 * icon: forms-small
 */
export const FormsSentWidget: DashboardWidgetFC = () => {
  return <FormsSharedWidget isFormsSent />;
};

FormsSentWidget.config = {
  size: {
    large: 'small-narrow',
    medium: 'small-narrow',
    small: 'tiny-slim',
    extraSmall: 'tiny-slim',
  },
  feature: 'forms',
};
