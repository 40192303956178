import { TextSection as TextSectionType } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { EmailAssistantModal } from '@frontend/email-assistant-modal';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RTEditor, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { ComposerSection } from '../../types';
import { getFrameStyle } from '../../utils';
import { useContentComposer } from '../content-composer-provider';
import { TextOutput } from '../outputs';
import { TextSettings } from '../settings';
import { TextTool } from '../tools';
import { BaseComposerSection, RTEToolbar } from './floating-toolbar';

export const TextSection: ComposerSection<TextSectionType> = ({ frame, text }) => {
  const { t } = useTranslation('content-composer');
  const { composerMode, promptProps, selectedSectionId } = useContentComposer();
  const isEmailComposer = composerMode !== 'feedback';
  const { replaceSection } = useComposerSection<TextSectionType>();
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <BaseComposerSection
        title={t('Text')}
        css={[
          getFrameStyle(frame),
          {
            '.editor-container': {
              marginTop: '0 !important',
              marginBottom: '0 !important',
              background: 'transparent !important',
              position: 'static',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
            '.editor-scroller': {
              minHeight: 'unset !important',
              resize: 'none',
            },
            '.editor-inner': {
              background: 'transparent !important',
              height: '100%',
              alignSelf: 'flex-start',
              width: '100%',
            },
            // because we have removed the dragging capability, we need to shift the input box to the left
            '.editor-input': {
              padding: '0 !important',
              minHeight: '16px !important', // we don't want it to be that large by default since that's not controlled by the user
            },
            // the placeholder text needs this to left align with the removal of the padding
            '.editor-placeholder': {
              top: '0 !important',
              left: '0 !important',
            },
            // the paragraphs have margins and line-heights by default that shouldn't be there since those aren't controlled by the user
            '.editor-paragraph': {
              margin: 0,
              // lineHeight: '100%', // TODO: waiting on Michelle to confirm this is what we want to do
            },
          },
        ]}
        openAssistantModal={isEmailComposer ? triggerProps.onClick : undefined}
      >
        <RTEToolbar>
          <RTEditor.Editor placeholder={selectedSectionId ? t('Enter text here...') : ''} />
        </RTEToolbar>
      </BaseComposerSection>
      <EmailAssistantModal
        modalProps={modalProps}
        // TODO: talk to Donat about if it would be possible for RTE's `initialHTML` property to be watched (i.e. manually update this state)
        //       this function wouldn't be necessary if that were the case (just need to update the text prop)
        // TODO: double check that this works for undo and redo
        onComplete={(value: string) => replaceSection({ text: value })}
        previousText={text}
        promptProps={promptProps}
        type='text'
      />
    </>
  );
};

TextSection.craft = {
  custom: {
    controlOutputRender: true,
  },
  props: {
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    sectionType: 'TextSection',
    text: '',
  },
  related: {
    output: TextOutput,
    settings: TextSettings,
    tool: TextTool,
  },
};
