import { useState } from 'react';
import { css } from '@emotion/react';
import { VoicemailGreetingType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Button,
  DropdownField,
  SkeletonLoaders,
  useAlert,
  useFormField,
  useModalControl,
} from '@frontend/design-system';
import { AudioPicker, DEFAULT_GREETING_ID, DEFAULT_GREETING_PATH } from '../../../audio-picker/audio-picker';
import { AudioItem } from '../../../audio-picker/types';
import { CreateModal } from '../../../voicemail-box/new/create-button';
import { VoicemailBoxCreateResponse } from '../../../voicemail-box/new/types';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const VoicemailStep = ({
  tenantId,
  settingsTenantLocation,
  initialState,
  callObjectsData,
  onClose,
  onCancelClick,
  onProceedClick,
  onBackClick,
}: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const { modalProps, triggerProps } = useModalControl();

  const [selectedAudioFile, setSelectedAudioFile] = useState<AudioItem | undefined>(undefined);

  const { voicemailBoxes, voicemailBoxesIsLoading } = callObjectsData;

  const getInitialGreetingMediaValue = () => {
    // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
    // and the generated typescript does not know how to handle that correctly.
    const greetingId = initialState?.callObject?.voicemailExpansion?.greetingMediaItemId;

    if (!greetingId) {
      return 'none';
    }

    if (greetingId === DEFAULT_GREETING_ID) {
      return 'default';
    }

    return greetingId;
  };

  const voicemailBoxProps = useFormField({
    required: true,
    type: 'dropdown',
    value: initialState?.callObject?.primitiveId,
  });

  const greetingMediaProps = useFormField({
    required: true,
    type: 'dropdown',
    value: getInitialGreetingMediaValue(),
  });

  const handleProceedClick = () => {
    const voicemailBox = voicemailBoxes?.find((vm) => vm.mailbox.id === voicemailBoxProps.value);

    if (!voicemailBox) {
      alerts.error('Unknown voicemail box data');
      return;
    }

    let greetingMediaFileName;
    let greetingMediaItemId;
    let downloadUrl;
    let greetingType;
    if (greetingMediaProps.value === 'none') {
      greetingMediaFileName = '';
      greetingMediaItemId = '';
      downloadUrl = '';
      greetingType = VoicemailGreetingType_Enum.NO_GREETING;
    } else if (greetingMediaProps.value === 'default') {
      greetingMediaFileName = 'Default Greeting';
      greetingMediaItemId = DEFAULT_GREETING_ID;
      downloadUrl = DEFAULT_GREETING_PATH;
      greetingType = VoicemailGreetingType_Enum.DEFAULT_GREETING;
    } else if (selectedAudioFile) {
      greetingMediaFileName = selectedAudioFile.name;
      greetingMediaItemId = selectedAudioFile.id;
      downloadUrl = selectedAudioFile.path;
      if (selectedAudioFile.type === 'mailbox') {
        greetingType = VoicemailGreetingType_Enum.NUMBERED_GREETING;
      } else {
        greetingType = VoicemailGreetingType_Enum.AUDIO_LIBRARY_GREETING;
      }
    } else {
      alerts.error('Unknown greeting data');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: voicemailBox.mailbox.id,
        primitiveName: voicemailBox.mailbox.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        voicemailExpansion: {
          greetingMediaFileName,
          greetingMediaItemId,
          downloadUrl,
          greetingType,
        },
      },
    });
  };

  const handleOnCreateSuccess = (data: VoicemailBoxCreateResponse) => {
    const mailBoxId = data.mailbox.id;

    voicemailBoxProps.onChange({
      // @ts-expect-error This is complaining thinking that value does not exist on the event target,
      // but it does.
      target: { value: mailBoxId },
    });
  };

  return (
    <>
      <HeaderBar title={t('Voicemail')} onClose={onClose} />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(3)};
        `}
      >
        <SkeletonLoaders.Loader isLoading={voicemailBoxesIsLoading} shape='rectangle' width={320} height={50}>
          <DropdownField
            {...voicemailBoxProps}
            css={css`
              max-width: 300px;
            `}
            name='voicemailBoxId'
            label={t('Select voicemail box')}
          >
            <DropdownField.Option
              css={css`
                padding: 0;
              `}
              key={'create-voicemail-box'}
              value=''
              isSelectable={false}
            >
              <Button
                iconName='plus'
                variant='tertiary'
                onClick={() => triggerProps.onClick()}
                css={css`
                  width: 100%;
                  justify-content: start;
                `}
              >
                {t('Create Voicemail Box')}
              </Button>
            </DropdownField.Option>
            {voicemailBoxes?.map((option) => (
              <DropdownField.Option key={option.mailbox.id} value={option.mailbox.id}>
                {option.mailbox.name}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </SkeletonLoaders.Loader>

        {voicemailBoxProps.value && (
          <AudioPicker
            widths={{ field: '300px', scrubber: '240px' }}
            allowedOptions={{
              add: true,
              custom: true,
              standard: false,
              mailbox: true,
              none: true,
              default: true,
            }}
            mailboxId={voicemailBoxProps.value}
            field={greetingMediaProps}
            name='greetingMediaId'
            labels={{
              field: t('Select voicemail greeting'),
            }}
            onSelect={(item) => {
              // NOTE: this callback function will not be called if the user selects the "No Greeting option". The
              // value will still be set on the field (greetingMediaProps.value), but the onSelect function will not
              // be called.
              setSelectedAudioFile(item);
            }}
            tenantId={tenantId}
          />
        )}
      </div>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!voicemailBoxProps.value || !greetingMediaProps.value}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onCancelClick}
        onBackClick={onBackClick}
      />

      {settingsTenantLocation && (
        <CreateModal
          tenantLocation={settingsTenantLocation}
          modalProps={modalProps}
          onSuccess={handleOnCreateSuccess}
        />
      )}
    </>
  );
};
