import { MouseEventHandler, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, removeNonDigits } from '@frontend/phone-numbers';
import { WeaveLocationGroup } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Chip, Text, TextLink, Truncated, TableRowActions } from '@frontend/design-system';
import { PhoneLocationFilterStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { IndexTable } from '../common/index-table';
import { PhoneOverrideType } from '../override/types';
import { NumbersPanelTabs } from './numbers-panel';
import { ExtendedCallRoute } from './types';

type CallRoutesListTableProps = {
  tableData: ExtendedCallRoute[];
  isLoading: boolean;
  settingsTenantLocation: WeaveLocationGroup;
  tableRowActions?: TableRowActions<ExtendedCallRoute> | undefined;
  clickableCallRoutes?: boolean;
  initialSelectedCallRouteId?: string;
  unsavedChangesCallRouteIds?: string[];
  onMoreTextClick?: (tab: NumbersPanelTabs, callRoute?: ExtendedCallRoute) => void;
  onRowSelect?: (callRoute: ExtendedCallRoute) => void;
  filteredLocations: string[];
  setLocationFilters: PhoneLocationFilterStore['setLocationFilters'];
};

export const CallRoutesListTable = ({
  tableData,
  isLoading,
  tableRowActions,
  settingsTenantLocation,
  clickableCallRoutes = true,
  initialSelectedCallRouteId,
  unsavedChangesCallRouteIds,
  onMoreTextClick,
  onRowSelect,
  filteredLocations,
  setLocationFilters,
}: CallRoutesListTableProps) => {
  const { t } = useTranslation('phone');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const [searchValue, setSearchValue] = useState('');

  /**
   * The table data filtered by the global search input.
   */
  const filteredTableData = useMemo(() => {
    const value = searchValue.trim();
    if (!value) {
      return tableData;
    }

    return tableData.filter((row) => {
      // Check if there are numbers in the search value since the removeNonDigits function
      // will remove all except numeric chars which can create an empty string if there
      // are no numbers in the string.
      const searchByNumbers = removeNonDigits(value).length > 2;

      // check if found a match in row name first
      if (row.name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      } else if (
        searchByNumbers &&
        row.phoneNumbers?.some((phoneNumber) =>
          String(phoneNumber?.phoneNumber?.nationalNumber).includes(removeNonDigits(value))
        )
      ) {
        // check if found a match in phone number
        return true;
      }
      // check if found a match in extensions
      else if (searchByNumbers && String(row.extensionNumbers)?.includes(removeNonDigits(value))) {
        return true;
      }
      return false;
    });
  }, [tableData, searchValue, filteredLocations]);

  return (
    <IndexTable
      labelsColumnPosition={3}
      tenantLocation={settingsTenantLocation}
      idAccessor={(data) => data.callRouteId}
      nameAccessor={(data) => data.name}
      labelsAccessor={(data) => [data.locationId]}
      filteredLocations={filteredLocations}
      DeleteIconButton={() => null}
      setFilteredLocations={(locations) => {
        setLocationFilters({ callRoutesIndex: locations });
      }}
      trackingIdBase={trackingId({ context: 'setting', feature: 'call-routes', details: 'location-filter' })}
      canDeleteRow={() => false}
      tableConfig={{
        globalTrackingId: 'call-routes-settings-list-table',
        hasGlobalSearch: true,
        isSelectable: onRowSelect ? true : false,
        rowSelectionConfig: {
          isSingleSelect: true,
          onSelectionToggle: (_, data) => onRowSelect?.(data[0]),
          initialState: tableData.reduce((acc, row) => {
            if (row.callRouteId === initialSelectedCallRouteId) {
              return { ...acc, [row.callRouteId]: true };
            }
            return acc;
          }, {}),
        },
        globalSearchConfig: {
          searchHandler: (searchValue) => setSearchValue(searchValue.trim().length < 3 ? '' : searchValue.trim()),
          debounceDelay: 500,
        },
        isLoading,
        data: filteredTableData ?? [],
        uniqueRowId: (row) => row.callRouteId,
        colConfig: [
          {
            id: 'name',
            Header: t('Name'),
            accessor: 'name',

            cellRenderer: (name, callRoute) =>
              clickableCallRoutes ? (
                <>
                  <Truncated
                    as={TextLink}
                    textAlign='left'
                    css={css`
                      width: fit-content;
                    `}
                    onClick={() => {
                      if (callRoute) {
                        settingsNavigate({
                          to: '/phone/call-routes/:id',
                          params: { id: callRoute.callRouteId },
                        });
                      }
                    }}
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-routes',
                      details: 'call-route-name-link',
                    })}
                  >
                    {name}
                  </Truncated>
                  {unsavedChangesCallRouteIds?.includes(callRoute?.callRouteId ?? '') && (
                    <Text
                      size='small'
                      color='subdued'
                      as='span'
                      css={css`
                        font-style: italic;
                        padding-left: ${theme.spacing(1)};
                      `}
                    >
                      {t('Unsaved Changes')}
                    </Text>
                  )}
                </>
              ) : (
                <Truncated>{name}</Truncated>
              ),
            sortType: 'string',
            width: 204,
          },
          {
            id: 'phoneNumbers',
            Header: t('Phone Numbers'),
            accessor: 'phoneNumbers',
            cellRenderer: (phoneNumbers, callRoute) => {
              if (!phoneNumbers || phoneNumbers.length === 0) {
                return '--';
              }

              return (
                <TextWithMore
                  text={formatPhoneNumber(phoneNumbers[0].phoneNumber.nationalNumber)}
                  count={phoneNumbers.length}
                  onClick={() => onMoreTextClick?.(NumbersPanelTabs.PHONE_NUMBERS, callRoute)}
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'more-phone-numbers-btn',
                  })}
                />
              );
            },
            width: 236,
            disableSortBy: true,
          },
          {
            id: 'extension',
            Header: t('Extension'),
            accessor: 'extensionNumbers',
            cellRenderer: (extensions, callRoute) => {
              if (!extensions || extensions.length === 0) {
                return '--';
              }

              return (
                <TextWithMore
                  text={extensions[0]}
                  count={extensions.length}
                  onClick={() => onMoreTextClick?.(NumbersPanelTabs.EXTENSIONS, callRoute)}
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'more-extensions-btn',
                  })}
                />
              );
            },
            width: 138,
            sortType: 'number',
          },
          {
            id: 'overrideStatus',
            Header: t('Override Status'),
            accessor: 'callRoutePhoneOverride',
            cellRenderer: (override?: PhoneOverrideType) => {
              if (!override) {
                return <Chip variant='neutral'>{t('None')}</Chip>;
              } else {
                return override.enabled ? (
                  <Chip variant='warn' leftElement={<Icon name='alert-small' />}>
                    {t('Active')}
                  </Chip>
                ) : (
                  <Chip variant='primaryDark' leftElement={<Icon name='clock-small' />}>
                    {t('Scheduled')}
                  </Chip>
                );
              }
            },
            width: 204,
            disableSortBy: true,
          },
        ],
        rowActions: tableRowActions,
      }}
    />
  );
};

const TextWithMore = ({
  text,
  count,
  onClick,
  trackingId,
}: {
  text: string;
  count: number;
  onClick: MouseEventHandler<HTMLElement>;
  trackingId?: string;
}) => {
  const { t } = useTranslation('phone');
  return (
    <Text>
      {text}
      {count > 1 && (
        <TextLink
          css={css`
            margin-left: ${theme.spacing(0.5)};
          `}
          onClick={onClick}
          trackingId={trackingId}
        >
          {t('+{{count}} more', { count: count - 1 })}
        </TextLink>
      )}
    </Text>
  );
};
