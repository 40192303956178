import { getInitialParams } from '@frontend/env';
import { sentry } from '@frontend/tracking';

const { backendApi } = getInitialParams();

export const resetPassword = async (email: string): Promise<Record<string, any>> => {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  };

  try {
    const res = await fetch(`${backendApi}/portal/v1/users/resetPassword`, options);
    return await res.json();
  } catch (err) {
    sentry.error({
      topic: 'auth',
      error: err,
      addContext: {
        name: 'auth',
        context: {
          errMessage: 'password reset',
        },
      },
    });
    throw err;
  }
};
