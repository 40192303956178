import { i18next } from '@frontend/i18n';
import { ConnectToNetwork } from './connect-to-wifi';
import { RegisterTerminalModalSteps } from './hooks';
import { GeneratePairingCode, PairYourTerminal } from './pair-terminal';
import { InstallBattery, PlugInTerminal, TurnTerminalOn } from './power-up-terminals';

export const REGISTER_TERMINAL_STEPS_TITLES = {
  [RegisterTerminalModalSteps.InstallBattery]: {
    title: i18next.t('Power Up Terminal', { ns: 'payments' }),
    heading: i18next.t('Power Up Terminal', { ns: 'payments' }),
  },
  [RegisterTerminalModalSteps.PlugInTerminal]: {
    title: i18next.t('Power Up Terminal', { ns: 'payments' }),
    heading: i18next.t('Power Up Terminal', { ns: 'payments' }),
  },
  [RegisterTerminalModalSteps.TurnTerminalOn]: {
    title: i18next.t('Power Up Terminal', { ns: 'payments' }),
    heading: i18next.t('Power Up Terminal', { ns: 'payments' }),
  },
  [RegisterTerminalModalSteps.ConnectToNetwork]: {
    title: i18next.t('Connect to Network', { ns: 'payments' }),
    heading: i18next.t('Connect to Network', { ns: 'payments' }),
  },
  [RegisterTerminalModalSteps.GeneratePairingCode]: {
    title: i18next.t('Register Terminal', { ns: 'payments' }),
    heading: i18next.t('Register Your Terminal', { ns: 'payments' }),
  },
  [RegisterTerminalModalSteps.PairYourTerminal]: {
    title: i18next.t('Register Terminal', { ns: 'payments' }),
    heading: i18next.t('Register Your Terminal', { ns: 'payments' }),
  },
};

export const getStepperSteps = () =>
  Object.entries(REGISTER_TERMINAL_STEPS_TITLES).map(([key, { title }]) => ({
    id: key,
    title,
  }));

export type StepperProps = {
  components: {
    Footer: React.ComponentType;
  };
  label: string;
  id: string | number;
  parentId?: string | number;
  children: React.ReactNode;
};
type RegisterTerminalModalAllStepsProps = {
  goToBigBang?: () => void; // handler that takes you to the start of the flow from where this flow starts
  goToStep?: (step: RegisterTerminalModalSteps) => void; // handler that takes you to the step in the flow
  goBack?: () => void; // handler that takes you back to the previous step
  onComplete: () => void; // handler that takes you to the next step in the flow
  onRegisterTerminal: () => void; // handler that takes you to the next step in the flow
  Stepper: React.FC<StepperProps>; // component that renders the stepper
  locationId?: string;
  stripeLocationId?: string;
  paymentsUrl: string | null;
};
export const RegisterTerminalModalAllSteps = ({
  goToBigBang,
  goToStep,
  goBack,
  onComplete,
  onRegisterTerminal,
  Stepper,
  locationId,
  stripeLocationId,
  paymentsUrl,
}: RegisterTerminalModalAllStepsProps) => {
  return (
    <>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.InstallBattery].heading}
        id={RegisterTerminalModalSteps.InstallBattery}
      >
        <InstallBattery goBack={goToBigBang} goToStep={goToStep} />
      </Stepper>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.PlugInTerminal].heading}
        id={RegisterTerminalModalSteps.PlugInTerminal}
        parentId={RegisterTerminalModalSteps.InstallBattery}
      >
        <PlugInTerminal goBack={goBack} goToStep={goToStep} />
      </Stepper>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.TurnTerminalOn].heading}
        id={RegisterTerminalModalSteps.TurnTerminalOn}
        parentId={RegisterTerminalModalSteps.InstallBattery}
      >
        <TurnTerminalOn goBack={goBack} goToStep={goToStep} />
      </Stepper>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.ConnectToNetwork].heading}
        id={RegisterTerminalModalSteps.ConnectToNetwork}
        parentId={RegisterTerminalModalSteps.TurnTerminalOn}
      >
        <ConnectToNetwork goBack={goBack} goToStep={goToStep} />
      </Stepper>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.GeneratePairingCode].heading}
        id={RegisterTerminalModalSteps.GeneratePairingCode}
      >
        <GeneratePairingCode goBack={goBack} goToStep={goToStep} />
      </Stepper>
      <Stepper
        components={{ Footer: () => null }}
        label={REGISTER_TERMINAL_STEPS_TITLES[RegisterTerminalModalSteps.PairYourTerminal].heading}
        id={RegisterTerminalModalSteps.PairYourTerminal}
        parentId={RegisterTerminalModalSteps.GeneratePairingCode}
      >
        <PairYourTerminal
          goBack={goBack}
          goToStep={goToStep}
          onSuccess={onComplete}
          onTerminalPaired={onRegisterTerminal}
          paymentsUrl={paymentsUrl ?? undefined}
          locationId={locationId}
          stripeLocationId={stripeLocationId}
        />
      </Stepper>
    </>
  );
};

RegisterTerminalModalAllSteps.displayName = 'RegisterTerminalStep';
