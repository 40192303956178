import { useMemo } from 'react';
import { css } from '@emotion/react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { DeviceSize } from '@frontend/device-size-field';
import { MiniChatHooks } from '@frontend/mini-chat';
import { theme } from '@frontend/theme';
import { BaseFieldProps, DynamicFieldAttributes, SpinningLoader, useFormField } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../constants';
import { ComposerMode } from '../../../types';
import { isValidSchemaSection } from '../../../utils';
import { ContentComposer } from '../../content-composer';
import {
  LogoSection,
  TextSection,
  ImageSection,
  ButtonSection,
  CustomCodeSection,
  FooterSection,
} from '../../sections';
import { AssistantTool } from '../../tools';
import { emailComposerMigrateLegacyToSchemaType } from './email-composer-migrate-legacy-to-schema-type';
import { EmailFooter, OnSaveChanges, OnTemplateNameChange } from './email-footer';
import { EmailSubjectHeader } from './email-header';

const CUSTOM_HEIGHT = 211;

type Props = {
  composerMode: ComposerMode;
  createTemplateAccess: boolean;
  editTemplateAccess: boolean;
  isLoading?: boolean;
  locationIds: string[];
  onBack: () => void;
  onSave: (data: OnSaveChanges, applyChanges: boolean) => void;
  onTemplateNameSave: (data: OnTemplateNameChange) => void;
  subject: string;
  subjectDynamicFields?: DynamicFieldAttributes[];
  templateJson: string;
  templateTitle?: string;
  templateTypeSlug: TemplateType_Slug;
  textDynamicFields?: DynamicFieldAttributes[];
  trackingPrefix: string;
};

// @params createTemplateAccess - boolean to determine if the user has access to create a new template
// @params editTemplateAccess - boolean to determine if the user has access to edit the template
// @params isLoading - boolean to determine if the template is still loading
// @params locationIds - array of selected location ids from the form
// @params onBack - callback to handle the back button click
// @params onSave - callback function that fires when the user clicks continue
// @params onTemplateNameSave - callback function that fires when the user clicks confirm & save template
// @params subject - email subject
// @params templateJson - stringified JSON of the template which will show in the content composer
// @params templateTitle - template title (derived from the fetched template data)

export const EmailComposer = ({
  composerMode,
  createTemplateAccess,
  editTemplateAccess,
  isLoading,
  locationIds,
  onBack,
  onSave,
  onTemplateNameSave,
  subject,
  subjectDynamicFields,
  templateJson,
  templateTitle,
  templateTypeSlug,
  textDynamicFields,
  trackingPrefix,
}: Props) => {
  // Form state
  const subjectProps = useFormField({ type: 'text', value: subject }, [subject]);
  const deviceSizeProps = useFormField({ type: 'optionswitch', value: 'desktop' });

  // Mini Chat styling
  const paddingBottom = MiniChatHooks.useMiniChatBottomStyling();

  const formattedTemplateJson = useMemo(() => {
    if (!templateJson) return undefined;

    const parsedTemplateJson = JSON.parse(templateJson);

    const firstSection = parsedTemplateJson?.sections?.[0];
    // if the section is a legacy type, migrate it to the valid section schema type
    if (!isValidSchemaSection(firstSection))
      return emailComposerMigrateLegacyToSchemaType(parsedTemplateJson).formattedSections;

    return parsedTemplateJson;
  }, [templateJson]);

  return (
    <div
      css={css`
        height: 100%;
        border-radius: ${theme.borderRadius.medium};
        padding-bottom: ${paddingBottom}px;
        transition: padding-bottom 300ms ease-in-out;

        .content-composer-container {
          grid-template-areas: 'header header' 'sidebar main' 'footer footer';
          grid-template-rows: auto 1fr auto;
          height: calc(100vh - ${CUSTOM_HEIGHT}px);

          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            grid-template-areas: 'header header' 'main main' 'footer footer';
            height: calc(100vh - ${theme.spacing(25)});
          }
        }
      `}
    >
      {isLoading ? (
        <div css={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(5), width: '100%' }}>
          <SpinningLoader />
        </div>
      ) : (
        <ContentComposer
          composerMode={composerMode}
          deviceSizeProps={deviceSizeProps as BaseFieldProps<'desktop' | 'mobile'>}
          initialValue={formattedTemplateJson}
          locationIds={locationIds}
          parts={{
            AssistantTool,
            LogoSection,
            TextSection,
            ImageSection,
            ButtonSection,
            CustomCodeSection,
            FooterSection,
          }}
          subjectDynamicFields={subjectDynamicFields}
          templateTypeSlug={templateTypeSlug}
          textDynamicFields={textDynamicFields}
        >
          <EmailSubjectHeader
            deviceSizeProps={deviceSizeProps as BaseFieldProps<DeviceSize>}
            subjectProps={subjectProps}
            trackingPrefix={trackingPrefix}
          />
          <ContentComposer.Container />
          <EmailFooter
            createTemplateAccess={createTemplateAccess}
            editTemplateAccess={editTemplateAccess}
            onBack={onBack}
            onSave={onSave}
            onTemplateNameSave={onTemplateNameSave}
            subject={subjectProps.value}
            templateTitle={templateTitle}
            trackingPrefix={trackingPrefix}
          />
        </ContentComposer>
      )}
    </div>
  );
};
