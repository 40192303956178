import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { ThreadStatus } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { ContactType_Enum, PersonOrderBy_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { MessagesHooks, MessagesUtils } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, formatPhoneNumberE164, isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ComboboxBase,
  TextField,
  useDebouncedValue,
  useFormField,
  usePopoverMenu,
  Text,
  IconButton,
  useAlert,
} from '@frontend/design-system';
import { containsNoAlphabets, isValidNorthAmericanPhoneNumber } from '../../../../utils';
import { THREAD_CREATION_FIELD_IDS } from './constants';
import { PersonPhonePopoverMenu } from './person-phone-popover-menu';
import { StartAdornment } from './start-adornment';
import { PersonPhoneSelection } from './types';

const PHONE_CONTACT_TYPES: ContactType_Enum[] = [
  ContactType_Enum.PHONE_HOME,
  ContactType_Enum.PHONE_MOBILE,
  ContactType_Enum.PHONE_WORK,
];

enum DefaultSearchType {
  PHONE = 'phone',
  PERSON_NAME = 'person-name',
  NONE = 'none',
}

type PersonPhoneSelectionFieldProps = {
  initValues: {
    personId?: string;
    personPhone?: string;
  };
  selectedPersonPhone?: PersonPhoneSelection;
  selectedGroupId?: string;
  selectedDepartmentId?: string;
  onSelect: (selection: PersonPhoneSelection) => void;
  clearSelection: () => void;
  departmentsByLocation: Record<string, DefaultSms[]>;
};

export const PersonPhoneSelectionField = ({
  initValues,
  selectedPersonPhone,
  selectedGroupId,
  selectedDepartmentId,
  onSelect,
  clearSelection,
  departmentsByLocation,
}: PersonPhoneSelectionFieldProps) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const { getLabel } = MessagesHooks.useConvertContactTypeToLabel();
  const defaultSearchType: DefaultSearchType = initValues.personPhone
    ? DefaultSearchType.PHONE
    : initValues.personId
    ? DefaultSearchType.PERSON_NAME
    : DefaultSearchType.NONE;
  const initSearchValue =
    defaultSearchType === DefaultSearchType.PHONE && initValues.personPhone
      ? initValues.personPhone?.replace('+1', '')
      : '';
  const [showSolidValue, setShowSolidValue] = useState(!!initSearchValue);
  const { onChange: searchFieldOnChange, ...searchFieldProps } = useFormField({
    type: 'text',
  });
  const personName = selectedPersonPhone?.person ? PersonsV3.PersonHelpers.getFullName(selectedPersonPhone.person) : '';
  const phoneLabel =
    selectedPersonPhone?.person && selectedPersonPhone?.personPhone
      ? getLabel(
          PersonsV3.PersonHelpers.getNumberContactType(selectedPersonPhone.person, selectedPersonPhone.personPhone)
        )
      : '';
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value, 500);
  const sanitizedSearchValue = sanitizePhoneNumber(debouncedSearchValue);

  const setSearchFieldValue = (value: string) => {
    searchFieldOnChange({ name: 'person-search', value });
  };

  const handleError = (err: HttpError) => {
    if (
      typeof err.data === 'object' &&
      typeof err.data.message === 'string' &&
      err.data.message.includes('multiple org ids found')
    ) {
      alert.error(
        t('Contacts could not be searched. Please ensure you have only one organization selected, and try again.')
      );
    }
  };

  const { validPhones: initPersonValidPhones, isLoading: initPersonValidityIsLoading } =
    SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
      personId: defaultSearchType === DefaultSearchType.PERSON_NAME && initValues.personId ? initValues.personId : '',
      groupIds: selectedLocationIds,
    });
  useEffect(() => {
    if (initPersonValidityIsLoading) return;
    if (initPersonValidPhones.length === 1) {
      handleSelect({ personPhone: initPersonValidPhones[0]?.number });
    }
  }, [initPersonValidityIsLoading, JSON.stringify(initPersonValidPhones)]);

  const isPhoneSearch = !!debouncedSearchValue && containsNoAlphabets(debouncedSearchValue);
  const personsQuery = PersonsV3.PersonQueries.usePersonSearchLegacyPostInfiniteQuery(
    {
      locationIds: selectedGroupId ? [selectedGroupId] : selectedLocationIds,
      search: debouncedSearchValue,
      contactInfoTypeFilter: PHONE_CONTACT_TYPES,
      page: {
        size: 50,
        order: [{ descending: false, orderBy: PersonOrderBy_Enum.FIRST_NAME }],
      },
    },
    {
      enabled: !!debouncedSearchValue && !isPhoneSearch,
      retry: 1,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            persons: page.persons?.filter((person) => !!person.contactInfo?.length) ?? [],
          })),
        };
      },
      onError: handleError,
    }
  );
  const personsByPhoneQuery = PersonsV3.PersonQueries.useListPersonsByPhoneLegacyInfiniteQuery(
    {
      locationIds: selectedGroupId ? [selectedGroupId] : selectedLocationIds,
      phoneNumber: sanitizePhoneNumber(debouncedSearchValue),
      partialSearch: sanitizePhoneNumber(debouncedSearchValue).length < 10,
      page: {
        size: 50,
      },
    },
    {
      enabled: !!debouncedSearchValue && isPhoneSearch && sanitizePhoneNumber(debouncedSearchValue).length > 3,
      retry: 1,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            persons:
              page.persons?.map((person) => ({
                ...person,
                contactInfo: person.contactInfo?.filter((contact) => PHONE_CONTACT_TYPES.includes(contact.type)),
              })) ?? [],
          })),
        };
      },
      onError: handleError,
    }
  );
  const personsResults = isPhoneSearch
    ? personsByPhoneQuery.data?.pages.flatMap((page) => page.persons) ?? []
    : personsQuery.data?.pages.flatMap((page) => page.persons) ?? [];
  const departmentIdsPerGroupId = Object.fromEntries(
    Object.entries(departmentsByLocation).map(([groupId, depts]) => [
      groupId,
      depts.map((dept) => dept.id).filter(Boolean),
    ])
  );
  const phoneNumberThreadQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        personPhones: [sanitizedSearchValue],
        groupIds: selectedLocationIds,
        departmentIdsPerGroupId,
      }),
    },
    options: {
      enabled: isPhoneNumber(sanitizedSearchValue) && !personsResults.length && !!selectedLocationIds.length,
      select: (data) => {
        return {
          ...data,
          responses: data.responses.filter((response) => {
            if (selectedGroupId && response.locationId !== selectedGroupId) return false;
            if (selectedDepartmentId && response.departmentId !== selectedDepartmentId) return false;
            if (formatPhoneNumberE164(response.personPhone) !== formatPhoneNumberE164(sanitizedSearchValue))
              return false;
            return true;
          }),
        };
      },
    },
  });
  const phoneNumberSearchHasThread = !!phoneNumberThreadQuery.data?.responses.find(
    (response) => response.status !== ThreadStatus.NEW
  );
  const { getMenuProps, getItemProps, getTriggerProps, activeIndex, setActiveIndex, refs, open } = usePopoverMenu({
    placement: 'bottom',
    interactionOptions: {
      listNavigation: { virtual: true },
      click: { enabled: false },
      typeahead: { enabled: false },
    },
    middlewareOptions: {
      offset: 20,
    },
  });

  const triggerProps = getTriggerProps({
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        const selection = activeIndex === null ? undefined : personsResults[activeIndex];
        if (activeIndex !== null && selection) {
          const firstNumber = selection.contactInfo?.[0];
          if (firstNumber) {
            handleSelect({
              person: selection,
              personPhone: firstNumber.destination || undefined,
            });
            setActiveIndex(null);
          }
        } else if (isValidNorthAmericanPhoneNumber(searchFieldProps.value) && !personsResults.length) {
          handleSelect({
            personPhone: sanitizePhoneNumber(searchFieldProps.value),
          });
        }
      }
    },
    onChange: (e) => {
      clearSelection();
      searchFieldOnChange(e);
      open();
    },
    onBlurCapture: () => {
      if (
        !personsResults.length &&
        !selectedPersonPhone?.personPhone &&
        isValidNorthAmericanPhoneNumber(searchFieldProps.value)
      ) {
        handleSelect({ personPhone: sanitizePhoneNumber(searchFieldProps.value) });
      }

      if (!searchFieldProps.value) setShowSolidValue(true);
    },
  });
  const solidFieldProps = useFormField({
    type: 'text',
  });

  const handleSelect = (selection: PersonPhoneSelection) => {
    if (selection.personPhone && containsNoAlphabets(searchFieldProps.value)) {
      setSearchFieldValue(MessagesUtils.formatPhoneWithCountryCode(selection.personPhone));
    } else if (!containsNoAlphabets(searchFieldProps.value) && selection.person) {
      setSearchFieldValue(PersonsV3.PersonHelpers.getFullName(selection.person));
    }
    onSelect(selection);
    setShowSolidValue(true);
  };

  return showSolidValue ? (
    <TextField
      {...solidFieldProps}
      startAdornment={<StartAdornment text={t('To:')} />}
      value=''
      label=''
      name='person-search-solid'
      onFocusCapture={() => {
        setShowSolidValue(false);
        open();
      }}
      onClickCapture={() => {
        setShowSolidValue(false);
        open();
      }}
      id={THREAD_CREATION_FIELD_IDS.toField.solid}
      data-trackingid={`${InboxPrefixes.Thread}-new-message-to-field`}
      css={{
        position: 'relative',
      }}
    >
      {selectedPersonPhone?.personPhone ? (
        <Text
          color={personName ? 'light' : undefined}
          css={{
            position: 'absolute',
            left: theme.spacing(6),
            cursor: 'text',
          }}
        >
          {personName && (
            <span
              css={{ fontWeight: theme.font.weight.semibold, color: theme.font.colors.default }}
            >{`${personName} `}</span>
          )}
          {`${phoneLabel} ${formatPhoneNumber(selectedPersonPhone.personPhone)}`.trim()}
        </Text>
      ) : (
        <Text
          css={{
            position: 'absolute',
            left: theme.spacing(6),
            cursor: 'text',
          }}
        >
          {searchFieldProps.value}
        </Text>
      )}
      {(!!selectedPersonPhone?.personPhone || !!searchFieldProps.value) && (
        <IconButton
          onClick={() => {
            clearSelection();
            setSearchFieldValue('');
          }}
          label={t('Clear')}
          size='small'
          css={{
            position: 'absolute',
            right: theme.spacing(1),
          }}
        >
          <Icon name='x-small' />
        </IconButton>
      )}
    </TextField>
  ) : (
    <ComboboxBase
      Input={
        <TextField
          {...triggerProps}
          {...searchFieldProps}
          name='person-search'
          label=''
          fieldComponentProps={{ ref: refs.setReference, autoComplete: 'off' }}
          startAdornment={<StartAdornment text={t('To:')} />}
          data-trackingid={`${InboxPrefixes.Thread}-new-message-to-field`}
          autoFocus
          id={THREAD_CREATION_FIELD_IDS.toField.default}
        >
          {(!!selectedPersonPhone?.personPhone || !!searchFieldProps.value) && (
            <IconButton
              onClick={() => {
                clearSelection();
                setSearchFieldValue('');
              }}
              label={t('Clear')}
              size='small'
              css={{
                position: 'absolute',
                right: theme.spacing(1),
              }}
            >
              <Icon name='x-small' />
            </IconButton>
          )}
        </TextField>
      }
      menuProps={getMenuProps()}
      menuStyles={css({ width: 'min(600px, 90vw)' })}
    >
      <PersonPhonePopoverMenu
        personsResults={personsResults}
        personsQueryIsLoading={isPhoneSearch ? personsByPhoneQuery.isLoading : personsQuery.isLoading}
        personsQueryIsError={isPhoneSearch ? personsByPhoneQuery.isError : personsQuery.isError}
        activeIndex={activeIndex ?? -1}
        getItemProps={getItemProps}
        handleSelectNumber={handleSelect}
        phoneNumberSearchHasThread={phoneNumberSearchHasThread}
        debouncedSearchValue={debouncedSearchValue}
        isPhoneSearch={isPhoneSearch}
      />
    </ComboboxBase>
  );
};
