import { useEffect, useMemo, useState } from 'react';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useQueries, useQueryClient } from 'react-query';
import {
  CustomizationFlagQueries,
  CustomizationFlagTypes,
  useCustomizationFlagShallowStore,
} from '@frontend/api-customization-flags';
import { DataSourcesHooks, DataSourcesQueries } from '@frontend/api-data-sources';
import { LocationsApi } from '@frontend/api-locations';
import { getUser, isWeaveUser, setLoginData } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { Location, useLastUsedVerticalShallowStore, useLocationDataShallowStore } from '@frontend/location-helpers';
import { SchemaFeatureFlags } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { multiLocationCheck } from './multi-location-checker';

export const determineOrgID = (locationInfo: Location): string => {
  return !!locationInfo.ParentID ? locationInfo.ParentID : locationInfo.LocationID;
};

export const useHandleScopeChange = () => {
  const { selectedLocationIds, accessibleGroupIds, accessibleLocationData, accessibleGroupData } = useAppScopeStore();
  const { setCustomizationFlags, setFeatureFlags } = useAppFlagStore();
  const [completionState, setCompletionState] = useState({ feature: false, customization: false });

  const locations = useMemo(
    () =>
      (Object.keys(accessibleLocationData).length === 0
        ? [
            ...selectedLocationIds.map((locationId) => accessibleLocationData[locationId]),
            // Allow parent locations to be selected as well for now
            ...accessibleGroupIds.map((groupId) => accessibleGroupData[groupId]),
          ]
        : selectedLocationIds.map((locationId) => accessibleLocationData[locationId])
      ).filter(Boolean),
    [selectedLocationIds, accessibleLocationData, accessibleGroupData, accessibleGroupIds]
  );
  if (locations[0]) {
    sentry.instance.setContext('Location', {
      name: locations[0].name,
      id: locations[0].id,
    });
    sentry.instance.setTag('locationId', locations[0].id);
  }

  /**
   * We want to useQueries here because we can cache the feature flags per-location.
   * Updating to react query v5 will give us better tools to handle this
   */
  const ffQueries = useQueries(
    locations.map((location) => {
      return {
        queryKey: [location.id, 'features'],
        queryFn: () => {
          return SchemaFeatureFlags.ListLocationFlags(
            { locationId: location.id },
            { headers: { 'Location-Id': location.id } }
          );
        },
      };
    })
  );

  const ffQueriesReady = ffQueries.every((query) => query.isSuccess);

  useEffect(() => {
    if (locations.length === 0) {
      setCompletionState((prev) => ({ ...prev, feature: true }));
    } else if (locations.length > 0 && ffQueriesReady) {
      ffQueries.forEach((query, index) => {
        const locationId = locations[index].id;
        const featureFlags = query.data;
        if (featureFlags.flags) {
          setFeatureFlags({ locationId, flags: featureFlags.flags });
          setCompletionState((prev) => ({ ...prev, feature: true }));
        }
      });
    }
    /**
     * It is important that only these variables will trigger this effect.
     * `ffQueries` will always be a new array, so it will always trigger the effect if used as a dependency
     */
  }, [locations, ffQueriesReady]);

  const customizationQueries = useQueries(
    locations.map((location) => {
      return {
        queryKey: [location.id, 'customization'],
        queryFn: () => {
          return CustomizationFlagQueries.getCustomizationFlagsByLocationId(location.id);
        },
      };
    })
  );

  const customizationQueriesReady = customizationQueries.every((query) => query.isSuccess);

  useEffect(() => {
    if (locations.length === 0) {
      setCompletionState((prev) => ({ ...prev, customization: true }));
    } else if (customizationQueriesReady) {
      customizationQueries.forEach((query, index) => {
        const locationId = locations[index].id;
        const customizationFlag = query.data;
        if (customizationFlag) {
          setCustomizationFlags({ locationId, flags: customizationFlag });
          setCompletionState((prev) => ({ ...prev, customization: true }));
        }
      });
    }
    /**
     * It is important that only these variables will trigger this effect.
     * `ffQueries` will always be a new array, so it will always trigger the effect if used as a dependency
     */
  }, [locations, customizationQueriesReady]);

  useEffect(() => {
    if (
      (locations.length === 0 && accessibleGroupIds.length + Object.keys(accessibleLocationData).length > 0) ||
      (isWeaveUser() && locations.length === 0 && Object.keys(accessibleLocationData).length == 0)
    ) {
      setCompletionState({ feature: true, customization: true });
    }
  }, [accessibleLocationData, accessibleGroupIds, locations]);

  /**
   * Everything below this is a copy of the original code for demo source ids.
   * It currently does not consider multiple locations, which is probably something we should be fixing
   */
  // const locationForDemoSource = locations[0];

  // const hasCustomContacts =
  //   customizationFlags[locationForDemoSource.id].get(Feature.CUSTOM_CONTACTS)?.state ===
  //   CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  // const hasIntegration =
  //   customizationFlags[locationForDemoSource.id].get(Feature.INTEGRATED)?.state ===
  //   CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  // const { setDemoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('setDemoSourceIds');

  // const demoSourceQueries = useQuery({
  //   queryKey: [locationForDemoSource.id, 'demo-sources'],
  //   queryFn: () => {
  //     return SchemaIntegrationsService.GetDemoLocationDataSources({
  //       locationId: locationForDemoSource.groupId || locationForDemoSource.id,
  //     });
  //   },
  //   select: (data) => {
  //     return (data?.dataSources ?? [])
  //       .filter((ds) => {
  //         return (
  //           ds.sourceId !== undefined &&
  //           ((hasCustomContacts === true && ds.sourceType === DemoSourceType.CUSTOM_CONTACT) ||
  //             (hasIntegration === false && ds.sourceType === DemoSourceType.CSV) ||
  //             (hasIntegration === true &&
  //               (ds.sourceType === DemoSourceType.OTHERPMS ||
  //                 (locationForDemoSource.vertical === Vertical.VET && ds.sourceType === DemoSourceType.WEAVE_PMS_VET) ||
  //                 (locationForDemoSource.vertical !== Vertical.VET &&
  //                   ds.sourceType === DemoSourceType.WEAVE_PMS_NON_VET))))
  //         );
  //       })
  //       .map((ds) => ({
  //         ...ds,
  //         preference: findDemoDataSourcePreference({
  //           dataSource: ds,
  //           location: locationForDemoSource,
  //           hasCustomContacts: hasCustomContacts || false,
  //           hasIntegration: hasIntegration || false,
  //         }),
  //       }))
  //       .sort((a, b) => a.preference - b.preference)
  //       .map((ds) => ds.sourceId)
  //       .filter(Boolean);
  //   },
  //   enabled: locations[0].opsType === OpsType.DEMO,
  // });

  // useEffect(() => {
  //   if (demoSourceQueries.data) {
  //     setDemoSourceIds(demoSourceQueries.data);
  //   }
  // }, [demoSourceQueries.data]);

  return {
    flagsInitialized: completionState.customization && completionState.feature,
  };
};

// Taken from api-data-sources/src/queries.ts
// const _findDemoDataSourcePreference = ({
//   dataSource: ds,
//   location,
//   hasCustomContacts,
//   hasIntegration,
// }: {
//   dataSource: DemoDataSource;
//   location: WeaveLocation;
//   hasCustomContacts: boolean;
//   hasIntegration: boolean;
// }) => {
//   if (
//     (hasIntegration === false && ds.sourceType === DemoSourceType.CSV) ||
//     (hasIntegration === true &&
//       (ds.sourceType === DemoSourceType.OTHERPMS ||
//         (location.vertical === Vertical.VET && ds.sourceType === DemoSourceType.WEAVE_PMS_VET) ||
//         (location.vertical !== Vertical.VET && ds.sourceType === DemoSourceType.WEAVE_PMS_NON_VET)))
//   ) {
//     return 1;
//   } else if (hasCustomContacts === true && ds.sourceType === DemoSourceType.CUSTOM_CONTACT) {
//     return 2;
//   } else {
//     return 3;
//   }
// };

// DEPRECATE THIS

interface ConfigureLocationDataFnParams {
  newLocationId: string;
  onSuccess?: (locationInfo: Location) => Promise<void> | void;
  onError?: (error: string) => Promise<void> | void;
}

const GENERIC_ERROR_MESSAGE = i18next.t(
  'Failed to load location information. Please contact support at (888) 579-5668 option 1',
  { ns: 'base' }
);

export const useConfigureLocation = () => {
  const { multiSelectedIds, setIsConfiguredLocationInfo, setMultiSelectedIds, setLocationInfo } =
    useLocationDataShallowStore(
      'multiSelectedIds',
      'setIsConfiguredLocationInfo',
      'setMultiSelectedIds',
      'setLocationInfo'
    );
  const { t } = useTranslation('base');
  const { setLastUsedVertical } = useLastUsedVerticalShallowStore('setLastUsedVertical');
  const configureCustomizationFlags = useConfigureCustomizationFlags();
  const configureDemoLocationSourceIds = useConfigureDemoLocationSourceIds();
  const queryClient = useQueryClient();

  const configureLocationData = async ({
    newLocationId,
    onSuccess,
    onError,
  }: ConfigureLocationDataFnParams): Promise<void> => {
    setIsConfiguredLocationInfo(false);
    try {
      http.setLocationIdHeader(newLocationId);
      const locationInfo = await LocationsApi.getLocation(newLocationId).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      queryClient.setQueryData(['location-data', newLocationId], locationInfo);
      // casting here because the Vertical from the getLocation call is capital case, whereas the Vertical enum is upper case.
      setLastUsedVertical(locationInfo.Vertical.toUpperCase() as Vertical);

      const { isMultiLocation, isParentLocation, childLocations } = await multiLocationCheck({
        locationId: locationInfo.LocationID,
        parentId: locationInfo.ParentID,
      }).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      setLocationInfo({ location: locationInfo, childLocations, isMultiLocation, isParentLocation });
      const user = getUser();
      if (user) {
        setLoginData(user.userID, { lastLocationId: newLocationId });
      }
      localStorage.setItem('locationId', locationInfo.LocationID);
      sentry.instance.setContext('Location', {
        name: locationInfo.Name,
        id: locationInfo.LocationID,
      });
      sentry.instance.setTag('locationId', locationInfo.LocationID);

      const set = new Set(multiSelectedIds);
      set.add(locationInfo.LocationID);
      setMultiSelectedIds([...set]);

      const customizationFlags = await configureCustomizationFlags(newLocationId).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      await configureDemoLocationSourceIds({
        location: locationInfo,
        customizationFlags,
      }).catch(() => {
        throw new Error(
          t(
            "Viewing Demo Location. If you believe you're seeing this message in error, please contact support at (888) 579-5668 option 1"
          )
        );
      });

      if (onSuccess && typeof onSuccess === 'function') {
        await onSuccess(locationInfo);
      }
    } catch (error) {
      if (onError && typeof onError === 'function') {
        const message = error instanceof Error ? error.message : GENERIC_ERROR_MESSAGE;
        await onError(message);
      }
      console.error(error);
    }

    setIsConfiguredLocationInfo(true);
  };

  return { configureLocationData };
};

const useQueryCustomizationFlags = () => {
  const qc = useQueryClient();
  return ({ locationId }: { locationId: string }) =>
    qc.fetchQuery({
      queryKey: [locationId, 'customization-flags'],
      queryFn: () => CustomizationFlagQueries.getCustomizationFlagsByLocationId(locationId),
    });
};

const useConfigureCustomizationFlags = () => {
  const { setFlags } = useCustomizationFlagShallowStore('setFlags');
  const cfQuery = useQueryCustomizationFlags();
  return async (locationId: string) => {
    const customizationFlags = await cfQuery({ locationId: locationId });
    setFlags(customizationFlags);
    return customizationFlags;
  };
};

const useConfigureDemoLocationSourceIds = () => {
  const { setDemoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('setDemoSourceIds');
  const qc = DataSourcesQueries.useQueryDemoLocationSourcesIds();
  return async (config: { location: Location; customizationFlags: CustomizationFlagTypes.CustomizationFlag[] }) => {
    const sourceIds = await qc(config);
    setDemoSourceIds(sourceIds);
  };
};
