import { SelectedLocationProvider } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { PaymentsMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { ContentLoader, ModalControlModalProps } from '@frontend/design-system';
import { CollectPaymentModalBody } from './collect-payment-body';
import { CollectPaymentModalSteps } from './hooks';

interface CollectPaymentModalProps {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
  invoiceId?: string;
  skipToStep?: CollectPaymentModalSteps;
}

/**
 * @deprecated Use CollectPaymentMultiModalInstance or one of it's variants that supports multiple processors.
 */
export const CollectPaymentModal = ({ modalProps, closeModal, invoiceId, skipToStep }: CollectPaymentModalProps) => {
  const { invoice, isLoading } = useSelectedInvoice(invoiceId);

  if ((!invoice || isLoading) && modalProps.show) return <ContentLoader show={true} />;
  if (!invoice) return null;

  return (
    <SelectedLocationProvider locationId={invoice.locationId}>
      <PaymentsMultiStepModal
        modalProps={modalProps}
        closeModal={closeModal}
        initialStep={skipToStep || CollectPaymentModalSteps.PaymentFlowList}
        maxWidth={parseInt(theme.spacing(82))}
        stepperWidth={parseFloat(theme.spacing(20))}
      >
        <CollectPaymentModalBody />
      </PaymentsMultiStepModal>
    </SelectedLocationProvider>
  );
};
