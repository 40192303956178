import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BreadcrumbPathProps, Text } from '@frontend/design-system';
import { URLs } from '../../../../constants';
import { featureFlags } from '../../../../feature-flags';
import { DemoDataBanner } from '../../../demo-data-banner';
import { DidYouKnow } from '../../components';
import { customROITimePeriods } from '../../constants';
import { getDateRangeText } from '../../utils/date-range-text';
import {
  ARCharts,
  ARRoiMultiSubView,
  ARRoiSingleSubView,
  ARRoiTable,
  ARSummary,
  Filters,
  HighlightARValue,
} from '../components';
import { useARRoiShallowStore, useFetchARROIOOverview } from '../hooks';
import { ARRoi } from '../types';

export const ARRoiDashboard = () => {
  const { t } = useTranslation('analytics');
  const {
    filterHintText: subtitle,
    filters,
    setDefinitionsHelperDateRange,
  } = useARRoiShallowStore('filterHintText', 'filters', 'setDefinitionsHelperDateRange');

  const search = useSearch<{ Search: { view: ARRoi } }>();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);

  const subViewTitle = useMemo(() => {
    let title = '';

    switch (search.view) {
      case 'arConversion':
        title = t('Appointment Reminder Conversion');
        break;

      case 'noShowRate':
        title = t('No Show Rate');
        break;
    }

    return title;
  }, [search.view]);

  const breadcrumbs: BreadcrumbPathProps[] = useMemo(() => {
    const values: BreadcrumbPathProps[] = [
      {
        label: t('Analytics'),
        to: URLs.ANALYTICS_DASHBOARD,
      },
      {
        label: t('Appointment Reminder'),
        to: URLs.ROI_APPOINTMENT_REMINDERS,
      },
    ];

    if (subViewTitle) {
      values.push({
        label: subViewTitle,
      });
    }

    return values;
  }, [subViewTitle]);

  const fetchOverviewProps = useFetchARROIOOverview({
    apiParams: {
      payload: {
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        location_id: filters?.locationIds,
      },
    },
  });
  const { aggregated, isLoading } = fetchOverviewProps;

  useEffect(() => {
    setDefinitionsHelperDateRange(
      getDateRangeText({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        timePeriods: customROITimePeriods,
      })
    );
  }, [filters?.startDate, filters?.endDate]);

  return (
    <>
      {isDemoAccount && (
        <DemoDataBanner title={t("You're taking Appointment Reminders Conversion for a test drive with demo data!")} />
      )}
      <Page>
        <Page.Header>
          <Page.Header.Breadcrumbs breadcrumbs={breadcrumbs} />
          <Page.Header.Heading>
            <Page.Header.Title title={subViewTitle || t('Appointment Reminder Conversion')} />
            <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body css={styles.body}>
          <div css={styles.body}>
            <Filters isLoading={isLoading} />

            {subViewTitle ? (
              <>
                {(filters?.locationIds?.length || 0) > 1 ? (
                  <ARRoiMultiSubView {...fetchOverviewProps} />
                ) : (
                  <ARRoiSingleSubView {...fetchOverviewProps} />
                )}
                <ARRoiTable trackingIdBase='ar-roi-patients-table' />
              </>
            ) : (
              <>
                <HighlightARValue value={aggregated.appointmentsCompleted.production} />
                <ARSummary data={aggregated} />
                <ARCharts {...fetchOverviewProps} />
                <DidYouKnow>
                  <Text size='medium'>
                    <Trans t={t}>
                      Practices that use Appointment Reminders have <strong>11%</strong> lower No Show rate compared to
                      practices that do not use Appointment Reminders. 🎉
                    </Trans>
                  </Text>
                </DidYouKnow>
              </>
            )}
          </div>
        </Page.Body>
      </Page>
    </>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};
