import { useEffect } from 'react';
import { ButtonSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import ContentEditable from 'react-contenteditable';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { ComposerSection, Corners } from '../../types';
import { getCornersStyle, getFrameStyle } from '../../utils';
import { FacebookNoBorderSvg } from '../assets/facebook-no-border-svg';
import { ReviewButtonOutput } from '../outputs';
import { ButtonSettings } from '../settings';
import { FacebookReviewButtonTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const FacebookReviewButtonSection: ComposerSection<ButtonSection> = ({
  buttonColor,
  corners,
  frame,
  link,
  textColor,
}) => {
  const { t } = useTranslation('content-composer');
  const { setProps } = useComposerSection<ButtonSection>();

  const fieldProps = useFormField({
    type: 'text',
    value: link?.text,
  });

  useEffect(() => {
    const newLink = { ...(link ? link : { linkType: '', url: '' }), text: fieldProps.value };
    setProps((props) => (props.link = newLink));
  }, [fieldProps.value]);

  return (
    <BaseComposerSection title={t('Facebook Review Button')} canCopy={false} css={getFrameStyle(frame)}>
      <div
        css={[
          getCornersStyle(corners as Corners | undefined),
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(0.75),
            background: buttonColor,
            color: textColor,
            padding: theme.spacing(1, 2),
            fontWeight: theme.font.weight.bold,
            outline: 'none',
            height: theme.spacing(4.5),
            '&:hover, &:focus': {
              boxShadow: theme.shadows.floating,
            },
          },
        ]}
      >
        <FacebookNoBorderSvg fillColor={buttonColor} innerColor={textColor} />
        <ContentEditable
          id={fieldProps.id}
          html={fieldProps.value}
          onChange={fieldProps.onChange}
          css={{
            outline: 'none',
          }}
        />
      </div>
    </BaseComposerSection>
  );
};

FacebookReviewButtonSection.craft = {
  props: {
    buttonColor: theme.colors.primary50.toUpperCase(),
    corners: 'pill',
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    link: {
      linkType: 'facebook',
      text: i18next.t('Write a Facebook Review', { ns: 'content-composer' }),
      url: 'my-facebook-review.com', // TODO: to be replaced after BE integration.
    },
    sectionType: 'FacebookReviewButtonSection',
    textColor: theme.colors.white.toUpperCase(),
  },
  related: {
    output: ReviewButtonOutput,
    settings: ButtonSettings,
    tool: FacebookReviewButtonTool,
  },
};
