import React from 'react';
import { ScheduleCallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, Chip } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { CallRoutesLink } from './call-routes-link';

interface PhoneHoursTraySubHeaderProps {
  scheduleCallRoutes: ScheduleCallRoute[];
  selectedLocationId: string;
}

export const PhoneHoursTraySubHeader: React.FC<PhoneHoursTraySubHeaderProps> = ({
  scheduleCallRoutes,
  selectedLocationId,
}) => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const isUnify = settingsTenantLocation?.locationType === 'unify';
  const { getScopeName } = useAppScopeStore();

  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(9),
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        paddingBottom: theme.spacing(2),
      }}
    >
      <div>
        <Text color='light' size='medium'>
          {t('Call Route')}
        </Text>
        <CallRoutesLink scheduleCallRoutes={scheduleCallRoutes} />
      </div>
      {isUnify && (
        <div>
          <Text color='light' size='medium'>
            {t('Location')}
          </Text>
          <Chip.SingleChip>{getScopeName(selectedLocationId ?? '')}</Chip.SingleChip>
        </div>
      )}
    </div>
  );
};
