import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { DeptPhoneNumberApi, DeptPhoneNumberTypes } from '@frontend/api-department-phone-numbers';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  SwitchField,
  Text,
  phone,
  useFormField,
  Table,
  useModalControl,
  Modal,
  XIcon,
  TextLink,
  Radio,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { WeaveSupportLink } from '../weave-support-link';
import { AssignNumberModal } from './phone-number-section/assign-number-modal';
import { ChangeDefaultNumberModal } from './phone-number-section/change-default-number';
import { ConfirmRemoveLastNumber } from './phone-number-section/confirm-remove-last-number';
import { ConfirmRemoveModal } from './phone-number-section/confirm-remove-modal';

type Props = {
  department: DepartmentsTypes.DepartmentModel;
};

export const PhoneNumberSettings: FC<React.PropsWithChildren<Props>> = ({ department }) => {
  const alert = useAlert();
  const departmentId = department.id ?? '';
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const [deleteNumberID, setDeleteNumberID] = useState<string>();

  const extensionProps = useFormField({ type: 'switch', value: department.extensionEnabled }, [
    department.extensionEnabled,
  ]);

  const { modalProps: assignModalProps, triggerProps: assignTriggerProps } = useModalControl();
  const { modalProps: changeDefaultModalProps, triggerProps: changeDefaultTriggerProps } = useModalControl();
  const { modalProps: confirmRemoveModalProps, triggerProps: confirmRemoveTriggerProps } = useModalControl();
  const { modalProps: confirmRemoveLastNumberModalProps, triggerProps: confirmRemoveLastNumberTriggerProps } =
    useModalControl();

  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const { mutate: updateDepartment } = useMutation(
    ({ payload }: { payload: Partial<DepartmentsTypes.UpdateDepartmentTypes['input']> }) => {
      if (!payload.department?.id) {
        throw new Error(t('No id passed to department PUT request'));
      }
      return DepartmentsApi.updateDepartment(payload);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([locationId, ...queryKeys.department(data.department?.id ?? '')]);
      },
    }
  );

  useEffect(() => {
    const payload = {
      department: {
        ...department,
        extensionEnabled: extensionProps.value,
      },
    };
    if (extensionProps.value !== department.extensionEnabled && department.extensionEnabled !== undefined) {
      updateDepartment({ payload });
    }
  }, [extensionProps.value]);

  const { data: numbers, isLoading: isPhoneNumbersLoading } = useLocalizedQuery({
    queryKey: queryKeys.listDepartmentPhoneNumbers(departmentId),
    queryFn: () => DeptPhoneNumberApi.getDepartmentPhonenumbers({ departmentId }),
    onError: () => {
      alert.error(t('Error in retrieving department phone numbers'));
    },
  });

  const phoneNumbers = numbers?.departmentPhoneNumbers?.[0]?.departmentPhoneNumbers;
  const { mutate: updateDefaultPhoneNumber } = useMutation(
    ({
      departmentId,
      payload: departmentPhoneNumber,
    }: {
      departmentId: string;
      payload: DeptPhoneNumberTypes.DepartmentPhoneNumberType;
    }) => {
      if (!departmentId) {
        throw new Error(t('No id passed to phone-number PUT request'));
      }
      return DeptPhoneNumberApi.update({ departmentId, departmentPhoneNumber });
    },
    {
      onSuccess: () => {
        alert.success(t('Your default phone number has been updated'));
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes('listDepartmentPhoneNumbers');
          },
        });
      },
    }
  );

  const { mutate: updateDefaultsAndRemove } = useMutation(
    (req: DeptPhoneNumberTypes.UpdateAndRemovePhoneNumberTypes['input']) =>
      DeptPhoneNumberApi.updateDefaultsAndRemove(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes('listDepartmentPhoneNumbers');
          },
        });
        alert.success(t('Your number has been removed'));
        alert.success(t('Your other number is set default'));
      },
      onError: () => {
        alert.error(t('Phone number deletion failed'));
      },
    }
  );

  const { mutate: assignMultipleDefaultNumbers } = useMutation(
    (multipleNumbers: DeptPhoneNumberTypes.AssignPhoneNumberTypes['input']) =>
      DeptPhoneNumberApi.assignNumber(multipleNumbers),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes('listDepartmentPhoneNumbers');
          },
        });
        alert.success(t('Your new number has been added'));
      },
      onError: () => {
        alert.error(t('Error in assigning a new number'));
      },
    }
  );

  const { mutate: deletePhoneNumber } = useMutation(
    (payload: DeptPhoneNumberTypes.DeletePayloadModel) => {
      return DeptPhoneNumberApi.removeNumber({
        departmentId: payload.departmentId,
        phoneNumberId: payload.phoneNumberId,
      });
    },
    {
      onSuccess: (_data, payload) => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes('listDepartmentPhoneNumbers');
          },
        });
        alert.success(t(`Phone number {{number}} has been removed`, { number: payload.number }));
      },
      onError: (_error, payload: DeptPhoneNumberTypes.DeletePayloadModel) => {
        alert.error(t(`Failed to remove phone number {{number}}`, { number: payload?.number }));
      },
    }
  );

  const { availablePhoneNumberIds = [], departmentPhoneNumbers = [] } = useMemo(() => {
    const availablePhoneNumberIds: string[] = [];
    const departmentPhoneNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
    phoneNumbers?.forEach((number) => {
      if (number.isAssigned) {
        departmentPhoneNumbers.push(number);
      } else if (
        number.voiceConfig?.provider !== DeptPhoneNumberTypes.EmptyProviderType.VOICE_PROVIDER_UNSPECIFIED ||
        number.smsConfig.provider !== DeptPhoneNumberTypes.EmptyProviderType.SMS_PROVIDER_UNSPECIFIED
      ) {
        availablePhoneNumberIds.push(number.id);
      }
    });

    return {
      availablePhoneNumberIds,
      departmentPhoneNumbers,
    };
  }, [phoneNumbers]);

  const defaultPhoneNumberId = useMemo(
    () => phoneNumbers?.find((phoneNumber) => phoneNumber.voiceConfig?.isDefaultVoice)?.id,
    [phoneNumbers]
  );

  const setDefaultPhoneNumberId = (phoneNumberId: string) => {
    const num = phoneNumbers?.find((num) => num.id === phoneNumberId);
    if (!num) {
      return;
    }
    const payload: DeptPhoneNumberTypes.DepartmentPhoneNumberType = {
      ...num,
      voiceConfig: { ...num.voiceConfig, isDefaultVoice: true },
    };
    updateDefaultPhoneNumber({ departmentId, payload });
  };

  const defaultSMSId = useMemo(
    () => phoneNumbers?.find((phoneNumber) => phoneNumber?.smsConfig?.isDefaultSms)?.id,
    [phoneNumbers]
  );

  const setDefaultSMSId = (phoneNumberId: string) => {
    const num = phoneNumbers?.find((num) => num.id === phoneNumberId);
    if (!num) {
      return;
    }
    const payload: DeptPhoneNumberTypes.DepartmentPhoneNumberType = {
      ...num,
      smsConfig: { ...num.smsConfig, isDefaultSms: true },
    };
    updateDefaultPhoneNumber({ departmentId, payload });
  };

  const onRemovePhoneNumber = (phoneNumberId: string) => {
    setDeleteNumberID(phoneNumberId);
    const isDefaultSMS = defaultSMSId === phoneNumberId;
    const isDefaultVoice = defaultPhoneNumberId === phoneNumberId;
    const isLastDeptNumber = departmentPhoneNumbers.length === 1;
    if (isLastDeptNumber && !!department.mainLine) {
      confirmRemoveTriggerProps.onClick();
    } else if (isLastDeptNumber && !department.mainLine) {
      confirmRemoveLastNumberTriggerProps.onClick();
    } else if (isDefaultVoice || isDefaultSMS) {
      changeDefaultTriggerProps.onClick();
    } else {
      confirmRemoveTriggerProps.onClick();
    }
  };

  const onConfirmRemove = async (phoneNumberId: string) => {
    const phoneNumberData = departmentPhoneNumbers.find(
      (item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => item.id === phoneNumberId
    );
    deletePhoneNumber({ departmentId: department.id, phoneNumberId, number: phoneNumberData?.number });
  };

  const onDefaultUpdate = (
    defaultNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[],
    deleteNumberID: string
  ) => {
    const updatedDefaultNumbers = defaultNumbers.map((number) => ({
      ...number,
      smsConfig: { ...number.smsConfig, isDefaultSms: number.smsConfig.isDefaultSms },
      voiceConfig: { ...number.voiceConfig, isDefaultVoice: number.voiceConfig?.isDefaultVoice ?? false },
    }));

    const defaultsPayload: DeptPhoneNumberTypes.UpdateAndRemovePhoneNumberTypes['input'] = {
      departmentId: departmentId,
      departmentPhoneNumbers: updatedDefaultNumbers,
      removePhoneNumberId: deleteNumberID,
    };

    updateDefaultsAndRemove(defaultsPayload);
  };

  const onAssignLastNumber = (multipleNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[]) => {
    assignMultipleDefaultNumbers({
      departmentId: department.id,
      phoneNumbers: multipleNumbers,
    });
  };

  let TableDescription;
  let tableAction;

  if (!departmentPhoneNumbers.length && !!availablePhoneNumberIds.length) {
    TableDescription = () => (
      <Trans t={t}>
        <Text textAlign='center'>You don't have any numbers assigned to this department yet.</Text>
        <Text textAlign='center'>
          Select{' '}
          <Text weight='bold' as='span'>
            Assign Number
          </Text>{' '}
          to get started.
        </Text>
      </Trans>
    );

    tableAction = {
      label: t('Assign Number'),
      onClick: assignTriggerProps.onClick,
    };
  } else if (!phoneNumbers?.length) {
    TableDescription = () => (
      <Trans t={t}>
        <Text textAlign='center'>You don't have any phone numbers available to use with this department.</Text>
        <Text textAlign='center'>
          Please contact <WeaveSupportLink /> to add one.
        </Text>
      </Trans>
    );
  }

  return (
    <>
      <div
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <SwitchField
          {...extensionProps}
          name='extension-switch'
          css={styles.switchStyle}
          labelPlacement='left'
          label={
            <Text css={styles.switchLabelStyle} weight='bold'>
              {t('Department Extension')}
            </Text>
          }
        />
        {extensionProps.value && (
          <Text
            css={css`
              color: ${theme.colors.neutral50};
            `}
          >
            {t('Ext.')} {department.extension}
          </Text>
        )}
      </div>

      <Text
        css={css`
          font-size: ${theme.fontSize(18)};
          margin-bottom: ${theme.spacing(1)};
        `}
        weight='bold'
      >
        {t('Phone Numbers')}
      </Text>

      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Table
          emptyStateConfig={{
            type: 'sync_your_phone',
            header: t('No Phone Numbers'),
            description: TableDescription,
            action: tableAction,
          }}
          colConfig={[
            {
              Header: t('Phone Number'),
              id: 'phoneNumber',
              accessor: (deptNum: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => phone(deptNum?.number),
            },
            {
              Header: t('Default Voice Outbound'),
              id: 'defaultVoiceOutbound',
              cellAlign: 'center',
              accessor: (deptNum: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => deptNum,
              cellRenderer: (deptNum) => {
                return (
                  <Radio
                    id={deptNum.id}
                    name='defaultVoice'
                    tabIndex={0}
                    checked={deptNum.id === defaultPhoneNumberId}
                    disabled={
                      deptNum?.voiceConfig?.provider ===
                      DeptPhoneNumberTypes.EmptyProviderType.VOICE_PROVIDER_UNSPECIFIED
                    }
                    onChange={() => {
                      setDefaultPhoneNumberId(deptNum.id);
                    }}
                    onBlur={() => {
                      /** */
                    }}
                    onFocus={() => {
                      /** */
                    }}
                    active={false}
                  />
                );
              },
            },
            {
              Header: t('Default SMS Outbound'),
              id: 'defaultSMSOutbound',
              cellAlign: 'center',
              accessor: (deptNum: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => deptNum,
              cellRenderer: (deptNum) => {
                return (
                  <Radio
                    id={deptNum.id}
                    name='defaultSms'
                    tabIndex={0}
                    checked={deptNum.id === defaultSMSId}
                    disabled={
                      deptNum?.smsConfig?.provider === DeptPhoneNumberTypes.EmptyProviderType.SMS_PROVIDER_UNSPECIFIED
                    }
                    onChange={() => {
                      setDefaultSMSId(deptNum.id);
                    }}
                    onBlur={() => {
                      /** */
                    }}
                    onFocus={() => {
                      /** */
                    }}
                    active={false}
                  />
                );
              },
            },
          ]}
          rowActions={{
            actions: [
              {
                Icon: XIcon,
                label: t('remove'),
                onClick: ({ id }) => onRemovePhoneNumber(id),
                trackingId: 'phone-portal-mainLine-phoneNumber-delete',
              },
            ],
          }}
          isLoading={isPhoneNumbersLoading}
          hasResponsiveColWidths
          data={departmentPhoneNumbers}
        />

        {availablePhoneNumberIds.length > 0 && departmentPhoneNumbers.length > 0 && (
          <TextLink size='large' weight='bold' onClick={assignTriggerProps.onClick} css={{ width: 'fit-content' }}>
            {t('Assign Number')}
          </TextLink>
        )}

        {phoneNumbers && phoneNumbers.length > 0 && availablePhoneNumberIds.length === 0 && (
          <div>
            <Trans t={t}>
              <Text
                as='span'
                size='small'
                css={css`
                  margin: ${theme.spacing(2, 0, 0)};
                `}
              >
                All current phone numbers are in use.
              </Text>{' '}
              <Text
                as='span'
                size='small'
                css={css`
                  margin: ${theme.spacing(2, 0, 0)};
                `}
              >
                If you need to add a new number, please contact <WeaveSupportLink />.
              </Text>
            </Trans>
          </div>
        )}
      </div>

      <Modal
        {...assignModalProps}
        css={css`
          min-width: 600px;
          padding: ${theme.spacing(7)};
        `}
      >
        <AssignNumberModal
          availablePhoneNumberIds={availablePhoneNumberIds}
          department={department}
          hasExistingDepartmentNumbers={departmentPhoneNumbers.length > 0}
          isMainline={department.mainLine}
          onClose={() => {
            assignModalProps.onClose();
          }}
          phoneNumbers={phoneNumbers ?? []}
        />
      </Modal>

      <Modal
        {...changeDefaultModalProps}
        maxWidth={600}
        css={css`
          padding: ${theme.spacing(6, 3, 3, 3)};
        `}
      >
        <ChangeDefaultNumberModal
          phoneNumberId={deleteNumberID ?? ''}
          existingDepartmentNumbers={departmentPhoneNumbers}
          isDefaultSMS={defaultSMSId === deleteNumberID}
          isDefaultVoice={defaultPhoneNumberId === deleteNumberID}
          onClose={changeDefaultModalProps.onClose}
          onDefaultsUpdate={onDefaultUpdate}
        />
      </Modal>

      <Modal
        {...confirmRemoveModalProps}
        maxWidth={!!department.mainLine ? 500 : 600}
        css={css`
          padding: ${theme.spacing(6, 2, 3, 2)};
        `}
      >
        <ConfirmRemoveModal
          phoneNumberId={deleteNumberID ?? ''}
          isMainline={department?.mainLine || false}
          existingDepartmentNumbers={departmentPhoneNumbers}
          onConfirm={onConfirmRemove}
          onClose={confirmRemoveModalProps.onClose}
        />
      </Modal>

      <Modal
        {...confirmRemoveLastNumberModalProps}
        maxWidth={600}
        css={css`
          padding: ${theme.spacing(6, 3, 3, 3)};
        `}
      >
        <ConfirmRemoveLastNumber
          phoneNumberId={deleteNumberID ?? ''}
          phoneNumbers={phoneNumbers ?? []}
          department={department}
          onClose={confirmRemoveLastNumberModalProps.onClose}
          onAssign={onAssignLastNumber}
        />
      </Modal>
    </>
  );
};

const styles = {
  CheckIcon: css`
    :hover {
      cursor: no-drop;
    }
  `,

  switchStyle: css`
    align-items: flex-start;
    margin-top: ${theme.spacing(3)};
    width: fit-content;
  `,

  switchLabelStyle: css`
    font-size: ${theme.fontSize(18)};
    margin-bottom: ${theme.spacing(1)};
  `,

  flexCells: css`
    justify-content: center;
    display: flex;
    flex-grow: 1;
  `,
};
