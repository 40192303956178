import { css } from '@emotion/react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Button,
  DropdownField,
  SkeletonLoaders,
  TextField,
  useAlert,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { CreateCallGroupModal } from '../../../call-group/new/create-call-group-modal';
import { AddStepPanelProps, ButtonBar, HeaderBar } from './add-step-panel';

export const CallGroupStep = ({
  settingsTenantLocation,
  initialState,
  callObjectsData,
  onClose,
  onCancelClick,
  onProceedClick,
  onBackClick,
}: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const { modalProps, triggerProps, closeModal } = useModalControl();

  const { callGroups, callGroupsIsLoading, sipProfiles, callForwardingNumbers } = callObjectsData;

  const { getFieldProps, isComplete, values } = useForm({
    fields: {
      callGroupId: {
        required: true,
        type: 'dropdown',
        value: initialState?.callObject?.primitiveId,
      },
      callerLabel: {
        required: false,
        type: 'text',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: initialState?.callObject?.callGroupExpansion?.callerLabel,
      },
    },
  });

  const handleProceedClick = () => {
    const callGroup = callGroups.find((group) => group.id === values.callGroupId);

    if (!callGroup) {
      alerts.error('Unknown call group data');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: callGroup.id,
        primitiveName: callGroup.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        callGroupExpansion: {
          callerLabel: values.callerLabel,
        },
      },
    });
  };

  const handleOnCreateSuccess = (data: CreateResponse) => {
    const newCallGroupId = data.callGroupId;

    getFieldProps('callGroupId').onChange({
      // @ts-expect-error This is complaining thinking that name and value do not exist on the event target,
      // but they do.
      target: { name: 'callGroupId', value: newCallGroupId },
    });
  };

  return (
    <>
      <HeaderBar title={t('Call Group')} onClose={onClose} />

      <SkeletonLoaders.Loader isLoading={callGroupsIsLoading} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Select Call Group')}
          {...getFieldProps('callGroupId')}
          placeholder={t('Select Call Group')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          <DropdownField.Option
            css={css`
              padding: 0;
            `}
            key={'create-call-group'}
            value={''}
            isSelectable={false}
          >
            <Button
              iconName='plus'
              variant='tertiary'
              onClick={() => triggerProps.onClick()}
              css={css`
                width: 100%;
                justify-content: start;
              `}
            >
              {t('Create Call Group')}
            </Button>
          </DropdownField.Option>
          {callGroups.map((group) => (
            <DropdownField.Option key={group.id} value={group.id}>
              {group.name}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </SkeletonLoaders.Loader>

      <SkeletonLoaders.Loader isLoading={callGroupsIsLoading} shape='rectangle' width={320} height={50}>
        <TextField
          label={t('Caller Label (Optional)')}
          {...getFieldProps('callerLabel')}
          helperText={t('Label displays on Weave phone screen while call is ringing')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        />
      </SkeletonLoaders.Loader>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!isComplete}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onCancelClick}
        onBackClick={onBackClick}
      />

      {settingsTenantLocation && (
        <CreateCallGroupModal
          tenantLocation={settingsTenantLocation}
          devices={sipProfiles}
          callForwardingNumbers={callForwardingNumbers}
          show={modalProps.show}
          onClose={closeModal}
          onSuccess={handleOnCreateSuccess}
        />
      )}
    </>
  );
};
