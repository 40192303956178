import { CustomCodeSection as CustomCodeSectionType } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import DOMPurify from 'dompurify';
import { useTranslation } from '@frontend/i18n';
import { ComposerSection } from '../../types';
import { CustomCodeOutput } from '../outputs';
import { CustomCodeSettings } from '../settings';
import { CustomCodeTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const CustomCodeSection: ComposerSection<CustomCodeSectionType> = ({ code }) => {
  const { t } = useTranslation('content-composer');
  const sanitizedCode = DOMPurify.sanitize(code ?? '');

  return (
    <BaseComposerSection title={t('Custom Code')} css={{ padding: 0 }}>
      <div css={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: sanitizedCode }} />
    </BaseComposerSection>
  );
};

CustomCodeSection.craft = {
  props: {
    code: '',
    sectionType: 'CustomCodeSection',
  },
  related: {
    output: CustomCodeOutput,
    settings: CustomCodeSettings,
    tool: CustomCodeTool,
  },
};
