import { css } from '@emotion/react';
import { Node } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { useTranslation, i18next } from '@frontend/i18n';
import { RadioCardField, Text, useAlert, useFormField } from '@frontend/design-system';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const getRepeatPhoneTreeName = (phoneTreeNode: Node) => {
  const name = phoneTreeNode.callObject?.primitiveName;
  if (!name) {
    return '';
  }

  return i18next.t(`Repeats “{{phoneTreeName}}”`, { phoneTreeName: name });
};

export type PhoneTreeAncestors = Record<string, { node: Node; dialOptions: Node[] }>;

type RepeatStepProps = AddStepPanelProps & {
  phoneTreeNodes: PhoneTreeAncestors;
};

export const RepeatStep = ({
  initialState,
  phoneTreeNodes,
  onClose,
  onCancelClick,
  onProceedClick,
  onBackClick,
}: RepeatStepProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const cardFieldProps = useFormField({
    required: true,
    type: 'radio',
    // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
    // and the generated typescript does not know how to handle that correctly.
    value: initialState?.callObject?.repeatExpansion?.phoneTreeNodeId,
  });

  const handleProceedClick = () => {
    const node = phoneTreeNodes[cardFieldProps.value].node;
    if (!node) {
      alerts.error('Unknown phone tree data');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: initialState?.callObject?.primitiveId ?? '',
        primitiveName: getRepeatPhoneTreeName(node),
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        repeatExpansion: {
          phoneTreeNodeId: node.id,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Repeat Phone Tree')} onClose={onClose} />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Text weight='bold'>{t('Select Phone Tree')}</Text>
        <Text size='medium'>{t('Select which previous phone tree will repeat when a caller reaches this step. ')}</Text>
      </div>

      <RadioCardField {...cardFieldProps} name='phoneTree' css={cardFieldStyles}>
        {Object.values(phoneTreeNodes).map(({ node, dialOptions }) => (
          <RadioCardField.Option
            key={node.id}
            css={cardStyles}
            value={node.id}
            title={node.callObject.primitiveName}
            description={t('{{count}} Dial Options', { count: dialOptions.length })}
          />
        ))}
        <span></span>
      </RadioCardField>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!cardFieldProps.value}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onCancelClick}
        onBackClick={onBackClick}
      />
    </>
  );
};

const cardStyles = css`
  flex: unset;
  width: 300px;
`;
const cardFieldStyles = css`
  div:first-child {
    display: flex;
    flex-wrap: wrap;
  }
`;
