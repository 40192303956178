import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import Lottie from 'react-lottie';
import { isChristmasPeriod } from '@frontend/date';
import { ICON_SIZE } from '../constants';
import christmasLogoAnimation from './logo-animations/christmas-logo-morph.json';
import dentalLogoAnimation from './logo-animations/dental-logo-morph.json';
import optoLogoAnimation from './logo-animations/opto-logo-morph.json';
import vetLogoAnimation from './logo-animations/vet-logo-morph.json';
import WeaveLogoDark from './Weave-Logo-Dark.svg';

const logoAnimations = {
  DENTAL: dentalLogoAnimation,
  ORTHODONTIC: dentalLogoAnimation,
  OPTOMETRY: optoLogoAnimation,
  VET: vetLogoAnimation,
  CHRISTMAS: christmasLogoAnimation,
};

export const AnimatedLogo = ({ vertical, onComplete }: { vertical?: Vertical; onComplete: () => void }) => {
  if (isChristmasPeriod) {
    return (
      <Lottie
        height={ICON_SIZE}
        options={{ animationData: logoAnimations['CHRISTMAS'], loop: false, autoplay: false }}
        eventListeners={[{ eventName: 'complete', callback: onComplete }]}
      />
    );
  }
  switch (vertical) {
    case 'DENTAL':
    case 'ORTHODONTIC':
    case 'OPTOMETRY':
    case 'VET':
      return (
        <Lottie
          height={ICON_SIZE}
          options={{
            animationData: logoAnimations[vertical],
            loop: false,
            autoplay: true,
          }}
          eventListeners={[{ eventName: 'complete', callback: onComplete }]}
        />
      );
    default:
      return <img src={WeaveLogoDark} alt='weave-app-logo' css={{ height: ICON_SIZE }} data-tour-guide='portal-logo' />;
  }
};
