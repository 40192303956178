import { useEffect } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import {
  CollectPaymentModalBody,
  CollectPaymentModalSteps,
  useCollectPaymentModal,
} from '@frontend/payments-collection-flow';
import {
  CreateInvoiceModalContents,
  useCreateInvoiceModalContentsProps,
} from '@frontend/payments-create-invoice-modal';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { MultiStepModal, PaymentsMultiStepModal } from '@frontend/payments-multistep-modal';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ModalControlModalProps } from '@frontend/design-system';

export { useCreateInvoiceModalContentsProps };

type PaymentModalProps = ModalControlModalProps;

export const CREATE_INVOICE_STEP = 'create-invoice';

/**
 * @deprecated Use CollectPaymentMultiModalInstance or one of it's variants, which support multiple processors.
 */
export const CreateAndCollectModal = ({ ...rest }: PaymentModalProps) => {
  const { t } = useTranslation('payments');
  const { selectedLocationIds } = useAppScopeStore();
  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: selectedLocationIds,
  });

  return (
    <PaymentsMultiStepModal
      modalProps={rest}
      closeModal={rest.onClose}
      initialStep={CREATE_INVOICE_STEP}
      css={{ maxHeight: 750 }}
      maxWidth={parseInt(theme.spacing(82))}
      data-trackingid='create-and-collect-modal'
      stepperWidth={parseFloat(theme.spacing(20))}
    >
      <MultiStepModal.Step
        id={CREATE_INVOICE_STEP}
        title={newRequestUI ? t('Creating Payment Request') : t('Create Invoice')}
        blueHeader={newRequestUI}
      >
        <CreateInvoiceForCollection />
      </MultiStepModal.Step>
      <CollectPaymentModalBody />
    </PaymentsMultiStepModal>
  );
};

type CreateInvoiceForCollectionProps = {
  person?: Person;
  onClose?: () => void;
  onInvoiceCreated?: (invoice: InvoiceModel) => void;
  hideHeader?: boolean;
};

export const CreateInvoiceForCollection = ({
  person,
  onClose,
  onInvoiceCreated,
  hideHeader = true,
}: CreateInvoiceForCollectionProps) => {
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();

  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const createInvoiceProps = useCreateInvoiceModalContentsProps({
    selectedPerson: person,
    onSelectInvoice: (invoice: InvoiceModel) => setSelectedInvoiceId(invoice.id),
    onClose,
  });

  const handleOnCreate = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList);
    onInvoiceCreated?.(invoice);
  };

  useEffect(() => {
    setSelectedInvoiceId(null);
  }, []);

  return <CreateInvoiceModalContents {...createInvoiceProps} onCreated={handleOnCreate} hideHeader={hideHeader} />;
};
