import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel, PaymentOrigin, sendInvoiceReceipt } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import {
  ITerminalStrategyError,
  PaymentsTerminalController,
  ReaderConnectErrors,
  RESTRICTED_TERMINAL_STATES,
  TerminalAppData,
  useTerminalPaymentSession,
} from '@frontend/payments-terminal-controller';
import { shell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, TextLink, useAlert, useFormField } from '@frontend/design-system';
import { useInvoicePerson } from '../../../../hooks';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useCreatePaymentIntent } from '../../../hooks/use-create-payment-intent';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { sortReaders } from '../../../utils/readers';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { useDiscoverReaderQuery } from '../../terminals/stripe/hooks/use-discover-readers-query';
import { useTerminalStepContextStripe } from '../../terminals/terminal-step-context/terminal-step-context-stripe';
import { CollectTerminalNavigationSwitch } from './collect-terminal-navigation-switch';
import { CollectReaderPaymentDisplay } from './collect-terminal-stripe/collect-reader-payment-display';
import { ReaderStatusAdapter } from './collect-terminal-stripe/reader-status-adapter';
import { CollectTerminalStepBodyProps } from './collect-terminal.types';

const styles = {
  buttonBar: css`
    display: flex;
    justify-content: end;
    gap: ${theme.spacing(2)};
  `,
  receiptButtonBar: css`
    display: flex;
    justify-content: space-between;
  `,
};

export const CollectTerminalStripe = () => {
  return <CollectTerminalNavigationSwitch body={CollectTerminalStripeBody} />;
};
const CollectTerminalStripeBody = ({
  onGoBack,
  onCloseWithoutReceipt,
  onInvoiceSent,
  onPaymentSuccess,
}: CollectTerminalStepBodyProps) => {
  const { t } = useTranslation('payments');
  const [sendingReceipt, setSendingReceipt] = useState(false);

  const {
    activeLocationIds,
    invoice,
    invoiceQuery: { refetch: refetchInvoice },
    paymentsUrl,
    stripeData: { stripeLocationId },
  } = useCollectPaymentMultiContext();

  const locationId = invoice?.locationId || activeLocationIds[0];
  const alert = useAlert();

  // PAYMENT INTENT
  const { person } = useInvoicePerson(invoice);

  /**
   * Should only use "offSession" when we have a "save for later"
   * checkbox and corresponding address fields, etc.
   */
  const setupFutureUsage: SetupFutureUsage | null = null;

  const onRefetchInvoice = useCallback(() => {
    return refetchInvoice().then((res) => res.data);
  }, [refetchInvoice]);

  const {
    paymentIntent,
    error: createIntentError,
    retryCreatePaymentIntent,
  } = useCreateTerminalPaymentIntent({ person, invoice, setupFutureUsage, onGoBack, onRefetchInvoice });

  // TERMINAL

  const discoverReaderQuery = useDiscoverReaderQuery({
    locationId,
    stripeLocationId,
    paymentsUrl: paymentsUrl ?? undefined,
  });

  const { selectedReader, setSelectedReader } = useTerminalStepContextStripe();

  const appData: TerminalAppData = useMemo(
    () =>
      shell.isShell
        ? {
            type: 'shell',
            version: shell.version,
          }
        : {
            type: 'nwx',
            version: undefined,
          },
    [shell]
  );

  const discoverReaders = useCallback(
    () => discoverReaderQuery.refetch().then((res) => res.data ?? []),
    [discoverReaderQuery]
  );

  const sortedReaders = useMemo(() => sortReaders(discoverReaderQuery.data ?? []), [discoverReaderQuery.data]);

  const {
    error: readerError,
    status: readerStatus,
    handleCancel,
    handleTryAgain,
    paymentSuccessful,
  } = useTerminalPaymentSession({
    onPaymentSuccess: () => {
      refetchInvoice();
      onPaymentSuccess();
    },
    reader: selectedReader,
    paymentsUrl: paymentsUrl ?? undefined,
    locationId: locationId ?? '',
    appData,
    enableServerDriven: true,
    goBack: onGoBack,
    paymentIntent: paymentIntent?.paymentIntent,
    paymentId: paymentIntent?.paymentId,
    invoiceId: invoice?.id,
    retryCreatePaymentIntent,
    discoverReaders,
  });

  const _handleEndActivePayment = () => {
    handleTryAgain({ selectedReader, failIfReaderInUse: false });
  };

  // TODO: implement terminal is in use
  const error = createIntentError ?? readerError;
  const isTerminalInUse = !!(error?.message === ReaderConnectErrors.alreadyInUse);

  const { personEmail } = useInvoicePerson(invoice);

  const emailReceiptFieldProps = useFormField({
    type: 'email',
    value: personEmail,
  });

  const handleSendReceipt = async () => {
    setSendingReceipt(true);
    try {
      await sendInvoiceReceipt(paymentsUrl as string, invoice?.id as string, [emailReceiptFieldProps.value]);
      alert.success(t('Receipt sent successfully'));
    } catch (err) {
      console.error('Error sending receipt', err);

      if (err instanceof HttpError) {
        alert.error(err.message);
      }
    }
    setSendingReceipt(false);
    onInvoiceSent();
  };

  const {
    changePaymentTrackingId,
    tryAgainTrackingId,
    cancelTrackingId,
    sendReceiptTrackingId,
    closeWithoutReceiptTrackingId,
  } = useScopedTrackingIds({
    changePaymentTrackingId: `${PAYMENT_MULTI_STEPS.collectTerminal}--change-payment-btn`,
    tryAgainTrackingId: `${PAYMENT_MULTI_STEPS.collectTerminal}--try-again-btn`,
    cancelTrackingId: `${PAYMENT_MULTI_STEPS.collectTerminal}--cancel-btn`,
    sendReceiptTrackingId: `${PAYMENT_MULTI_STEPS.collectTerminal}--send-receipt-btn`,
    closeWithoutReceiptTrackingId: `${PAYMENT_MULTI_STEPS.collectTerminal}--close-without-receipt-btn`,
  });

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.collectTerminal}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.collectTerminal]}
      components={{
        Footer: () => (
          <div className='step__footer'>
            {!isTerminalInUse &&
              (!paymentSuccessful ? (
                <div css={styles.buttonBar}>
                  {readerError ? (
                    <>
                      <Button trackingId={changePaymentTrackingId} variant='secondary' size='large' onClick={onGoBack}>
                        {t('Change Payment Method')}
                      </Button>
                      <Button trackingId={tryAgainTrackingId} size='large' onClick={() => handleTryAgain()}>
                        {t('Try Again')}
                      </Button>
                    </>
                  ) : (
                    <Button trackingId={cancelTrackingId} variant='secondary' size='large' onClick={handleCancel}>
                      {t('Cancel')}
                    </Button>
                  )}
                </div>
              ) : (
                <div css={styles.receiptButtonBar}>
                  <TextLink trackingId={closeWithoutReceiptTrackingId} onClick={onCloseWithoutReceipt}>
                    {t('Close without receipt')}
                  </TextLink>
                  <Button
                    trackingId={sendReceiptTrackingId}
                    size='large'
                    disabled={!emailReceiptFieldProps.value || sendingReceipt}
                    onClick={handleSendReceipt}
                  >
                    {t('Send Receipt')}
                  </Button>
                </div>
              ))}
          </div>
        ),
      }}
    >
      <ContentLoader show={sendingReceipt} message={t('Sending receipt...')} />
      <CollectReaderPaymentDisplay
        selectedReader={selectedReader}
        isTerminalInUse={isTerminalInUse}
        paymentSuccessful={paymentSuccessful}
        emailReceiptFieldProps={emailReceiptFieldProps}
        ReaderStatusOverrideComponent={
          <ReaderStatusAdapter
            invoice={invoice}
            status={readerStatus}
            error={error}
            isTerminalInUse={isTerminalInUse}
            handleEndActivePayment={_handleEndActivePayment}
          />
        }
        availableReaders={sortedReaders}
        onSelectedReaderChange={(terminal) => {
          if (!paymentsUrl || !locationId) return; // this should never happen, we only show readers if there is a payments url
          return setSelectedReader(PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, terminal));
        }}
        disableTerminalSelection={RESTRICTED_TERMINAL_STATES.includes(readerStatus)}
      />
    </StyledStep>
  );
};
type UseCreatePaymentIntentProps = {
  person: ReturnType<typeof useInvoicePerson>['person'];
  invoice: InvoiceModel | undefined;
  setupFutureUsage?: SetupFutureUsage | null;
  onGoBack?: () => void;
  onRefetchInvoice: () => Promise<InvoiceModel | undefined>;
};

const useCreateTerminalPaymentIntent = ({
  person,
  invoice,
  setupFutureUsage = SetupFutureUsage.offSession,
  onGoBack,
  onRefetchInvoice,
}: UseCreatePaymentIntentProps) => {
  const [paymentIntent, setPaymentIntent] = useState<Awaited<ReturnType<typeof createPaymentIntent>>>();
  const [error, setError] = useState<ITerminalStrategyError>();

  const personEmail = person ? PersonsV3.PersonHelpers.getEmailContactType(person) : '';
  const { createPaymentIntent } = useCreatePaymentIntent({
    amount: invoice?.billedAmount ?? 0,
    email: personEmail,
    personId: person?.personId,
    setupFutureUsage: setupFutureUsage || undefined,
    locationId: invoice?.locationId,
    origin: PaymentOrigin.Terminal,
    invoiceId: invoice?.id,
    onGoBack,
    onRefetchInvoice,
  });

  const createOnce = useRef(false);

  const tryCreatePaymentIntent = useCallback(async () => {
    let intent: typeof paymentIntent | undefined;
    let paymentIntentError: ITerminalStrategyError | undefined;
    if (!createOnce.current) {
      createOnce.current = true;
      try {
        intent = await createPaymentIntent();
        setPaymentIntent(intent);
      } catch (error) {
        if (error instanceof HttpError || error instanceof Error) {
          paymentIntentError = { action: 'initialize', message: error.message };
          setError(paymentIntentError);
        }
      }
    }
    return { intent, paymentIntentError };
  }, [createPaymentIntent]);

  useEffect(() => {
    tryCreatePaymentIntent();
  }, [createPaymentIntent, paymentIntent?.clientSecret]);

  const retryCreatePaymentIntent = async () => {
    createOnce.current = false;
    const { intent, paymentIntentError } = await tryCreatePaymentIntent();
    if (paymentIntentError || !intent?.paymentIntent || !intent.paymentId)
      throw paymentIntentError ?? new Error('Failed to create payment intent');
    return { paymentIntent: intent.paymentIntent, paymentId: intent.paymentId };
  };

  return { paymentIntent, error, retryCreatePaymentIntent };
};
