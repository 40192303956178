import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { URLs } from '../../../constants';
import { featureFlags } from '../../../feature-flags';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { useFetchARROIOOverview } from '../appointment-reminders/hooks';
import { WidgetCardProps } from './types';
import { WidgetCard } from './widget-card';

// This is a temporary placeholder for the chart and will be removed once one more ROI feature is released
// Then widget design will change and trend lines will be removed.
// TODO :: Delete after GA of bulk texting roi
const breakValueIntoParts = (value: number): number[] => {
  const parts = 10;

  // Create an array to hold the parts
  const result = Array(parts).fill(0);

  if (!value) {
    return result;
  }

  // Distribute the total value randomly among the parts
  let remainingValue = value;

  // Randomly assign values to the parts, ensuring the sum equals the original value
  for (let i = 0; i < parts - 1; i++) {
    // Randomly choose a part value, but don't exceed remaining value
    const partValue = Math.floor(Math.random() * (remainingValue / (parts - i)) + 1);
    result[i] = partValue;
    remainingValue -= partValue;
  }

  // Assign the remaining value to the last part to ensure the sum is correct
  result[parts - 1] = remainingValue;

  return result;
};

export const ARWidgetCard = ({ handleUpdateVisibleCards, showTrendLine, timePeriod }: WidgetCardProps) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);

  const { aggregated, isLoading } = useFetchARROIOOverview({
    apiParams: {
      payload: {
        start_date: timePeriod.value.startDate,
        end_date: timePeriod.value.endDate,
        location_id: selectedLocationIds,
      },
    },
    fetchNoShowRate: false,
    isEnabled: !isDemoAccount,
  });

  const appointmentsConfirmed = aggregated?.appointmentsConfirmed?.appointmentsCount || 0;

  const trendLine = useMemo(() => {
    return showTrendLine ? breakValueIntoParts(aggregated?.appointmentsConfirmed?.appointmentsCount || 0) : [];
  }, [aggregated?.appointmentsConfirmed?.appointmentsCount, showTrendLine]);

  const handleNavigate = useCallback(() => {
    navigate({
      to: URLs.ROI_APPOINTMENT_REMINDERS,
    });
  }, []);

  useEffect(() => {
    handleUpdateVisibleCards('appointmentReminders', !!appointmentsConfirmed);
  }, [appointmentsConfirmed]);

  if (!appointmentsConfirmed) {
    return null;
  }

  return (
    <WidgetCard
      backgroundConfig={{
        // Colors are not available in the theme
        gradientStart: '#F07006',
        gradientEnd: '#FDB771',
      }}
      iconName='calendar-small'
      isLoading={isLoading}
      onClick={handleNavigate}
      subTitle={t('Appts Confirmed')}
      title={t('Appointment Reminders')}
      trackingId={trackingIds.roi.appointmentRemindersRoiWidget}
      trend={showTrendLine && trendLine}
      value={formatters.value.format(appointmentsConfirmed)}
    />
  );
};
