import { CallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { DeviceInclude_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { CallGroupApi } from '@frontend/api-call-group';
import CallQueueAPI from '@frontend/api-call-queue';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { DevicesQueries } from '@frontend/api-devices';
import { ForwardingNumberApi } from '@frontend/api-forwarding-number';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { PhoneNumbersV1 } from '@frontend/api-phone-numbers';
import SipProfileAPI from '@frontend/api-sip-profile';
import { useQuery } from '@frontend/react-query-helpers';
import { WeaveLocationGroup } from '@frontend/scope';
import { queryKeys } from '../../query-keys';
import { useTenantLocationIds } from '../../utils/use-tenant-location-ids';
import useVoicemailBoxes from '../voicemail-box/new/use-voicemail-boxes';

export const useCallObjectsData = (settingsTenantLocation?: WeaveLocationGroup) => {
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const tenantLocationIds = useTenantLocationIds();

  const { data: callGroups = [], isLoading: callGroupsIsLoading } = useQuery({
    queryKey: queryKeys.listCallGroups(tenantId),
    queryFn: () => CallGroupApi.listCallGroups({ tenantId: tenantId }),
    enabled: !!tenantId,
    select: (data) =>
      (data.callGroups ?? []).sort((a, b) => a.name.localeCompare(b.name)).filter(({ name }) => !name.includes('e911')),
    staleTime: 0,
    retry: false,
  });

  // Fetch devices with addresses
  const { data: sipProfiles = [], isLoading: sipProfilesIslLoading } = useQuery({
    queryKey: queryKeys.devicesWithAddresses(),
    queryFn: () => SipProfileAPI.List({ tenantId: tenantId }).then((res) => res.sipProfiles),
    select: (data) =>
      data?.sort((a, b) => {
        const aName = a.device?.deviceName || '';
        const bName = b.device?.deviceName || '';
        return aName.localeCompare(bName);
      }),
    retry: false,
    enabled: !!tenantId,
  });

  const { data: callForwardingNumbers = [], isLoading: callForwardingNumbersIsLoading } = useQuery({
    queryKey: queryKeys.forwardingNumbers(),
    queryFn: () => ForwardingNumberApi.list({ locationId: settingsTenantLocation?.id }),
    select: (data) =>
      data.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }),
    retry: false,
    enabled: !!settingsTenantLocation?.id,
  });

  const { data: callQueues = [], isLoading: callQueuesIsLoading } = useQuery({
    queryKey: [tenantId, ...queryKeys.settings.listCallQueue()],
    queryFn: () => CallQueueAPI.List({ tenantId: tenantId ?? '' }).then((res) => res.callQueues),
    select: (data) => {
      return (data ?? []).sort((a, b) => a.name.localeCompare(b.name));
    },
    enabled: !!tenantId,
    staleTime: 0,
    retry: false,
  });

  const { data: callRoutes = [], isLoading: callRoutesIsLoading } = CallRouteV1.Queries.useListQuery({
    request: { tenantId: settingsTenantLocation?.phoneTenantId ?? '' },
    options: {
      enabled: !!settingsTenantLocation?.phoneTenantId,
      select: ({ callRoutes }) =>
        // Sort the extension numbers so that they are displayed in ascending order.
        callRoutes.reduce<CallRoute[]>((acc, callroute) => {
          return [
            ...acc,
            {
              ...callroute,
              extensionNumbers: callroute.extensionNumbers.sort(),
            },
          ];
        }, []),
      cacheTime: 30 * 1000,
      staleTime: 0,
      retry: false,
    },
  });

  const { data: phoneOverrides = [], isLoading: phoneOverridesIsLoading } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.listOverrides()],
    queryFn: async () => PhoneOverrideApi.List({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }),
    select: (data) => data?.phoneOverrides ?? [],
    enabled: !!settingsTenantLocation?.phoneTenantId,
    retry: false,
  });

  const { data: allPhoneNumbers, isLoading: allPhoneNumbersIsLoading } =
    PhoneNumbersV1.Queries.useListPhoneNumbersForLocationsQuery({
      request: { locationIds: tenantLocationIds },
      options: { enabled: !!tenantLocationIds.length, select: (data) => data?.phoneNumbers ?? [], retry: false },
    });

  const { data: devices = [], isLoading: devicesIsLoading } = DevicesQueries.useGetDevicesList(
    {
      include: [DeviceInclude_Enum.CALL_GROUPS, DeviceInclude_Enum.E911_ADDRESS, DeviceInclude_Enum.REGISTRATION],
      locationIds: tenantLocationIds,
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: (data) => (data?.devices ?? []).sort((a, b) => a.name.localeCompare(b.name)),
      enabled: !!settingsTenantLocation?.phoneTenantId,
      retry: false,
      staleTime: 0,
    }
  );

  const { data: forwardingNumbers = [], isLoading: forwardingNumbersIsLoading } =
    PhoneNumbersV1.Queries.useListForwardingNumbersQuery({
      request: { tenantId },
      options: {
        enabled: !!tenantId,
        select: (data) =>
          data.forwardingNumbers?.sort((a, b) => {
            const nameA = a.name?.toLowerCase() ?? '';
            const nameB = b.name?.toLowerCase() ?? '';

            return nameA.localeCompare(nameB);
          }) ?? [],
        retry: false,
        staleTime: 0,
      },
    });

  const { data: voicemailBoxes, isLoading: voicemailBoxesIsLoading } = useVoicemailBoxes({
    settingsTenantLocation,
    options: {
      retry: false,
      staleTime: 0,
    },
  });

  return {
    callGroups,
    callGroupsIsLoading,
    callForwardingNumbers,
    callForwardingNumbersIsLoading,
    callQueues,
    callQueuesIsLoading,
    callRoutes,
    callRoutesIsLoading,
    devices,
    devicesIsLoading,
    forwardingNumbers,
    forwardingNumbersIsLoading,
    phoneOverrides,
    phoneOverridesIsLoading,
    sipProfiles,
    sipProfilesIslLoading,
    voicemailBoxes,
    voicemailBoxesIsLoading,
    allPhoneNumbers,
    allPhoneNumbersIsLoading,
    someIsLoading:
      callGroupsIsLoading ||
      callForwardingNumbersIsLoading ||
      callQueuesIsLoading ||
      callRoutesIsLoading ||
      devicesIsLoading ||
      forwardingNumbersIsLoading ||
      phoneOverridesIsLoading ||
      sipProfilesIslLoading ||
      voicemailBoxesIsLoading ||
      allPhoneNumbersIsLoading,
  };
};
