import { useState } from 'react';
import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { paymentsSentry } from '@frontend/payments-hooks';
import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Text,
  TextField,
  ValidatorFieldState,
  useForm,
  useAlert,
  BannerNotification,
} from '@frontend/design-system';
import { RegisterTerminalModalSteps } from './hooks';
import {
  RegisterTerminalModalStepProps,
  RegisterTerminalStepModalWrapper,
  RegisterTerminalStep,
} from './register-terminal-modal-step';

const styles = {
  list: css`
    list-style-type: number;
    padding-left: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    & > li:not(:first-of-type) {
      margin-top: ${theme.spacing(2)};
    }
    & > li:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  registrationForm: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin: ${theme.spacing(2, 0)};
  `,
  pairingCodeContainer: css`
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
};

export const GeneratePairingCode = (props: RegisterTerminalModalStepProps) => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalStepModalWrapper
      title={t('Generate a pairing code on your terminal')}
      nextStep={RegisterTerminalModalSteps.PairYourTerminal}
      Action={RegisterTerminalStep.Next}
      {...props}
    >
      <ol css={styles.list}>
        <li>{t('Select Language')}</li>
        <li>{t('Select Country')}</li>
        <li>{t('Wait for 10 seconds to allow it to update')}</li>
        <li>{t('A 3-word code pops up on the terminal. Enter this on the next screen.')}</li>
      </ol>
    </RegisterTerminalStepModalWrapper>
  );
};

export type UsePairYourTerminalProps = {
  onTerminalPaired: () => void;
  paymentsUrl: string | undefined;
  locationId: string | undefined;
  stripeLocationId: string | undefined;
};
export const usePairYourTerminnal = ({
  onTerminalPaired,
  paymentsUrl,
  locationId,
  stripeLocationId,
}: UsePairYourTerminalProps) => {
  const { t } = useTranslation('payments');

  const alerts = useAlert();

  const [showStatus, setShowStatus] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const paringFieldValidator = (field: ValidatorFieldState<'text'>) => {
    if (!/[a-zA-Z0-9]+$/g.test(field.value)) {
      return t('Only letters, or numbers are allowed.');
    } else {
      // clear errors, all good
      return '';
    }
  };

  const { values, getFieldProps, isComplete } = useForm({
    fields: {
      pairingCode1: {
        type: 'text',
        required: true,
        validator: paringFieldValidator,
      },
      pairingCode2: {
        type: 'text',
        required: true,
        validator: paringFieldValidator,
      },
      pairingCode3: {
        type: 'text',
        required: true,
        validator: paringFieldValidator,
      },
      terminalName: {
        type: 'text',
        required: true,
        validator: (field: ValidatorFieldState<'text'>) => {
          if (!/[a-zA-Z0-9- _.]+$/g.test(field.value)) {
            return 'Only letters, numbers, spaces, "-", "_" are allowed.';
          } else {
            return '';
          }
        },
      },
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (code: string) => {
      if (!paymentsUrl || !code || !values.terminalName || !locationId || !stripeLocationId) {
        const missingFields = [];
        if (!paymentsUrl) missingFields.push('paymentsUrl');
        if (!code) missingFields.push('pairingCode');
        if (!values.terminalName) missingFields.push('terminalName');
        if (!locationId) missingFields.push('locationId');
        if (!stripeLocationId) missingFields.push('stripeLocationId');

        throw new Error(`Missing required fields for terminal registration: ${missingFields.join(', ')}`);
      }
      return PaymentsTerminalController.registerTerminal({
        paymentsUrl,
        locationId,
        code,
        name: values.terminalName,
        stripeLocationId,
      });
    },
    onError: (error: HttpError) => {
      alerts.error(t("Couldn't register terminal"));
      console.error('Trouble registering terminal', error);
      setError(error?.message || t("Couldn't register terminal"));
      setShowStatus(true);
      if (error) paymentsSentry.error(error.message ?? '', 'terminal-request', 'Trouble registering terminal');
    },
    onSuccess: () => {
      alerts.success(t('Terminal registered successfully'));
      onTerminalPaired();
      setShowStatus(true);
    },
  });

  const handleRegisterTerminal = () => {
    if (!(paymentsUrl && stripeLocationId)) {
      console.warn('Failed to initiate terminal registration. Missing context');
    } else if (values && values.pairingCode1 && values.pairingCode2 && values.pairingCode3 && values.terminalName) {
      const code = [values.pairingCode1, values.pairingCode2, values.pairingCode3].join('-');
      mutate(code);
    }
  };

  const resetStatus = () => {
    setShowStatus(false);
    setError(null);
  };

  return { getFieldProps, isComplete, isLoading, handleRegisterTerminal, showStatus, error, resetStatus };
};

export type PairYourTerminalProps = RegisterTerminalModalStepProps<{
  onTerminalPaired: () => void;
  onSuccess: () => void;
  paymentsUrl: string | undefined;
  locationId: string | undefined;
  stripeLocationId: string | undefined;
}>;

export const PairYourTerminal = ({
  onTerminalPaired,
  onSuccess,
  paymentsUrl,
  locationId,
  stripeLocationId,
  ...props
}: PairYourTerminalProps) => {
  const { t } = useTranslation('payments');

  const { handleRegisterTerminal, isComplete, isLoading, getFieldProps, showStatus, error, resetStatus } =
    usePairYourTerminnal({
      onTerminalPaired,
      paymentsUrl,
      locationId,
      stripeLocationId,
    });

  const action = showStatus
    ? error
      ? RegisterTerminalStep.TryAgain
      : RegisterTerminalStep.Complete
    : RegisterTerminalStep.Register;
  const actionHandler = showStatus ? (error ? resetStatus : onSuccess) : handleRegisterTerminal;

  return (
    <RegisterTerminalStepModalWrapper
      title={t('Pair your terminal to Weave')}
      onClickAdvance={actionHandler}
      isAdvanceDisabled={!showStatus && (!isComplete || isLoading)}
      Action={action}
      backLabel={showStatus && !error ? t('Register Another Terminal') : undefined}
      {...props}
      goBack={showStatus && !error ? resetStatus : props.goBack}
    >
      <ContentLoader show={isLoading} message={t('Registering terminal')} />
      {!showStatus ? (
        <RegistrationForm
          getFieldProps={getFieldProps}
          isComplete={isComplete}
          isLoading={isLoading}
          handleRegisterTerminal={handleRegisterTerminal}
        />
      ) : (
        <StatusMessage error={error} />
      )}
    </RegisterTerminalStepModalWrapper>
  );
};

type PairingCodeFieldsProps = {
  getFieldProps: any;
};
const PairingCodeFields = ({ getFieldProps }: PairingCodeFieldsProps) => {
  const { t } = useTranslation('payments');
  return (
    <div css={styles.pairingCodeContainer}>
      <TextField label={t('Pairing Code')} {...getFieldProps('pairingCode1')} />
      <div>-</div>
      <TextField label={t('Pairing Code')} {...getFieldProps('pairingCode2')} />
      <div>-</div>
      <TextField label={t('Pairing Code')} {...getFieldProps('pairingCode3')} />
    </div>
  );
};

type RegistrationFormProps = {
  getFieldProps: any;
  isComplete: boolean;
  isLoading: boolean;
  handleRegisterTerminal: () => void;
};
const RegistrationForm = ({ getFieldProps }: RegistrationFormProps) => {
  const { t } = useTranslation('payments');
  return (
    <div css={styles.registrationForm}>
      <Text>{t('Fill in the following information to complete the terminal registration')}</Text>
      <TextField label={t('Custom terminal name')} {...getFieldProps('terminalName')} />
      <PairingCodeFields getFieldProps={getFieldProps} />
    </div>
  );
};

const StatusMessage = ({ error }: { error: string | null }) => {
  const { t } = useTranslation('payments');
  return (
    <div css={styles.registrationForm}>
      <BannerNotification
        css={css`
          margin-top: ${theme.spacing(2)};
          max-width: fit-content;
        `}
        status={error ? 'error' : 'success'}
        message={error ? t('There was a problem registering your terminal') : t('Terminal connected!')}
      />
      {!error ? <Text weight='bold'>{t('You are now ready to collect payments!')}</Text> : <Text>{error}</Text>}
    </div>
  );
};
