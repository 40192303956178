export const APIFetchNoAuth = <T extends Record<string, any>>(
  backend: string
): ((url: string, reqInit: RequestInit) => Promise<T>) => {
  return async (url: string, reqInit: RequestInit) => {
    const res = await fetch(`${backend}${url}`, reqInit);
    if (res.status !== 200) {
      let errorMessage;
      try {
        const errorData = await res.json();
        // Check for common error fields in JSON, fall back to stringifying the whole object
        errorMessage = errorData.error || errorData.message || JSON.stringify(errorData);
      } catch (e) {
        // If JSON parsing fails, read as plain text
        errorMessage = await res.text();
      }
      throw new Error(`HTTP error ${res.status}: ${errorMessage}`);
    }
    const json = await res.json();
    return json as T;
  };
};
