import { useEffect, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useContentComposer } from '../content-composer-provider';
import { LogoSection } from '../sections';
import { Divider, ToolboxButton } from '../sidebar';

export const LogoTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection, composerMode } = useContentComposer();
  const isEmailComposer = composerMode !== 'feedback';
  const [includesLogo, setIncludesLogo] = useState<boolean>(false);
  const [includesFooter, setIncludesFooter] = useState<boolean>(false);

  const { nodes } = useEditor((state) => ({
    nodes: state.nodes,
  }));

  const selectedNodes = Object.values(nodes)
    .map((node) => node.data.name)
    .sort((a, b) => {
      if (a === b) return 0;
      return a < b ? -1 : 1;
    });

  useEffect(() => {
    setIncludesLogo(selectedNodes.includes('LogoSection'));
    setIncludesFooter(selectedNodes.includes('FooterSection'));
  }, [selectedNodes]);

  return includesLogo ? null : (
    <>
      <ToolboxButton
        icon={isEmailComposer ? 'star' : 'header'}
        title={t('Logo')}
        subtitle={t(isEmailComposer ? 'Add a branded logo to the top of your campaign.' : 'Add a branded logo.')}
        onClick={() => insertSection(<LogoSection />, 0)}
        trackingId={`${BulkEmailPrefixes.Composer}-logo-tool-btn`}
      >
        <LogoSection />
      </ToolboxButton>
      {(isEmailComposer || includesFooter) && <Divider />}
    </>
  );
};
