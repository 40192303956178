import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { AreaChartData } from '@frontend/charts';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../../feature-flags';
import { useFetchRoiData } from '../../hooks';
import { RoiApiPayload } from '../../types';
import { appointmentsScheduleRate } from '../demo-data';

type APIParams = {
  payload: RoiApiPayload;
};

interface Props {
  apiParams: APIParams;
  isEnabled?: boolean;
}

type ScheduleRate = {
  key: string;
  rate?: number;
  weave_average?: number;
};

export type ScheduleRateResponse = {
  dates?: ScheduleRate[];
  rate_difference?: number;
  time_period?: string;
  weave_average?: number;
};

type HighestConversion = {
  difference: number;
  timePeriod: string;
  timeType: string;
};

interface UseFetchBTRoiScheduleRate {
  isLoading?: boolean;
  scheduleRate: {
    chartDataGroups: AreaChartData['groups'];
    dates: Record<string, number>;
    difference: number;
    highestConversion: HighestConversion;
    showWeaveAverageGraph?: boolean;
    weaveAverage: number;
  };
}

const api = async ({ payload }: APIParams) => {
  const response = await http.post<{ data: ScheduleRateResponse }>(
    `${BASE_URL_ANALYTICS}/roi/v4/bulkmessaging/getscheduleconversion`,
    payload
  );

  return response.data;
};

export const useFetchBTRoiScheduleRate = ({ isEnabled = true, ...rest }: Props): UseFetchBTRoiScheduleRate => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.bulkTextRoiDemoData);

  const { data: scheduleRateData, isLoading } = useFetchRoiData<APIParams, ScheduleRateResponse>({
    ...rest,
    api,
    errorMessage: t("Couldn't fetch the appointment schedule rate details. Please try again."),
    isEnabled: isEnabled && !isDemoAccount,
    uniqueId: 'bt-roi-overview',
  });

  const processedData = useMemo(() => {
    const scheduleRate: UseFetchBTRoiScheduleRate['scheduleRate'] = {
      chartDataGroups: [],
      dates: {},
      difference: 0,
      highestConversion: {
        difference: 0,
        timePeriod: '',
        timeType: '',
      },
      showWeaveAverageGraph: false,
      weaveAverage: 0,
    };

    const dataToUse = isDemoAccount ? appointmentsScheduleRate() : scheduleRateData;

    if (!dataToUse) {
      return {
        scheduleRate,
      };
    }

    scheduleRate.difference = (dataToUse.rate_difference || 0) * 100;
    scheduleRate.weaveAverage = (dataToUse.weave_average || 0) * 100;
    scheduleRate.chartDataGroups =
      dataToUse.dates?.map(({ key, rate = 0, weave_average = 0 }) => {
        if (rate >= weave_average) {
          const conversionDifference = (weave_average - rate) * 100;

          if (conversionDifference > scheduleRate.highestConversion.difference) {
            scheduleRate.highestConversion = {
              difference: (weave_average - rate) * 100,
              timePeriod: key,
              timeType: dataToUse.time_period || '',
            };
          }
        }

        scheduleRate.dates[key] = rate * 100;

        return {
          name: key,
          values: {
            yourScheduleRate: rate * 100,
          },
        };
      }) || [];

    // TODO :: Enable this condition when the PM and team has confidence over the data being showed
    // scheduleRate.showWeaveAverageGraph = scheduleRate.difference > 0;

    return {
      scheduleRate,
    };
  }, [isDemoAccount, scheduleRateData]);

  return {
    ...processedData,
    isLoading,
  };
};
