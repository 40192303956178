import { GetWeavePopNotificationByType } from '@frontend/types';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

type Props = {
  onView?: (notification: GetWeavePopNotificationByType<'missed-call'>) => void;
};

export const useIncomingCallNotification = ({ onView }: Props = {}) => {
  const { create, remove } = useNotification<'incoming-call' | 'missed-call'>();
  useCommonActionSubscriptions('incoming-call');
  useCommonActionSubscriptions('missed-call');

  useNotificationActionSubscription('missed-call', 'view', (e) => {
    onView?.(e.notification);
    remove(e.notification.id);
  });

  return {
    create,
    remove,
  };
};
