import { MouseEventHandler, forwardRef } from 'react';
import { ROOT_NODE, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { DragIconSmall, Text, useTooltip } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../constants';
import { useComposerSection } from '../../../hooks';
import { emailAssistantGradientStyling } from '../../../styles';
import { useContentComposer } from '../../content-composer-provider';
import { FloatingButton, FloatingTool, FloatingToolbarLeft, FloatingToolbarRight } from '.';

type Props = {
  canCopy?: boolean;
  canDelete?: boolean;
  children: React.ReactNode;
  disableDrag?: boolean;
  title: string;
  Toolbar?: React.ReactNode;

  // TODO: Move the following props out of the composer
  openAssistantModal?: () => void;

  // Image and Logo specific props
  onClick?: MouseEventHandler<HTMLElement>;
};

export const BaseComposerSection = (props: Props & CommonHTMLAttributes) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const { meta } = useComposerSection();

  return (
    <BaseComposerSectionContent
      {...props}
      ref={(ref) => (ref && !props.disableDrag && meta.draggable ? connect(drag(ref)) : ref ? connect(ref) : null)}
    />
  );
};

const BaseComposerSectionContent = forwardRef<HTMLDivElement, Props>(
  ({ canCopy = true, canDelete = true, children, disableDrag, openAssistantModal, title, Toolbar, ...rest }, ref) => {
    const { copySection, deleteSection, id, isSelected, setMeta } = useComposerSection();
    const { selectedSectionId } = useContentComposer();
    const { Tooltip, triggerProps, tooltipProps } = useTooltip();

    return (
      <section
        id={id}
        css={css`
          display: flex;
          justify-content: center;
          position: relative;
          padding: ${theme.spacing(4)};
          background: ${theme.colors.neutral5};
          border: ${isSelected ? `solid 2px ${theme.colors.primary50}` : `solid 2px ${theme.colors.neutral20}`};
          &:not(:first-of-type) {
            ${!isSelected && `border-top: none;`}
          }
          .floating-toolbar {
            display: ${isSelected ? 'inline-flex' : 'none'};
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              display: none;
            }
          }
          &:hover {
            .floating-toolbar {
              display: ${selectedSectionId === ROOT_NODE || isSelected ? 'inline-flex' : 'none'};
              @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
                display: none;
              }
            }
          }
        `}
        {...rest}
        ref={ref}
        onClick={(event) => {
          event.stopPropagation(); // Stop click event propagation the canvas container as onClick on the canvas closes section settings
          rest.onClick?.(event);
        }}
      >
        <FloatingToolbarLeft>
          <FloatingButton
            onMouseEnter={() => setMeta((meta) => (meta.draggable = true))}
            onMouseLeave={() => setMeta((meta) => (meta.draggable = false))}
            css={{
              cursor: 'grab',
              display: disableDrag ? 'none' : 'block',
              fontWeight: theme.font.weight.bold,
            }}
          >
            <DragIconSmall />
          </FloatingButton>
          <FloatingTool>
            <Text as='span' weight='bold' {...triggerProps}>
              {title}
            </Text>
            <Tooltip {...tooltipProps}> {title} </Tooltip>
          </FloatingTool>
        </FloatingToolbarLeft>
        <FloatingToolbarRight>
          {openAssistantModal && (
            <FloatingButton
              onClick={openAssistantModal}
              css={[
                emailAssistantGradientStyling,
                {
                  border: '1px solid transparent',
                  width: theme.spacing(5.25),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: theme.font.weight.bold,
                },
              ]}
            >
              <Icon name='aiassistant-small' />
            </FloatingButton>
          )}
          {canCopy && (
            <FloatingButton onClick={copySection} css={{ fontWeight: theme.font.weight.bold }}>
              <Icon name='copy' />
            </FloatingButton>
          )}
          {canDelete && (
            <FloatingButton onClick={deleteSection} css={{ fontWeight: theme.font.weight.bold }}>
              <Icon name='trash' />
            </FloatingButton>
          )}
        </FloatingToolbarRight>
        {children}
        {Toolbar && Toolbar}
      </section>
    );
  }
);

BaseComposerSectionContent.displayName = 'ContentSection';
