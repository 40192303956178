import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import { CallRoute, Node, Edge } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { PhoneHoursScheduleRule } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { PhoneNumbersV1 } from '@frontend/api-phone-numbers';
import { getInitialParams } from '@frontend/env';
import { GlobalBannerView } from '@frontend/global-info-banner';
import { i18next, useTranslation } from '@frontend/i18n';
import { NodeTypes, NodeType, CallRouteFlow } from '@frontend/node-flow';
import { Page } from '@frontend/page';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { SettingsPageLayout } from '@frontend/settings-ui';
import { useAppFlagStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Button,
  Modal,
  Tray,
  useModalControl,
  ContentLoader,
  SkeletonLoader,
  Text,
  useAlert,
  ConfirmationModal,
} from '@frontend/design-system';
import { ActionBarButton } from '../../components/call-routes/call-route-details/action-bar-button';
import {
  AddStepPanel,
  CallObjectState,
} from '../../components/call-routes/call-route-details/add-step-panel/add-step-panel';
import { PhoneTreeAncestors } from '../../components/call-routes/call-route-details/add-step-panel/repeat-step';
import { useCallRouteGraphsShallowStore } from '../../components/call-routes/call-route-details/call-route-store';
import { LocationPanel } from '../../components/call-routes/call-route-details/location-panel';
import {
  isPhoneHoursCallObject,
  PhoneHoursPanel,
} from '../../components/call-routes/call-route-details/phone-hours-panel';
import { WarningModal } from '../../components/call-routes/call-route-details/warning-modal';
import { ChangeLocationModal } from '../../components/call-routes/change-location-modal copy';
import { DeleteCallRouteModal } from '../../components/call-routes/delete-call-route-modal';
import { EditExtensionsModal } from '../../components/call-routes/edit-extensions-modal';
import { EditPhoneNumbersModal } from '../../components/call-routes/edit-phone-numbers-modal';
import {
  getPhoneTreeNodesForAdd,
  getPhoneTreeNodesForEdit,
  getUnallocatedPhoneNumbers,
  isAssociativeNode,
  isPhoneTreeDescendant,
  shouldShowWarning,
  getPhoneHoursNodesForAdd,
  getPhoneHoursNodesForEdit,
  getNodesForNonAssociativeEdit,
  getNodesForNonAssociativeAdd,
  getNodesForDelete,
  areGraphsEqual,
  reconcileGraphNodes,
  findAllPhoneTreeAncestors,
  getDialOptionChildren,
} from '../../components/call-routes/helpers';
import { NumbersPanel } from '../../components/call-routes/numbers-panel';
import { OverridePanel } from '../../components/call-routes/override-panel';
import { RenameCallRouteModal } from '../../components/call-routes/rename-call-route-modal';
import { NodeAction, NodeActionType } from '../../components/call-routes/types';
import { useCallObjectsData } from '../../components/call-routes/use-call-objects-data';
import { CachedAudioScrubber } from '../../components/common/cached-audio-scrubber';
import { NewOverrideSettingTray } from '../../components/override/override-settings-modal.new';
import { DayHoursList } from '../../components/phone-hours/day-hours-list';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { buildAudioLibraryPath } from '../../utils/media-path';
import { useTenantLocationIds } from '../../utils/use-tenant-location-ids';

const SidePanel = {
  Numbers: i18next.t('Numbers', { ns: 'phone' }),
  Location: i18next.t('Location', { ns: 'phone' }),
  PhoneHours: i18next.t('Phone Hours', { ns: 'phone' }),
  Override: i18next.t('Override', { ns: 'phone' }),
} as const;

type SidePanelType = (typeof SidePanel)[keyof typeof SidePanel];

export const CallRouteDetails = () => {
  const { t } = useTranslation('phone');
  const {
    params: { id: callRouteId },
    // crid, crn and nid are set in the search params when the user is redirected from the call route details page.
    // (from clicking on a call route link in a node in the call route flow)
    search: { crid: redirectCallRouteId, crn: redirectCallRouteName, nid: redirectNodeId },
  } = useMatch<{ Params: { id: string }; Search: { crid?: string; crn?: string; nid?: string } }>();
  const alert = useAlert();

  const { navigate: settingsNavigate, context } = useSettingsNavigate<{ contextNodeId?: string }>();
  const { settingsTenantLocation, globalAvailableLocationIds = [] } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'globalAvailableLocationIds'
  );
  const tenantLocationIds = useTenantLocationIds();

  const { getScopeName, isSingleTypeScope } = useAppScopeStore();

  // The id of the node that the user is currently acting upon (adding, editing, deleting).
  // NOTE: when a user is adding a new step, the id will be the id of the node that the user wants to add a step to
  // (the node that is being extended which will ultimately be the parent of the new step). When a user is editing or
  // deleting a step, the id will be the id of the node that the user wants to edit or delete.
  const [processingNodeId, setProcessingNodeId] = useState<string | undefined>(undefined);
  const [processingActionType, setProcessingActionType] = useState<NodeActionType | undefined>(undefined);

  // The data to use when editing or adding a new step to the call route flow. We need to temporarily store the data
  // to use it after the user has confirmed the action in a modal
  const [tempProcessingData, setTempProcessingData] = useState<CallObjectState | undefined>(undefined);

  const { getFeatureFlagValue } = useAppFlagStore();
  const callRouteEditFlag = getFeatureFlagValue({
    flagName: 'call-routes-settings-edit',
    locationIds: globalAvailableLocationIds,
  });

  const callObjectsData = useCallObjectsData(settingsTenantLocation);

  // contextNodeId will be set when a user redirects back to the call route details page
  // from a call object settings page. A user can redirect back to the call route details page
  // from the breadcrumbs after they have been redirected to a call object page by clicking on a call
  // object links in one of the nodes in the call route canvas.
  const contextNodeId = context?.contextNodeId;

  const [activePanel, setActivePanel] = useState<SidePanelType>();

  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();
  const { modalProps: sidePanelModalProps, triggerProps: sidePanelTriggerProps } = useModalControl();
  const { modalProps: editPhoneNumbersModalProps, triggerProps: editPhoneNumbersTriggerProps } = useModalControl();
  const { modalProps: editExtensionsModalProps, triggerProps: editExtensionsTriggerProps } = useModalControl();
  const { modalProps: changeLocationModalProps, triggerProps: changeLocationTriggerProps } = useModalControl();
  const { modalProps: renameCallRouteModalProps, triggerProps: renameCallRouteTriggerProps } = useModalControl();
  const editOverrideTray = useModalControl();
  const { modalProps: addStepModalProps, triggerProps: addStepTriggerProps } = useModalControl();
  const { modalProps: confirmationModalProps, triggerProps: confirmationTriggerProps } = useModalControl();
  const { modalProps: forceResetConfirmModalProps, triggerProps: forceResetTriggerProps } = useModalControl();

  const {
    allNodes,
    allEdges,
    allTempEditingNodes,
    allTempEditingEdges,
    setNodesByCallRouteId,
    setEdgesByCallRouteId,
    setTempEditingNodesByCallRouteId,
    setTempEditingEdgesByCallRouteId,
    getNodesByCallRouteId,
    getEdgesByCallRouteId,
    getTempEditingNodesByCallRouteId,
    getTempEditingEdgesByCallRouteId,
  } = useCallRouteGraphsShallowStore(
    'allNodes',
    'allEdges',
    'allTempEditingNodes',
    'allTempEditingEdges',
    'setNodesByCallRouteId',
    'setEdgesByCallRouteId',
    'setTempEditingNodesByCallRouteId',
    'setTempEditingEdgesByCallRouteId',
    'getNodesByCallRouteId',
    'getEdgesByCallRouteId',
    'getTempEditingNodesByCallRouteId',
    'getTempEditingEdgesByCallRouteId'
  );

  const { data: callRoute, isLoading: callRouteIsLoading } = CallRouteV1.Queries.useReadQuery({
    request: { callRouteId },
    options: {
      enabled: !!callRouteId,
      staleTime: 0,
      cacheTime: 0,
    },
  });

  const callRouteNodes = useMemo(() => getNodesByCallRouteId(callRouteId), [callRouteId, allNodes]);
  const callRouteEdges = useMemo(() => getEdgesByCallRouteId(callRouteId), [callRouteId, allEdges]);
  const tempEditingNodes = useMemo(
    () => getTempEditingNodesByCallRouteId(callRouteId),
    [callRouteId, allTempEditingNodes]
  );
  const tempEditingEdges = useMemo(
    () => getTempEditingEdgesByCallRouteId(callRouteId),
    [callRouteId, allTempEditingEdges]
  );

  const _prevNodesAndEdges = useRef<{ nodes: Node[]; edges: Edge[] }>({ nodes: [], edges: [] });

  // Update the nodes and edges in the store when the call route data from the API changes.
  useEffect(() => {
    if (!callRoute) {
      return;
    }
    const latestNodesFromApi = callRoute?.nodes ?? [];
    const latestEdgesFromApi = callRoute?.edges ?? [];

    setNodesByCallRouteId(callRouteId, latestNodesFromApi);
    setEdgesByCallRouteId(callRouteId, latestEdgesFromApi);
  }, [callRoute?.nodes, callRoute?.edges]);

  // When the nodes and the edges in the store change, if there are temporary editing changes, then we need to check
  // if the nodes and edges from the API are STRUCTURALLY different from the nodes and edges in the store.
  // If they are different, then we need to check if the user has made any changes to the call route flow
  // and show a modal to inform the user that the unsaved changes need to be reset.
  // If they are not STRUCTURALLY different, then we need to update the temp editing nodes and edges with the latest
  // data from the API.
  useEffect(() => {
    if (callObjectsData.someIsLoading) return;

    const latestNodes = callRouteNodes ?? [];
    const latestEdges = callRouteEdges ?? [];
    const prevNodes = _prevNodesAndEdges.current.nodes;
    const prevEdges = _prevNodesAndEdges.current.edges;

    // If there are no previous nodes and edges, then we will set the previous nodes and edges to the latest nodes and
    // edges from the API and return. This is the first time the call route data is being set in the store.
    if (!prevNodes.length && !prevEdges.length) {
      _prevNodesAndEdges.current = { nodes: latestNodes, edges: latestEdges };
      return;
    }

    // Check if there are temporary editing nodes and edges in the store. If there are, then we need to compare
    // the latest nodes and edges from the API with nodes and edges that we have in the store to see if they are
    // structurally equal.
    if (tempEditingEdges && tempEditingNodes) {
      const areEqual = areGraphsEqual(
        { nodes: latestNodes, edges: latestEdges },
        { nodes: prevNodes, edges: prevEdges }
      );

      if (!areEqual) {
        // If the nodes and edges from the API are not equal to the nodes and edges in the store (graph structure only),
        // then we need to reset the temp editing nodes and edges and update the store with the latest nodes and edges
        // from the API. We will additionally show a modal to inform the user that the unsaved changes will be lost.
        forceResetTriggerProps.onClick();
        setTempEditingNodesByCallRouteId(callRouteId, undefined);
        setTempEditingEdgesByCallRouteId(callRouteId, undefined);
      } else {
        // If the nodes and edges from the API are equal to the nodes and edges in the store (graph structure only),
        // then we will update the temp editing nodes and edges with the latest nodes and edges callObject data from
        // the API to reflect the latest changes.
        const reconciledNodes = reconcileGraphNodes({ tempEditingNodes, latestNodes, prevNodes, callObjectsData });
        setTempEditingNodesByCallRouteId(callRouteId, reconciledNodes);
      }
    }

    _prevNodesAndEdges.current = { nodes: latestNodes, edges: latestEdges };
  }, [callRouteNodes, callRouteEdges]);

  const { data = { phoneOverrides: [], lastUsed: {} }, isLoading: phoneOverridesIsLoading } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.listOverrides()],
    queryFn: async () => PhoneOverrideApi.List({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }),
    retry: false,
    enabled: !!settingsTenantLocation?.phoneTenantId,
  });
  const phoneOverrides = data.phoneOverrides;

  const callRouteLocationId = callRoute?.callRoute.locationId;
  const { data: allPhoneNumbers, isLoading: phoneNumbersIsLoading } =
    PhoneNumbersV1.Queries.useListPhoneNumbersForLocationsQuery({
      request: { locationIds: tenantLocationIds },
      options: {
        enabled: !!tenantLocationIds.length && !!callRouteLocationId,
        select: (data) => data?.phoneNumbers ?? [],
        retry: false,
      },
    });

  // For now, we have to pull the full call routes list to get the call route data since the
  // singular call route query does not return all the necessary data.
  const { data: callRoutes, isLoading: callRoutesIsLoading } = CallRouteV1.Queries.useListQuery({
    request: { tenantId: settingsTenantLocation?.phoneTenantId ?? '' },
    options: {
      enabled: !!settingsTenantLocation?.phoneTenantId,
      select: ({ callRoutes }) =>
        // Sort the extension numbers so that they are displayed in ascending order.
        callRoutes.reduce<CallRoute[]>((acc, callroute) => {
          return [
            ...acc,
            {
              ...callroute,
              extensionNumbers: callroute.extensionNumbers.sort(),
            },
          ];
        }, []),
      cacheTime: 0,
      staleTime: 0,
    },
  });

  const { mutate: saveCallRoute, isLoading: saveCallRouteIsLoading } = CallRouteV1.Mutations.useSaveMutation({
    options: {
      onSuccess: () => {
        handleUndoChanges();
        alert.success(t('Call Route saved successfully.'));
      },
      onError: () => {
        alert.error(t('Failed to save Call Route.'));
      },
    },
  });

  const extendedCallRouteData = useMemo(() => {
    if (!callRoute || !callRoutes) {
      return undefined;
    }

    const callRouteData = callRoutes.find((route) => route.callRouteId === callRoute.callRouteId);
    if (!callRouteData) {
      return undefined;
    }

    const phoneNumberIds = callRouteData.phoneNumberIds ?? [];
    const phoneNumbers = allPhoneNumbers?.filter((phoneNumber) => phoneNumberIds.includes(phoneNumber.id)) ?? [];

    const callRoutePhoneOverride = phoneOverrides?.find(
      (phoneOverride) => phoneOverride.instructionSetId === callRouteData.rootInstructionSetId
    );

    return {
      callRoute: callRouteData,
      extendedCallRoute: {
        ...callRouteData,
        phoneNumbers,
        callRoutePhoneOverride,
      },
    };
  }, [callRoute?.callRouteId, allPhoneNumbers, phoneOverrides, callRoutes]);

  const extendedCallRoute = extendedCallRouteData?.extendedCallRoute;
  //  The phone numbers that are not allocated to any call route for a specific tenant.
  const unallocatedPhoneNumbers = useMemo(() => {
    if (!allPhoneNumbers || !callRoutes || !settingsTenantLocation?.phoneTenantId) {
      return [];
    }
    return getUnallocatedPhoneNumbers(allPhoneNumbers, callRoutes, settingsTenantLocation.phoneTenantId);
  }, [allPhoneNumbers, callRoutes, settingsTenantLocation?.phoneTenantId]);

  useEffect(() => {
    if (sidePanelModalProps.show === false) {
      setActivePanel(undefined);
    }
  }, [sidePanelModalProps.show]);

  useEffect(() => {
    // Clear out processingNodeId whenever the add step or confirmation modals are all closed since this means the user
    // has now added, edited or deleted a node or closed the modal without adding or deleting a node.
    if (addStepModalProps.show === false && confirmationModalProps.show === false) {
      setProcessingNodeId(undefined);
      setProcessingActionType(undefined);
      setTempProcessingData(undefined);
    }
  }, [addStepModalProps.show, confirmationModalProps.show]);

  const getNodes = () => tempEditingNodes ?? callRouteNodes ?? [];
  const getEdges = () => tempEditingEdges ?? callRouteEdges ?? [];

  // The node that the user is currently acting upon (adding, editing, deleting).
  const tempProcessingNode = useMemo(
    () => getNodes().find((node) => node.id === processingNodeId),
    [processingNodeId, tempEditingNodes, callRouteNodes]
  );

  const isLoading = callRouteIsLoading || phoneNumbersIsLoading || phoneOverridesIsLoading || callRoutesIsLoading;

  const callRouteName = callRoute?.callRoute.name ?? 'Unknown Call Route Name';

  // Callback function for when a call object link is clicked in a node.
  // Redirects the user to the appropriate page based on the type of node clicked.
  const handleCallObjectLinkClicked = useCallback(
    ({ callObjectId, nodeId, type }: { callObjectId: string; nodeId: string; type: NodeTypes }) => {
      switch (type) {
        case NodeType.CallGroup:
          settingsNavigate({
            to: `/phone/call-groups/:id`,
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name, nid: nodeId },
          });
          break;
        case NodeType.CallRoute:
          settingsNavigate({
            to: '/phone/call-routes/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name, nid: nodeId },
          });
          break;
        case NodeType.CallQueue:
          settingsNavigate({
            to: '/phone/call-queues/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name, nid: nodeId },
          });
          break;
        case NodeType.PhoneTree:
          settingsNavigate({
            to: '/phone/phone-tree/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name, nid: nodeId },
          });
          break;
        case NodeType.VoicemailBox:
          settingsNavigate({
            to: '/phone/voicemail-box/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name, nid: nodeId },
          });
          break;
        case NodeType.ForwardDevice:
          settingsNavigate({
            to: '/phone/devices/:id',
            params: { id: callObjectId },
            search: {
              id: callObjectId,
              crid: extendedCallRoute?.callRouteId,
              crn: extendedCallRoute?.name,
              nid: nodeId,
            },
          });
          break;
        default:
          console.error(`Unhandled node type: ${type}`);
      }
    },
    [extendedCallRoute?.callRouteId, extendedCallRoute?.name, settingsNavigate]
  );

  /**
   * This is a custom audio player component that is used in the call route flow for the PlayMessage and VoicemailBox nodes.
   * In the case of the voicemailBox node, the downloadUrl is passed in as a prop. In the case of the PlayMessage node, the
   * mediaItemId is passed in as a prop and so we need to build the download the URL for the audio file in those cases.
   */
  const AudioPlayerComponent = useCallback(
    (props: { mediaItemId: string; downloadUrl?: string }) => {
      if (!props.mediaItemId && !props.downloadUrl) {
        console.error('Missing mediaItemId or downloadUrl');
        return null;
      }

      let filePath = '';
      if (props.downloadUrl) {
        filePath = props.downloadUrl;
      } else {
        const currentEnvApi = getInitialParams().backendApi;
        const downloadUrl = `${currentEnvApi}/phone/audio-library/v1/download/${settingsTenantLocation?.phoneTenantId}/${props.mediaItemId}`;

        filePath = buildAudioLibraryPath({
          media: { id: props.mediaItemId, path: downloadUrl, isGlobal: false },
        });
      }

      return <CachedAudioScrubber customWidth={205} filePath={filePath} mediaId={props.mediaItemId} />;
    },
    [settingsTenantLocation?.phoneTenantId]
  );

  /**
   * Custom component for rendering the DayHoursList component in the call route flow.
   */
  const DayHoursListComponent = useCallback(
    (props: { phoneHours: PhoneHoursScheduleRule[] }) => <DayHoursList phoneHours={props.phoneHours} />,
    []
  );

  /**
   * Callback function for when the plus icon is clicked in a node in the canvas.
   */
  const handlePlusIconClicked = useCallback(
    ({ nodeId }: { nodeId: string }) => {
      setProcessingNodeId(nodeId);
      setProcessingActionType(NodeAction.Add);
      addStepTriggerProps.onClick();
    },
    [addStepTriggerProps.onClick]
  );

  /**
   * Callback function for when the edit icon is clicked in a node in the canvas.
   */
  const handleEditIconClicked = useCallback(
    ({ nodeId }: { nodeId: string }) => {
      setProcessingNodeId(nodeId);
      setProcessingActionType(NodeAction.Edit);
      addStepTriggerProps.onClick();
    },
    [addStepTriggerProps.onClick]
  );

  /**
   * Callback function for when the delete icon is clicked in a node in the canvas.
   */
  const handleDeleteIconClicked = useCallback(
    ({ nodeId }: { nodeId: string }) => {
      const nodesToUse = getNodes();
      const edgesToUse = getEdges();
      // find the node that the user wants to delete
      const nodeToDelete = nodesToUse.find((node) => node.id === nodeId);
      if (!nodeToDelete) {
        alert.error('Could not find node to delete');
        return;
      }

      const showWarning = shouldShowWarning({
        nodeId,
        actionType: NodeAction.Delete,
        nodes: nodesToUse,
        edges: edgesToUse,
      });
      if (showWarning) {
        setProcessingActionType(NodeAction.Delete);
        setProcessingNodeId(nodeId);
        confirmationTriggerProps.onClick();
        return;
      } else {
        handleDeleteStep(nodeId);
      }
    },
    [confirmationTriggerProps.onClick]
  );

  const handleConfirmEditStep = () => {
    const { type, callObject, dialOptions } = tempProcessingData ?? {};
    if (!type || !callObject) {
      alert.error('Missing type or callObject');
      return;
    }

    handleEditStep({ type, callObject, dialOptions });
  };

  const handleConfirmAddStep = () => {
    const { type, callObject, dialOptions } = tempProcessingData ?? {};
    if (!type || !callObject) {
      alert.error('Missing type or callObject');
      return;
    }

    handleAddStep({ type, callObject, dialOptions });
  };

  const handleConfirmDeleteStep = () => {
    const nodeId = processingNodeId;
    if (!nodeId) {
      alert.error('Could not find node to delete');
      console.error('Could not find node to delete: PC-jd93lf0');
      return;
    }

    handleDeleteStep(nodeId);
  };

  const handleDeleteStep = (nodeId: string) => {
    const nodesToUse = getNodes();
    const edgesToUse = getEdges();
    // find the node that the user wants to delete
    const nodeToDelete = nodesToUse.find((node) => node.id === nodeId);
    if (!nodeToDelete) {
      alert.error('Could not find node to delete');
      return;
    }

    const { nodes: newNodes, edges: newEdges } = getNodesForDelete({
      nodeId,
      nodes: nodesToUse,
      edges: edgesToUse,
    });

    confirmationModalProps.onClose();
    setTempEditingNodesByCallRouteId(callRouteId, newNodes);
    setTempEditingEdgesByCallRouteId(callRouteId, newEdges);
  };

  const handleEditStep = ({ type, callObject, dialOptions }: CallObjectState) => {
    if (!type || !callObject) {
      alert.error('Missing data for editing node.');
      console.error('Missing type or callObject: PC-jd7894h');
      return;
    }

    const nodesToUse = getNodes();
    const edgesToUse = getEdges();

    let data;
    const newTypeIsAssociative = isAssociativeNode(type);
    if (newTypeIsAssociative) {
      if (type === NodeType.PhoneTree) {
        data = getPhoneTreeNodesForEdit({
          data: { type, callObject, dialOptions },
          editingNodeId: processingNodeId ?? '',
          nodes: nodesToUse,
          edges: edgesToUse,
        });
      } else if (type === NodeType.OfficeHours) {
        data = getPhoneHoursNodesForEdit({
          data: { type, callObject, dialOptions },
          editingNodeId: processingNodeId ?? '',
          nodes: nodesToUse,
          edges: edgesToUse,
        });
      } else {
        alert.error('Unhandled associative node type');
        console.error('Unhandled associative node type: PC-idj82az');
        return;
      }
    } else {
      data = getNodesForNonAssociativeEdit({
        data: { type, callObject, dialOptions },
        processingNodeId: processingNodeId ?? '',
        nodes: nodesToUse,
        edges: edgesToUse,
      });
    }

    const { nodes: newNodes, edges: newEdges } = data;

    setTempEditingNodesByCallRouteId(callRouteId, newNodes);
    setTempEditingEdgesByCallRouteId(callRouteId, newEdges);
    addStepModalProps.onClose();
  };

  const handleAddStep = ({ type, callObject, dialOptions }: CallObjectState) => {
    if (!type || !callObject || !processingNodeId || !processingActionType) {
      alert.error('Missing data for processing node.');
      console.error('Missing type or callObject or processingNodeId or processingActionType: PC-7nm0934');
      return;
    }

    const nodesToUse = getNodes();
    const edgesToUse = getEdges();

    let data;
    const newTypeIsAssociative = isAssociativeNode(type);
    if (newTypeIsAssociative) {
      if (type === NodeType.PhoneTree) {
        data = getPhoneTreeNodesForAdd({
          data: { type, callObject, dialOptions },
          extendingNodeId: processingNodeId ?? '',
          nodes: nodesToUse,
          edges: edgesToUse,
        });
      } else if (type === NodeType.OfficeHours) {
        data = getPhoneHoursNodesForAdd({
          data: { type, callObject, dialOptions },
          extendingNodeId: processingNodeId ?? '',
          nodes: nodesToUse,
          edges: edgesToUse,
        });
      } else {
        alert.error('Unhandled associative node type');
        console.error('Unhandled associative node type: PC-j93o6is');
        return;
      }
    } else {
      data = getNodesForNonAssociativeAdd({
        data: { type, callObject, dialOptions },
        processingNodeId: processingNodeId ?? '',
        nodes: nodesToUse,
        edges: edgesToUse,
      });
    }

    const { nodes: newNodes, edges: newEdges } = data;

    setTempEditingNodesByCallRouteId(callRouteId, newNodes);
    setTempEditingEdgesByCallRouteId(callRouteId, newEdges);
    addStepModalProps.onClose();
  };

  const processStepAction = ({ type, callObject, dialOptions }: CallObjectState) => {
    if (!type || !callObject || !processingNodeId || !processingActionType) {
      alert.error('Missing data for processing node.');
      console.error('Missing type or callObject or processingNodeId or processingActionType: PC-7nm0934');
      return;
    }

    const nodesToUse = getNodes();
    const edgesToUse = getEdges();

    // First check if we need to show a warning to the user before proceeding with the action. If so, save the data
    // to use after the user has confirmed the action and display the confirmation modal.
    const showWarning = shouldShowWarning({
      nodeId: processingNodeId,
      newNodeType: type,
      nodes: nodesToUse,
      edges: edgesToUse,
      actionType: processingActionType,
    });
    if (showWarning) {
      setTempProcessingData({ type, callObject, dialOptions });
      confirmationTriggerProps.onClick();
      return;
    }

    if (processingActionType === NodeAction.Edit) {
      handleEditStep({ type, callObject, dialOptions });
    } else if (processingActionType === NodeAction.Add) {
      handleAddStep({ type, callObject, dialOptions });
    } else {
      alert.error('Unhandled action type');
      console.error('Unhandled action type: PC-u82l0pq');
      return;
    }
  };

  const getEditingNodeInitialData = (): CallObjectState | undefined => {
    if (!processingNodeId || !(processingActionType === NodeAction.Edit)) {
      return undefined;
    }

    const nodesToUse = getNodes();

    const editingNode = nodesToUse?.find((node) => node.id === processingNodeId);

    if (!editingNode) {
      alert.error('Could not find editing node');
      return undefined;
    }

    let dialOptions: string[] = [];
    if (editingNode.type === NodeType.PhoneTree) {
      // if the is a phone tree node, we need to get all of the dial options from the children nodes. We can
      // get the dial options from the nodes that have edges whose sourceId is equal to the editing node id and whose node
      // type is of type treeOption.
      const edgesToUse = tempEditingEdges ?? callRouteEdges ?? [];
      const targetNodeIds = edgesToUse.filter((edge) => edge.sourceId === editingNode.id).map((edge) => edge.targetId);
      const childNodes = nodesToUse.filter(
        (node) => targetNodeIds.includes(node.id) && node.type === NodeType.TreeOption
      );

      dialOptions = childNodes.map((node) => node?.callObject?.primitiveName ?? '');
    }

    return {
      type: editingNode.type as NodeTypes,
      callObject: editingNode.callObject,
      dialOptions,
    };
  };

  const getPhoneHoursCallObjects = useCallback(() => {
    if (!callRouteNodes) {
      return [];
    }

    const nodes = getNodes();

    const phoneHourCallObjects = nodes.map((node) => node.callObject).filter(isPhoneHoursCallObject);

    return phoneHourCallObjects;
  }, [callRouteNodes, tempEditingNodes]);

  // The call route nodes with some extra data for the flow.
  const processedNodes = useMemo(() => {
    if (!callRouteNodes || !callRouteEdges) {
      return [];
    }

    // Use the temporary nodes/edges if they exist, otherwise use the nodes/edges from the call route.
    const nodesToUse = getNodes();
    const edgesToUse = getEdges();

    return nodesToUse.map((node) => {
      return {
        ...node,
        // We need to check if the nodes are a descendent of a phone tree node, and if so, we add
        // an extra property to the node to indicate that it is a descendent of a phone tree.
        isPhoneTreeDescendent:
          node.type !== NodeType.PhoneTree && isPhoneTreeDescendant(node.id, nodesToUse, edgesToUse),
      };
    });
  }, [callRouteNodes, callRouteEdges, tempEditingNodes, tempEditingEdges]);

  // Get all of the ancestors of the node that the user is currently editing that are phone tree nodes.
  // along with their children dial option nodes.
  const getAncestorPhoneTrees = useCallback(() => {
    if (!processingNodeId) {
      return {};
    }

    const nodesToUse = getNodes();
    const edgesToUse = getEdges();

    const ancestors = findAllPhoneTreeAncestors(processingNodeId, nodesToUse, edgesToUse);

    const phoneTreeAncestors = ancestors.reduce((acc, ancestor) => {
      const dialOptions = getDialOptionChildren(ancestor.id, nodesToUse, edgesToUse);

      acc[ancestor.id] = {
        node: ancestor,
        dialOptions,
      };

      return acc;
    }, {} as PhoneTreeAncestors);

    return phoneTreeAncestors;
  }, [tempEditingNodes, tempEditingEdges, callRouteNodes, callRouteEdges, processingNodeId]);

  // The node that the user is currently acting upon (adding, editing, deleting).
  const processingNode = useMemo(() => {
    if (!processingNodeId) {
      return undefined;
    }

    return processedNodes.find((node) => node.id === processingNodeId);
  }, [processingNodeId, processedNodes]);

  const handleSaveChanges = () => {
    if (!tempEditingNodes || !tempEditingEdges || !callRoute) {
      alert.error('Missing data to save');
      console.error('Missing data to save: PC-kd9487f');
      return;
    }

    saveCallRoute({
      callRouteId: callRoute.callRouteId,
      edges: tempEditingEdges,
      nodes: tempEditingNodes,
      rootInstructionSetId: callRoute.callRoute.rootInstructionSetId,
    });
  };

  const handleUndoChanges = () => {
    setTempEditingNodesByCallRouteId(callRouteId, undefined);
    setTempEditingEdgesByCallRouteId(callRouteId, undefined);
    setProcessingNodeId(undefined);
    setTempProcessingData(undefined);
    addStepModalProps.onClose();
  };

  return (
    <SettingsPageLayout css={{ scrollbarGutter: 'stable' }}>
      <Page
        bgColor={theme.colors.white}
        css={css`
          max-width: 100%;
          padding: 0;
          display: flex;
          flex-direction: column;

          .page-header {
            padding: ${theme.spacing(2, 5)};
            margin-bottom: 0;
            border-bottom: 1px solid ${theme.colors.neutral10};
            height: 96px;
          }

          nav {
            margin-bottom: 0;
          }
        `}
      >
        {extendedCallRoute?.callRoutePhoneOverride?.enabled && (
          <GlobalBannerView
            title={t('Active Override')}
            type='warning'
            message={t(
              'This Call Route has an active override and will not direct calls normally until the override is removed.'
            )}
            isDismissible={false}
            action={{ label: t('Edit Override'), onClick: editOverrideTray.openModal }}
          />
        )}
        <Page.Header>
          <Page.Header.SettingsBreadcrumbs
            breadcrumbs={
              redirectCallRouteId
                ? [
                    { label: t('Phone'), to: '/phone/main' },
                    { label: t('Call Routes'), to: '/phone/call-routes' },
                    {
                      label: redirectCallRouteName ?? '',
                      onClick: () =>
                        settingsNavigate({
                          to: '/phone/call-routes/:id',
                          params: { id: redirectCallRouteId },
                          context: { contextNodeId: redirectNodeId },
                        }),
                    },
                    { label: callRouteName },
                  ]
                : [
                    { label: t('Phone'), to: '/phone/main' },
                    { label: t('Call Routes'), to: '/phone/call-routes' },
                    { label: callRouteName },
                  ]
            }
          />

          <Page.Header.Heading>
            {callRouteIsLoading ? (
              <SkeletonLoader width={300} />
            ) : (
              <Page.Header.Title title={callRouteName}>
                {' '}
                <Button
                  size='small'
                  variant='secondary'
                  iconName='edit-small'
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-route',
                    details: 'edit-call-route-name',
                  })}
                  css={{
                    '.button-icon': {
                      color: theme.colors.neutral60,
                    },
                  }}
                  {...renameCallRouteTriggerProps}
                />
              </Page.Header.Title>
            )}
            <Page.Header.Action>
              <Button
                variant='secondary'
                size='large'
                destructive
                disabled={callRouteIsLoading}
                {...deleteTriggerProps}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'delete-call-route-btn' })}
              >
                {t('Delete Call Route')}
              </Button>
            </Page.Header.Action>
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
            `}
            id='call-route-body-section'
          >
            {/* Main Content */}
            <section
              id='call-route-flow-section'
              css={css`
                flex: 1;
                background-color: ${theme.colors.neutral5};
                position: relative;
              `}
            >
              {/* 
                NOTE: added extendedCallRoute?.callRouteId to the condition here even though it seems like it is not
                required by CallRouteFlow component in order to prevent rerender and causing the Flow component from
                recreating it's node types since extendedCallRoute?.callRouteId is actually used in the dependency
                array of one of the callback functions passed to CallRouteFlow.
              */}
              {!!callRouteNodes && !!callRouteEdges && !!extendedCallRoute?.callRouteId && (
                <CallRouteFlow
                  nodes={processedNodes}
                  edges={tempEditingEdges ?? callRouteEdges}
                  canEdit={callRouteEditFlag}
                  onClickLink={handleCallObjectLinkClicked}
                  onPlusIconClick={handlePlusIconClicked}
                  onEditIconClick={handleEditIconClicked}
                  onDeleteIconClick={handleDeleteIconClicked}
                  AudioPlayerComponent={AudioPlayerComponent}
                  DayHoursListComponent={DayHoursListComponent}
                  initialCallObjectId={contextNodeId}
                />
              )}
              <ContentLoader show={isLoading} />
            </section>

            {/* Sidebar */}
            <aside
              css={css`
                width: 56px;
                border-left: 1px solid ${theme.colors.neutral10};
              `}
            >
              <ActionBarButton
                iconName='porting-numbers'
                label={SidePanel.Numbers}
                isActive={activePanel === SidePanel.Numbers}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'numbers-side-btn' })}
                onClick={() => {
                  setActivePanel(SidePanel.Numbers);
                  sidePanelTriggerProps.onClick();
                }}
              />
              {!isSingleTypeScope && (
                <ActionBarButton
                  iconName='location'
                  label={SidePanel.Location}
                  isActive={activePanel === SidePanel.Location}
                  trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'location-side-btn' })}
                  onClick={() => {
                    setActivePanel(SidePanel.Location);
                    sidePanelTriggerProps.onClick();
                  }}
                />
              )}
              <ActionBarButton
                iconName='clock'
                label={SidePanel.PhoneHours}
                isActive={activePanel === SidePanel.PhoneHours}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'clock-side-btn' })}
                onClick={() => {
                  setActivePanel(SidePanel.PhoneHours);
                  sidePanelTriggerProps.onClick();
                }}
              />
              <ActionBarButton
                iconName='voicemail-override'
                label={SidePanel.Override}
                isActive={activePanel === SidePanel.Override}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'override-side-btn' })}
                onClick={() => {
                  setActivePanel(SidePanel.Override);
                  sidePanelTriggerProps.onClick();
                }}
              />
            </aside>
          </div>

          {(tempEditingNodes || tempEditingEdges) && (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: ${theme.spacing(2)};
                padding: ${theme.spacing(2)};
                border-top: 1px solid ${theme.colors.neutral10};
              `}
            >
              <Text>{t('Changes were made')}</Text>
              <Button size='large' disabled={saveCallRouteIsLoading} onClick={handleSaveChanges}>
                {t('Save Changes')}
              </Button>
              <Button size='large' disabled={saveCallRouteIsLoading} variant='secondary' onClick={handleUndoChanges}>
                {t('Undo Changes')}
              </Button>
            </div>
          )}
        </Page.Body>
      </Page>

      {extendedCallRoute && (
        <Tray
          css={css`
            border-left: solid ${theme.colors.neutral10};
            overflow-y: scroll;
          `}
          mountTarget='#call-route-flow-section'
          width='medium'
          {...sidePanelModalProps}
          showBackdrop={false}
        >
          <Tray.Header
            Buttons={
              <Button
                iconName='x'
                onClick={() => {
                  setActivePanel(undefined);
                  sidePanelModalProps.onClose();
                }}
                variant='secondary'
                size='large'
              />
            }
          >
            {activePanel ?? ''}
          </Tray.Header>

          {activePanel === SidePanel.Numbers && (
            <NumbersPanel
              callRoute={extendedCallRoute}
              hasChildLocations={!isSingleTypeScope}
              onEditPhoneNumbers={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                editPhoneNumbersTriggerProps.onClick();
              }}
              onEditExtensions={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                editExtensionsTriggerProps.onClick();
              }}
            />
          )}
          {activePanel === SidePanel.Override && (
            <OverridePanel
              callRoute={extendedCallRoute}
              locationId={settingsTenantLocation?.locationId ?? ''}
              editOverrideTray={editOverrideTray}
            />
          )}

          {activePanel === SidePanel.Location && (
            <LocationPanel
              locationName={getScopeName(extendedCallRoute.locationId)}
              onChangeLocationClick={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                changeLocationTriggerProps.onClick();
              }}
            />
          )}

          {activePanel === SidePanel.PhoneHours && (
            <PhoneHoursPanel phoneHourCallObjects={getPhoneHoursCallObjects()} />
          )}
        </Tray>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...editPhoneNumbersModalProps} minWidth={600}>
          <EditPhoneNumbersModal
            onClose={() => {
              editPhoneNumbersModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            tenantLocation={settingsTenantLocation}
            unallocatedPhoneNumbers={unallocatedPhoneNumbers ?? []}
          />
        </Modal>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...editExtensionsModalProps} minWidth={600}>
          <EditExtensionsModal
            onClose={() => {
              editExtensionsModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            tenantLocation={settingsTenantLocation}
          />
        </Modal>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...changeLocationModalProps} minWidth={580}>
          <ChangeLocationModal
            onClose={() => {
              changeLocationModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            settingsTenantLocation={settingsTenantLocation}
          />
        </Modal>
      )}

      {extendedCallRoute && (
        <Modal {...renameCallRouteModalProps} minWidth={400}>
          <RenameCallRouteModal
            onClose={() => {
              renameCallRouteModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
          />
        </Modal>
      )}

      <DeleteCallRouteModal
        deleteModalProps={deleteModalProps}
        callRouteId={callRouteId}
        onSuccess={() => {
          settingsNavigate({ to: '/phone/call-routes' });
        }}
      />
      <NewOverrideSettingTray
        modalControls={editOverrideTray}
        override={extendedCallRoute?.callRoutePhoneOverride}
        mountTarget='#call-route-body-section'
        isCallRouteFlow={true}
        overridesData={data}
      />

      <Tray mountTarget='#call-route-flow-section' width='xlarge' {...addStepModalProps}>
        <AddStepPanel
          onClose={addStepModalProps.onClose}
          onAddStep={processStepAction}
          initialState={getEditingNodeInitialData()}
          callObjectsData={callObjectsData}
          omitStepTypes={[!processingNode?.isPhoneTreeDescendent ? NodeType.Repeat : undefined].filter(Boolean)}
          getAncestorPhoneTrees={getAncestorPhoneTrees}
        />
      </Tray>

      <ConfirmationModal
        {...forceResetConfirmModalProps}
        maxWidth={500}
        title={t('{{callRouteName}} Call Route Has Changed', { callRouteName: callRoute?.callRoute.name })}
        message={t(
          'This Call Route was updated elsewhere since your last visit. The edits you made here have been replaced to reflect the most recent changes.'
        )}
        confirmLabel={t('View Call Route')}
        confirmTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'force-reset::confirm-btn',
        })}
        cancelLabel=''
        onConfirm={() => forceResetConfirmModalProps.onClose()}
      />

      {processingActionType && (
        <WarningModal
          modalProps={confirmationModalProps}
          nodeName={processingActionType === NodeAction.Delete ? tempProcessingNode?.callObject?.primitiveName : ''}
          stepType={
            processingActionType === NodeAction.Add ? tempProcessingData?.type : (tempProcessingNode?.type as NodeTypes)
          }
          stepActionType={processingActionType}
          onConfirm={() => {
            if (processingActionType === NodeAction.Delete) {
              handleConfirmDeleteStep();
            } else if (processingActionType === NodeAction.Edit) {
              handleConfirmEditStep();
            } else if (processingActionType === NodeAction.Add) {
              handleConfirmAddStep();
            } else {
              alert.error('Unhandled step action type');
            }
          }}
          changingStepType={processingActionType === NodeAction.Edit ? tempProcessingData?.type : undefined}
        />
      )}
    </SettingsPageLayout>
  );
};
