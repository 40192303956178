import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
  RegistrationPublicKeyCredential,
} from '@github/webauthn-json/browser-ponyfill';
import {
  AuthService,
  BeginWebAuthnLoginResponse,
  BeginWebAuthnRegistrationResponse,
  CompleteWebAuthnLoginRequest,
  CompleteWebAuthnLoginResponse,
  CompleteWebAuthnRegistrationRequest,
  CompleteWebAuthnRegistrationResponse,
  VerifyAuthResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import {
  AuthStorage,
  localStorageHelper,
  LOGIN_ERROR_CAUSE,
  SignInMethod,
  isWeaveTokenActive,
  setWeaveToken,
  getExpireLength,
} from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { sentry } from '@frontend/tracking';
import { AuthFlow, SignInProps, SignOutProps, ClientTokens, HandleCallbackProps } from './AuthFlow';
import { APIFetchNoAuth } from './fetch';

export class LegacyClient implements AuthFlow {
  public getSignInMethod(): Promise<SignInMethod> {
    return Promise.resolve('legacy');
  }

  public getTokens(): Promise<ClientTokens> {
    const weaveJWT = localStorageHelper.get<string>(AuthStorage.weave_token);
    const tokens = {
      weaveToken: weaveJWT,
    };
    return Promise.resolve(tokens);
  }

  public handleCallback(_?: HandleCallbackProps): Promise<ClientTokens> {
    return Promise.resolve(this.getTokens());
  }

  public isUserAuthenticated(): boolean {
    return isWeaveTokenActive();
  }

  public async signIn(signInInfo?: SignInProps): Promise<VerifyAuthResponse> {
    if (!!signInInfo?.useWebauthn) {
      return this.beginSignInWithWebauthn();
    }

    return AuthService.VerifyAuth(
      APIFetchNoAuth(appConfig.BACKEND_API) as (url: string, reqInit: RequestInit) => Promise<VerifyAuthResponse>,
      {
        data: {
          credentials: {
            username: signInInfo?.username,
            password: signInInfo?.password,
          },
          code: signInInfo?.code,
        },
        challenge: signInInfo?.challenge,
        previousChallenge: localStorageHelper.get(AuthStorage.cached_challenge), // sends the cached challenge if it exists
      }
    )
      .then((res) => {
        if (res.token) {
          // successful login
          setWeaveToken(res.token);
          if (!!signInInfo?.code && signInInfo?.challenge) {
            // if we have a code and challenge, we are doing MFA
            if (signInInfo?.rememberMe) {
              // only store the challenge if rememberMe is true
              // store the challenge for cached MFA logins
              localStorageHelper.create(AuthStorage.cached_challenge, signInInfo.challenge);
            } else {
              // clear the challenge if rememberMe is false
              localStorageHelper.delete(AuthStorage.cached_challenge);
            }
          }
          if (signInInfo?.registerWebauthn) {
            return this.handleWebauthnRegistration(res.token).then(() => res);
          }
          return Promise.resolve(res);
        } else if (res.mfa) {
          return Promise.resolve(res);
        } else {
          throw new Error('No token returned');
        }
      })
      .catch((err) => {
        sentry.error({
          topic: 'auth',
          error: err,
          addContext: {
            name: 'auth',
            context: {
              errMessage: 'Fetching verify token',
            },
          },
        });
        throw new Error('Unknown login error', { cause: LOGIN_ERROR_CAUSE.unknown });
      });
  }

  public async signOut(props?: SignOutProps): Promise<never> {
    localStorageHelper.delete(AuthStorage.weave_token);
    if (props?.logoutRedirect) {
      window.location.href = props?.logoutRedirect;
    } else {
      window.location.href = '/'; // we default to the home page
    }
    return new Promise<never>(() => {}); // return a promise that never resolves
  }

  private async beginSignInWithWebauthn(): Promise<VerifyAuthResponse> {
    return AuthService.BeginWebAuthnLogin(
      this.wrappedFetch() as (url: string, reqInit: RequestInit) => Promise<BeginWebAuthnLoginResponse>,
      {}
    ).then((data) => {
      let jsonAssertions = '';

      if (data.assertionData) {
        jsonAssertions = atob(data.assertionData);
      }

      const parsedAssertions = JSON.parse(jsonAssertions);
      const assertions = parseRequestOptionsFromJSON(parsedAssertions);

      // webauthn login challenge
      return get(assertions)
        .then((credential) => {
          return this.handleCompleteWebauthnLogin({
            sessionId: data.sessionId,
            credential: JSON.stringify(credential),
            // username: signInInfo.username,
            exp: `${getExpireLength()}`,
          });
        })
        .catch((err) => {
          sentry.error({
            topic: 'auth',
            error: 'WebAuthn login failed',
            addContext: {
              name: 'auth',
              context: {
                errMessage: err.message,
              },
            },
          });
          console.error(err.message);
          throw new Error('Login failed', { cause: LOGIN_ERROR_CAUSE.webauthn });
        });
    });
  }

  private handleCompleteWebauthnLogin(req: CompleteWebAuthnLoginRequest) {
    return AuthService.CompleteWebAuthnLogin(
      this.wrappedFetch() as (url: string, reqInit: RequestInit) => Promise<CompleteWebAuthnLoginResponse>,
      req
    ).then((data) => {
      if (!!data?.token) {
        setWeaveToken(data.token);
        const verifyResponse: VerifyAuthResponse = {
          token: data.token,
        };
        return verifyResponse;
      }
      throw new Error('No token present after webauthn login');
    });
  }

  private handleWebauthnRegistration(token: string) {
    return AuthService.BeginWebAuthnRegistration(
      this.wrappedFetch(token) as (url: string, reqInit: RequestInit) => Promise<BeginWebAuthnRegistrationResponse>,
      {}
    ).then((data) => {
      let jsonOptions = '';
      if (data.options) {
        jsonOptions = atob(data.options);
      }
      const parsedOptions = JSON.parse(jsonOptions);
      const options = parseCreationOptionsFromJSON(parsedOptions);

      // webauthn registration challenge
      return create(options).then((credential: RegistrationPublicKeyCredential) => {
        const displayName = this.getCredentialDisplayName(credential);
        return this.handleCompleteWebauthnRegistration(token, {
          sessionId: data.sessionId,
          credential: JSON.stringify(credential),
          credentialDisplayName: displayName,
        });
      });
    });
  }

  private getCredentialDisplayName(credential: RegistrationPublicKeyCredential) {
    let displayName = '';
    if (credential.authenticatorAttachment === 'cross-platform') {
      // figure out what names to give to cross-platform keys
      displayName = 'Cross-Platform Authenticator';
    } else if (credential.authenticatorAttachment === 'platform') {
      // using User-Agent we can guess at the platform
      if (/iPhone|iPad|Mac/.test(navigator.userAgent)) {
        displayName = 'iCloud';
      } else if (/Windows/.test(navigator.userAgent)) {
        displayName = 'Windows Hello';
      } else if (/Android/.test(navigator.userAgent)) {
        displayName = 'Android';
      } else if (/CrOS/.test(navigator.userAgent)) {
        displayName = 'Chrome OS';
      } else {
        displayName = 'Unknown Platform Authenticator';
      }
    }
    // append the year and month to the key name e.g. 2024-09 with zero padded numbers
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const monthStr = month.toString().padStart(2, '0');
    displayName += ` ${year}-${monthStr}`;
    return displayName;
  }

  private handleCompleteWebauthnRegistration(token: string, req: CompleteWebAuthnRegistrationRequest) {
    return AuthService.CompleteWebAuthnRegistration(
      this.wrappedFetch(token) as (url: string, reqInit: RequestInit) => Promise<CompleteWebAuthnRegistrationResponse>,
      req
    ).then((data) => {
      return data;
    });
  }

  private wrappedFetch(token?: string): (url: string, reqInit: RequestInit) => Promise<Response> {
    return (url: string, reqInit: RequestInit) => {
      if (!!token) {
        reqInit.headers = {
          ...reqInit.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return fetch(`${appConfig.BACKEND_API}${url}`, reqInit).then((res) => {
        if (res.status !== 200) {
          return res.json().then((resBody: { code: number; message: string }) => {
            throw new Error(resBody.message);
          });
        }
        return res.json();
      });
    };
  }
}
