import { useCallback, useEffect, useMemo, useState } from 'react';
import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { DestinationType_Enum, MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import {
  DynamicFieldProperty_Enum,
  Template,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { isEqual } from 'lodash-es';
import { AppointmentsApiQueries } from '@frontend/api-appointments';
import { DepartmentsQueries } from '@frontend/api-departments';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { CommPreferenceQueries, MessagesHooks, MessagesTypes, MessagesUtils } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { PetsV1 } from '@frontend/api-pets';
import { ReviewInvitationMutations } from '@frontend/api-reviews-invitation';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useDraft } from '@frontend/api-sms-draft';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { ThreadSendingAreaUtils } from '@frontend/thread-sending-area';
import { useDebouncedValue, useFileUpload, useAlert, ModalProps } from '@frontend/design-system';
import { SendInThreadStateProps } from '../components';
import { TEMPLATE_TYPE_LINK_MAP } from '../constants';
import { SendInThreadModalProps } from '../modals';
import { LinkData, PropertyBindingsData } from '../types';
import {
  convertBindingsDataToPropertyBindingsList,
  convertLinkDataToProperty,
  getPetBindingFromAppointmentData,
  reducePetRelatedAppointmentData,
} from '../utils';
import { useGetDefaultTemplate } from './use-get-default-template';
import { useThreadMedia } from './use-thread-media';

type UseSendInThreadStateArgs = Omit<SendInThreadModalProps, 'onBack' | keyof ModalProps> & {
  propertyBindings?: PropertyBindingsData;
  sendToCustomPhoneNumber?: boolean;
  messageType?: MessageType_Enum;
  onSend?: NonNullable<NonNullable<Parameters<typeof SMSSendV3.Mutations.useSendMutation>[0]>['options']>['onSuccess'];
  onSendError?: NonNullable<
    NonNullable<Parameters<typeof SMSSendV3.Mutations.useSendMutation>[0]>['options']
  >['onError'];
  onSchedule?: NonNullable<
    NonNullable<Parameters<typeof ManualSmsScheduledV1.Mutations.useScheduleMutation>[0]>['options']
  >['onSuccess'];
  onScheduleError?: NonNullable<
    NonNullable<Parameters<typeof ManualSmsScheduledV1.Mutations.useScheduleMutation>[0]>['options']
  >['onError'];
};

type SendInThreadState = {
  contentProps: SendInThreadStateProps;
  sendMessage: () => void;
  scheduleMessage: (scheduledTime: Date | string, pausable: boolean) => void;
  setDraftToRenderedTemplate: (template: Template, propertyBindings?: PropertyBindingsData) => void;
};

export const useSendInThreadState = ({
  groupId,
  initThreadId,
  initPersonPhone,
  personId,
  petId,
  onSend,
  onSendError,
  onSchedule,
  onScheduleError,
  messageType,
  onThreadChange,
  templateType,
  sendToCustomPhoneNumber,
  linkData,
  propertyBindings,
  initialBody,
  initialMediaIds,
}: UseSendInThreadStateArgs): SendInThreadState => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const { selectedOrgId, getLocationName, accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const { featureFlags } = useAppFlagStore();
  const locationData = accessibleLocationData[groupId];
  const currentUser = getUser();
  const [bodyValue, setBodyValue] = useState(initialBody ?? '');
  const [dynamicModalContextSubtitle, setDynamicModalContextSubtitle] = useState<string>();
  const [previousThreadId, setPreviousThreadId] = useState(initThreadId);
  const debouncedBodyValue = useDebouncedValue(bodyValue, 1000);
  const [selectedPersonPhone, setSelectedPersonPhone] = useState(initPersonPhone);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>();
  const [threadSelectionHasChanged, setThreadSelectionHasChanged] = useState(false);
  const getDefaultTemplate = useGetDefaultTemplate({
    groupIds: [groupId],
  });
  const [initTemplate, setInitTemplate] = useState<Template>();
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === Vertical.VET;
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: [groupId],
      personId: personId ?? '',
    },
    {
      enabled: !!personId,
      select: (data) =>
        data
          ? {
              ...data,
              contactInfo: data.contactInfo?.filter(
                (contact) => contact.type !== ContactType_Enum.EMAIL && !!contact.destination
              ),
            }
          : undefined,
    }
  );

  const petNameEnabled = !!featureFlags[groupId]?.get('nwx:manual-templates-pet-name')?.value;
  const petsQuery = PetsV1.Queries.useFetchPetsByPersonIdQuery(
    {
      personId: personId ?? '',
      locationId: groupId,
    },
    {
      enabled: !!personId && isVet && petNameEnabled,
    }
  );
  const { data: appointmentsData } = AppointmentsApiQueries.useListPersonAppointments(
    {
      locationId: groupId,
      personId: personId ?? '',
      includePast: true,
    },
    {
      enabled: !!personId && isVet && petNameEnabled,
      select: (data) => reducePetRelatedAppointmentData(data.appointments, petsQuery.data?.pets ?? [], petId),
    }
  );
  const departmentsQuery = DepartmentsQueries.useListDefaultSMSQuery({ locationId: groupId });

  const threadStatusQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        groupIds: [groupId],
        personPhones: personQuery.data?.contactInfo?.map((contact) => contact.destination ?? '').filter(Boolean) ?? [],
        departmentIdsPerGroupId: {
          [groupId]: (departmentsQuery.data.smsNumbers ?? []).map((number) => number.id ?? '').filter(Boolean),
        },
      }),
    },
    options: {
      select: (data) => {
        const sortedAllThreads = data.responses.sort((a, b) => {
          const aLastModified = a.lastModified ?? '';
          const bLastModified = b.lastModified ?? '';
          return bLastModified.localeCompare(aLastModified);
        });
        return {
          lastUpdatedThread: sortedAllThreads[0],
          sortedAllThreads,
        };
      },
    },
  });
  const initialThread = initThreadId
    ? threadStatusQuery.data?.sortedAllThreads.find((thread) => thread.threadId === initThreadId)
    : initPersonPhone
    ? threadStatusQuery.data?.sortedAllThreads.find(
        (thread) => formatPhoneNumberE164(thread.personPhone) === formatPhoneNumberE164(initPersonPhone)
      )
    : threadStatusQuery.data?.lastUpdatedThread;

  const threadIdQuery = SMSDataV3.Queries.useLookupThreadIdQuery({
    request: {
      locationId: groupId,
      personPhone: selectedPersonPhone ? formatPhoneNumberE164(selectedPersonPhone) : '',
      departmentId: selectedDepartmentId,
      calculateMissing: true,
    },
    options: {
      enabled:
        (!!selectedPersonPhone && (selectedPersonPhone !== initPersonPhone || !!selectedDepartmentId)) ||
        !initialThread,
    },
  });

  const { data: smsPreference } = CommPreferenceQueries.useCheckSMSPreference({
    userChannelAddress: selectedPersonPhone ? formatPhoneNumberE164(selectedPersonPhone) : '',
    locationId: groupId,
    messageType: messageType ?? MessageType_Enum.MESSAGING_MANUAL,
    channel: Channel.CHANNEL_SMS,
  });

  const threadId = threadSelectionHasChanged ? threadIdQuery.data?.threadId : initThreadId;
  const { deleteMutation, shallowUpdate } = useDraft({
    threadId: threadId ?? '',
    userId: currentUser?.userID ?? '',
    orgId: selectedOrgId,
    locationId: groupId,
    groupIds: selectedLocationIds,
  });
  const {
    metadata,
    messages,
    scheduledMessages,
    isLoadingFirstPage,
    hasOlderMessages,
    fetchOlderMessages,
    mediaQueries,
  } = MessagesHooks.useThread({
    threadId: threadId ?? '',
    groupId,
    providedPersonPhone: selectedPersonPhone,
    getThreadRetryLimit: 1,
  });
  const selectedDepartmentQuery = DepartmentsQueries.useListDefaultSMSQuery(
    { locationId: groupId },
    {
      select: (data) => {
        return data.smsNumbers?.find((number) => number.id === selectedDepartmentId);
      },
      enabled: !!selectedDepartmentId,
    }
  );

  const locationPhone =
    departmentsQuery.data?.smsNumbers?.find((dept) => dept.id === selectedDepartmentId)?.smsNumber?.number ||
    messages[0]?.locationPhone ||
    '';

  const onSelectPersonPhone = (personPhone: string) => {
    if (!threadSelectionHasChanged) setThreadSelectionHasChanged(true);
    setSelectedPersonPhone(personPhone);
  };

  const onSelectDepartmentId = (departmentId?: string) => {
    if (!threadSelectionHasChanged) setThreadSelectionHasChanged(true);
    setSelectedDepartmentId(departmentId);
  };

  const { threadMedia, uploadFiles, removeMediaItem, clearMedia } = useThreadMedia({
    threadId: threadId ?? '',
    groupId,
    departmentId: selectedDepartmentId ?? '',
    personPhone: selectedPersonPhone ?? '',
    locationPhone,
    maxFileCount: 10,
    loadDraft: false,
    onError: () => {
      alert.error(t('Error uploading image. Please try again.'));
    },
    onExceedMaxFileCount: (excessCount) => {
      alert.error(
        t('You can only send up to 10 images in a single message. {{count}} images were not uploaded.', {
          count: excessCount,
        })
      );
    },
    mediaIds: initialMediaIds,
  });

  const imageUploadProps = useFileUpload({
    acceptedFileType: ['jpeg', 'png', 'jpg'],
    onFileUpload: (files) => {
      uploadFiles(files);
      imageUploadProps.resetFiles();
    },
  });

  const clearDraft = () => {
    deleteMutation.mutate({ threadId: threadId ?? '', userId: currentUser?.userID ?? '', orgId: selectedOrgId });
    clearMedia();
    setBodyValue('');
  };

  const { data: signature } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId,
      userId: currentUser?.userID ?? '',
    },
    options: {
      enabled: !!currentUser?.userID,
      select: (data) => data.signature.signature,
    },
  });
  const { mutate: sendMessage } = SMSSendV3.Mutations.useSendMutation({
    options: {
      onSuccess: (...args) => {
        clearDraft();
        onSend?.(...args);
      },
      onError: (...args) => {
        onSendError?.(...args);
      },
    },
    optimisticUpdate: true,
  });

  const { mutate: scheduleMessage } = ManualSmsScheduledV1.Mutations.useScheduleMutation({
    options: {
      onSuccess: (...args) => {
        clearDraft();
        onSchedule?.(...args);
      },
      onError: (...args) => {
        onScheduleError?.(...args);
      },
    },
    optimisticUpdate: true,
  });

  const { associatedContacts, isLoading: isGetAssociatedContactsLoading } = PersonsV3.PersonHooks.useAssociatedContacts(
    {
      phoneNumber: selectedPersonPhone ?? '',
      locationId: groupId,
      enabled: !!sendToCustomPhoneNumber && !!selectedPersonPhone,
    }
  );

  const [renderData, setRenderData] = useState<{
    payload: Parameters<(typeof TemplatorV2Queries)['useRender']>[0];
    templateId: string;
    linkData?: LinkData;
  }>();
  const renderedTemplateQuery = TemplatorV2Queries.useRender(
    renderData?.payload
      ? {
          ...renderData.payload,
          keepEmptyDynamicFields: true,
        }
      : {
          templateTypeSlug: TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
          templateString: '',
          bindingsList: [],
          timezone: locationData?.timezone ?? '',
          // TODO: get the locale dynamically once we have a way to do so
          locale: 'en_US',
          destinationType: DestinationType_Enum.SMS,
        },
    {
      enabled: !!renderData,
      onSuccess: ({ message: { message } }) => {
        setBodyValue(message);
      },
    }
  );

  const mediaIds = threadMedia.reduce<string[]>((acc, media) => {
    if (media.mediaObj?.mediaId) acc.push(media.mediaObj.mediaId);
    return acc;
  }, []);

  const getRelatedIds = (body: string): RelatedID[] => {
    if (!templateType || !linkData || !body.includes(linkData.link)) return [];
    const linkMap = TEMPLATE_TYPE_LINK_MAP[templateType];
    return [
      {
        type: linkMap.relatedType,
        id: linkData.relatedId,
      },
    ];
  };

  const getLabels = (relatedIds: RelatedID[]) => {
    return relatedIds.reduce<Parameters<typeof sendMessage>[0]['labels']>((acc, curr) => {
      if (curr.type === RelatedType.RELATED_TYPE_FORM) {
        if (acc) {
          acc[curr.type] = curr.id;
        } else {
          acc = { [curr.type]: curr.id };
        }
      }
      return acc;
    }, undefined);
  };

  const handleSend = () => {
    const relatedIds = getRelatedIds(bodyValue);
    sendMessage({
      locationId: groupId,
      personPhone: selectedPersonPhone ? MessagesUtils.formatPhoneWithCountryCode(selectedPersonPhone) : '',
      departmentId: selectedDepartmentId,
      body: `${bodyValue}\n\n${signature}`.trim(),
      media: mediaIds.map((mediaId) => ({ mediaId })),
      programSlugId: MessagesTypes.KnownProgramSlugIds.MANUAL_MESSAGES,
      relatedIds,
      personId: sendToCustomPhoneNumber ? metadata?.person?.personId ?? '' : personId, // To support sending empty personId for unknown selectedPhoneNumbers
      createdBy: currentUser?.userID,
      messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds(relatedIds),
      labels: getLabels(relatedIds), // This is needed for the forms team to get the submission id on sms update events
      _otherOptions: threadId
        ? {
            threadId,
          }
        : undefined,
    });
  };

  const handleSchedule = (scheduledTime: Date | string, pausable: boolean) => {
    const relatedIds = getRelatedIds(bodyValue);
    scheduleMessage({
      locationId: groupId,
      threadId: threadId ?? '',
      departmentId: selectedDepartmentId ?? '',
      locationPhone: formatPhoneNumberE164(locationPhone),
      personPhone: selectedPersonPhone ? formatPhoneNumberE164(selectedPersonPhone) : '',
      personId: sendToCustomPhoneNumber ? metadata?.person?.personId ?? '' : personId, // To support sending empty personId for unknown selectedPhoneNumbers
      body: `${bodyValue}\n\n${signature}`.trim(),
      mediaIds,
      scheduledBy: currentUser?.userID ?? '',
      sendAt: typeof scheduledTime === 'string' ? scheduledTime : scheduledTime.toISOString(),
      relatedIds,
      pausable,
      messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds(relatedIds),
    });
  };

  useEffect(() => {
    if (renderedTemplateQuery.data) {
      setBodyValue(renderedTemplateQuery.data.message.message);
    }
  }, [renderedTemplateQuery.data]);

  useEffect(() => {
    if (previousThreadId)
      shallowUpdate({
        threadId: previousThreadId,
        draft: { body: debouncedBodyValue },
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
      });
  }, [debouncedBodyValue]);

  useEffect(() => {
    if (metadata?.departmentId && !selectedDepartmentId) setSelectedDepartmentId(metadata.departmentId);
  }, [metadata?.departmentId]);

  useEffect(() => {
    if (threadId && previousThreadId !== threadId) {
      if (previousThreadId)
        deleteMutation.mutate({ threadId: previousThreadId, userId: currentUser?.userID ?? '', orgId: selectedOrgId });
      shallowUpdate({
        threadId,
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
        draft: {
          body: bodyValue,
          medias: threadMedia.map((media) => ({
            mediaId: media.mediaObj?.mediaId || media.id,
          })),
        },
      });
      setPreviousThreadId(threadId);
    }
  }, [threadId]);

  useEffect(() => {
    if (threadId)
      shallowUpdate({
        threadId,
        departmentId: selectedDepartmentId ?? '',
        personPhone: selectedPersonPhone ?? '',
        locationPhone,
        draft: {
          medias:
            initialMediaIds?.map((mediaId) => ({
              mediaId,
            })) ?? [],
        },
      });
  }, [JSON.stringify(initialMediaIds)]);

  useEffect(() => {
    if ((initialThread?.threadId || !!personQuery.data?.personId) && !initThreadId) {
      setSelectedPersonPhone(initialThread?.personPhone ?? personQuery.data?.contactInfo?.[0]?.destination);
      if (initialThread?.departmentId) setSelectedDepartmentId(initialThread?.departmentId);
      if (initialThread?.threadId) setPreviousThreadId(initialThread?.threadId);
      setThreadSelectionHasChanged(true);
    }
  }, [initialThread?.threadId, personQuery.data?.personId]);

  const petName = useMemo(
    () =>
      getPetBindingFromAppointmentData(
        appointmentsData ?? { allAppointments: [], futureAppointmentCount: 0, latestAppointment: undefined },
        petsQuery.data?.pets ?? [],
        linkData?.relatedType === RelatedType.RELATED_TYPE_APPOINTMENT ? linkData.relatedId : undefined
      ),
    [JSON.stringify(appointmentsData), JSON.stringify(petsQuery.data?.pets), linkData?.relatedType, linkData?.relatedId]
  );

  const defaultBindingsData = useMemo<PropertyBindingsData>(
    () => ({
      [DynamicFieldProperty_Enum.ORG_NAME]: getLocationName(selectedOrgId),
      [DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME]: getLocationName(groupId),
      [DynamicFieldProperty_Enum.BUSINESS_GROUP_PHONE]:
        selectedDepartmentQuery.data?.smsNumber?.number || messages?.[0]?.locationPhone,
      [DynamicFieldProperty_Enum.FIRST_NAME]: personQuery.data?.firstName,
      [DynamicFieldProperty_Enum.LAST_NAME]: personQuery.data?.lastName,
      [DynamicFieldProperty_Enum.PREFERRED_NAME]: personQuery.data?.preferredName || personQuery.data?.firstName,
      [DynamicFieldProperty_Enum.PET_NAME]: petName || '[Pet Name]',
    }),
    [
      groupId,
      selectedOrgId,
      personQuery.data?.firstName,
      personQuery.data?.lastName,
      personQuery.data?.preferredName,
      messages?.[0]?.locationPhone,
      getLocationName,
      petName,
    ]
  );

  const { mutateAsync: reviewInvitationMutate } = ReviewInvitationMutations.useReviewInvitationMutation();

  const setDraftToRenderedTemplate = useCallback<SendInThreadState['setDraftToRenderedTemplate']>(
    async (template, propertyBindingsData = {}) => {
      const linkDataPropertyBindings =
        linkData && templateType ? convertLinkDataToProperty({ linkData, templateType }) : undefined;

      //Get new review link before rendering a review template (except static ones).
      let newReviewLink: string | undefined;
      if (templateType === TemplateType_Slug.MANUAL_REVIEW_REQUEST && !template.templateId.includes('static')) {
        const {
          invitation: { invitationLink },
        } = await reviewInvitationMutate({ locationId: groupId, personId, templateId: template.templateId });
        newReviewLink = invitationLink || '';
        setDynamicModalContextSubtitle(invitationLink);
      }
      const bindingsList = convertBindingsDataToPropertyBindingsList({
        ...defaultBindingsData,
        ...linkDataPropertyBindings,
        ...propertyBindings,
        ...propertyBindingsData,
        ...(newReviewLink && { REVIEW_REQUEST_LINK: newReviewLink }),
      });
      setRenderData((prev) => {
        const newPayload = {
          templateTypeSlug: template.templateTypeSlug,
          templateString: template.templateString,
          bindingsList,
          timezone: locationData?.timezone ?? '',
          // TODO: get the locale dynamically once we have a way to do so
          locale: 'en_US',
          destinationType: DestinationType_Enum.SMS,
        };

        if (renderedTemplateQuery.data && isEqual(newPayload, prev?.payload)) {
          setBodyValue(renderedTemplateQuery.data.message.message);
        }

        return {
          payload: newPayload,
          templateId: template.templateId,
        };
      });
    },
    [
      selectedOrgId,
      defaultBindingsData,
      propertyBindings,
      locationData?.timezone,
      setRenderData,
      linkData,
      templateType,
      convertLinkDataToProperty,
      renderedTemplateQuery?.data,
      setBodyValue,
    ]
  );

  useEffect(() => {
    if (templateType) {
      const template = getDefaultTemplate({ type: templateType });
      if (template) setInitTemplate(template);
    }
  }, [groupId, templateType]);

  useEffect(() => {
    const hasPerson = personId ? !!personQuery.data : true;
    if (hasPerson && initTemplate) {
      setDraftToRenderedTemplate(initTemplate);
    }
  }, [initTemplate, personId, personQuery.data, JSON.stringify(linkData), JSON.stringify(propertyBindings)]);

  useEffect(() => {
    if (threadId) {
      onThreadChange?.({
        threadId,
        personPhone: selectedPersonPhone || '',
        locationPhone: selectedDepartmentQuery.data?.smsNumber?.number || messages?.[0]?.locationPhone || '',
        departmentId: selectedDepartmentId || '',
      });
    }
  }, [threadId]);

  // Thread context changes for custom phone number. metadata represents thread context
  const isThreadContextChangedWithCustomNumber = useMemo(
    () =>
      !!sendToCustomPhoneNumber &&
      !isGetAssociatedContactsLoading &&
      !associatedContacts.find((person) => person.personId === personId),
    [sendToCustomPhoneNumber, associatedContacts]
  );

  return {
    contentProps: {
      imageUploadProps,
      groupId,
      threadId: threadId ?? '',
      person: personQuery.data,
      selectedPersonPhone: selectedPersonPhone ?? '',
      onSelectPersonPhone,
      optedOut: !!selectedPersonPhone && smsPreference?.consented === false,
      selectedDepartmentId,
      onSelectDepartmentId,
      bodyValue,
      dynamicModalContextSubtitle,
      setBodyValue,
      onSend: handleSend,
      onSchedule: handleSchedule,
      messages,
      scheduledMessages,
      isLoadingFirstPage,
      hasOlderMessages,
      mediaQueries,
      fetchOlderMessages,
      threadMedia,
      removeMediaItem,
      personInThreadContext: isThreadContextChangedWithCustomNumber ? metadata?.person : undefined, // for when thread body loads messages from different contact (custom number) while the header and sending context doesn't change.
      isUnknownContactThread: isThreadContextChangedWithCustomNumber && messages.length === 0 && !!selectedPersonPhone,
    },
    sendMessage: handleSend,
    scheduleMessage: handleSchedule,
    setDraftToRenderedTemplate,
  };
};
