import { useCallback } from 'react';
import { RelatedType_Enum } from '@weave/schema-gen-ts/dist/schemas/email/shared/v1/enums.pb';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useQueryClient } from 'react-query';
import { DigitalFormsQueries as queryEndpointKeys } from '@frontend/api-digital-forms';
import { EmailSendV2 } from '@frontend/api-email-send';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useSendFormsStore, useSendFormsContext } from '../providers';
import { convertToHTML } from './utils/emailHelper';

type SendDocumentFn = () => Promise<void>;

export interface useSendDocumentResult {
  sendDocument: SendDocumentFn;
}

export const useSendDocument = (): useSendDocumentResult => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const { firstName, locationId, submissionToken, clearStore, getEmailPayload } = useSendFormsStore([
    'firstName',
    'locationId',
    'submissionToken',
    'clearStore',
    'getEmailPayload',
  ]);

  const queryClient = useQueryClient();

  const { mutate: sendEmail } = EmailSendV2.Mutations.useSendMutation();

  const sendDocument = useCallback<SendDocumentFn>(async () => {
    const { email, emailBody, emailSubject } = getEmailPayload();
    if (email.trim() === '') {
      return;
    }

    sendEmail(
      {
        locationId,
        email: {
          body: {
            rawHtml: convertToHTML(emailBody),
          },
          subject: emailSubject,
          emailType: MessageType_Enum.MESSAGING_MANUAL_FORM,
          relatedIds: [
            {
              type: RelatedType_Enum.RELATED_TYPE_FORM,
              id: submissionToken,
            },
          ],
          to: [
            {
              email: email,
              name: firstName,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          alert.success(t('Forms sent to {{firstName}} successfully', { firstName }));
          sendFormsModalControls.closeModal();
          queryClient.invalidateQueries(queryEndpointKeys.endpointKeys.submissionDetailsForPerson);
          clearStore(origin);
        },
        onError: () => {
          alert.error(t('Failed to send email'));
        },
      }
    );
  }, [sendEmail, getEmailPayload, locationId, submissionToken]);

  return { sendDocument };
};
