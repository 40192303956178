import { authnClient } from '@frontend/auth';
import { http } from '@frontend/fetch';
import { resetAllStores } from '@frontend/store';

// signOut will end in a browser redirect away from the current page, the promise will never be resolved
// if you need side effects performed make sure to do them before calling this method
export const signOut = async () => {
  http.clearMiddleware();
  resetAllStores();
  // this results in a browser redirect
  await authnClient.signOut();
};
