import { ReactChild, ReactNode, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import {
  DesktopNotificationDetails,
  ListNotificationsResponse,
  NotificationDoc,
} from '@weave/schema-gen-ts/dist/schemas/notification/v1/notification_api.pb';
import {
  NotificationType,
  NotificationType_index,
} from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { CallStatus, Direction as CallDirection } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import {
  CallRecordTagNotification,
  MissedCallNotification,
} from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/message.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import {
  doc,
  getDoc,
  deleteDoc,
  query,
  collection,
  where,
  writeBatch,
  onSnapshot,
  Firestore,
} from 'firebase/firestore';
import { onlyText } from 'react-children-utilities';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';
import { PaymentOrigin } from '@frontend/api-invoices';
import { NotificationQueries } from '@frontend/api-notifications';
import { PersonHelpers } from '@frontend/api-person';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { ActionableListRow, VariantRowProps } from '@frontend/components';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { usePaymentTypeMap } from '@frontend/payments-hooks';
import { formatPhoneNumber, isPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { useSettingsNavigate, allowedNotificationTypes } from '@frontend/settings-routing';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { encodeBase64, sentenceCase } from '@frontend/string';
import { pendo, sentry } from '@frontend/tracking';
import { PaymentsNotification } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  AutomatedMessagesIcon,
  CalendarIcon,
  ChatIcon,
  CheckIconSmall,
  Chip,
  ConfirmationModal,
  Dot,
  EditStatusIcon,
  FeedbackIcon,
  HelpIcon,
  IconButton,
  ListRow,
  MessageIcon,
  MessageUnreadIcon,
  PhoneCallIcon,
  PhoneIcon,
  PopoverMenu,
  PopoverMenuItem,
  ReviewsDashIcon,
  SettingsIcon,
  Text,
  TrashIcon,
  VoicemailIcon,
  useModalControl,
  usePopoverMenu,
  phone as formatPhone,
  styles,
  PopoverDialog,
  TextButton,
  CheckboxField,
  useForm,
  NakedUl,
  usePopoverDialog,
  useAlert,
} from '@frontend/design-system';
import { useFirestoreDbQuery } from './firestore';
import { NotificationInnerProps } from './notification-components/base';
import { converter, useNotificationContext } from './notification-provider';
import {
  TimeGroupedNotificationRecord,
  DATE_TIME_KEYS,
  DATE_TIME_LABELS,
  DateTimeKeys,
  getTitleFromPayload,
} from './notification-utils';
import { useNotificationFiltersStore } from './use-notification-filters';

dayjs.extend(calendar);

const NOTIFICATIONS_THAT_FIT_IN_VIEWPORT_WITHOUT_SCROLLING = 5;

const groupNotificationsByTime = (notifications: NotificationDoc[]) => {
  const acc: TimeGroupedNotificationRecord = {
    [DATE_TIME_KEYS.TODAY]: [],
    [DATE_TIME_KEYS.YESTERDAY]: [],
    [DATE_TIME_KEYS.THIS_WEEK]: [],
    [DATE_TIME_KEYS.LAST_WEEK]: [],
    [DATE_TIME_KEYS.EARLIER]: [],
  };
  notifications.forEach((notification) => {
    const isToday = dayjs().isSame(dayjs(notification.createdAt), 'day');
    const isYesterday = dayjs().subtract(1, 'day').isSame(dayjs(notification.createdAt), 'day');
    const isThisWeek = dayjs().isSame(dayjs(notification.createdAt), 'week');
    const isLastWeek = dayjs().subtract(1, 'week').isSame(dayjs(notification.createdAt), 'week');

    if (isToday) {
      acc[DATE_TIME_KEYS.TODAY].push(notification);
    } else if (isYesterday) {
      acc[DATE_TIME_KEYS.YESTERDAY].push(notification);
    } else if (isThisWeek) {
      acc[DATE_TIME_KEYS.THIS_WEEK].push(notification);
    } else if (isLastWeek) {
      acc[DATE_TIME_KEYS.LAST_WEEK].push(notification);
    } else {
      acc[DATE_TIME_KEYS.EARLIER].push(notification);
    }
  });

  return acc;
};
type AllowedNotificationTypes = typeof allowedNotificationTypes;
type FieldValue = { type: 'checkbox'; value?: boolean };
type Fields = { [key in AllowedNotificationTypes[number]['firestoreType']]: FieldValue };

export const BulkNotificationHistoryActions = ({ closeModal }: { closeModal?: () => void }) => {
  const { t } = useTranslation('base');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const firestoreDbQuery = useFirestoreDbQuery();
  const { setNotificationFilters, notificationFilters } = useNotificationFiltersStore(
    'setNotificationFilters',
    'notificationFilters'
  );

  const fieldProps = useForm({
    fields: allowedNotificationTypes.reduce((acc, cur) => {
      let val: FieldValue = { type: 'checkbox' };
      if (notificationFilters?.includes(cur.firestoreType)) {
        val = { ...val, value: true };
      }
      return { ...acc, [cur.firestoreType]: val };
    }, {} as Fields),
    fieldStateReducer: (state) => {
      setNotificationFilters(
        Object.entries(state)
          .filter((item) => item[1].value)
          .map((item) => item[0])
      );
      return null;
    },
  });

  const { notifications, maxLocationsSelected } = useNotificationContext();
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({
    placement: 'bottom-start',
  });
  const notificationFilterPopover = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-start',
  });
  const alert = useAlert();
  const deleteConfirmationModalControl = useModalControl();
  const user = getUser();
  const { selectedLocationIds } = useAppScopeStore();

  const handleBatchInteractions = (updateType: 'markAllAsRead' | 'deleteAll', db: Firestore) => {
    const conditions = [where('userId', '==', user?.userID), where('locationId', 'in', selectedLocationIds)];
    // for the markAllAsRead update type, we can limit the query to only the unread notifications
    if (updateType === 'markAllAsRead') {
      conditions.push(where('hasRead', '==', false));
    }
    const matchingNotificationsQuery = query(
      collection(db, 'user_notification').withConverter(converter),
      ...conditions
    );
    const unsubscribe = onSnapshot(matchingNotificationsQuery, (querySnapshot) => {
      const batch = writeBatch(db);
      if (querySnapshot.size === 0) {
        return;
      }
      querySnapshot.forEach((doc) => {
        if (updateType === 'deleteAll') {
          batch.delete(doc.ref);
        } else if (updateType === 'markAllAsRead') {
          batch.update(doc.ref, { hasRead: true } as Pick<NotificationDoc, 'hasRead'>);
        }
      });
      batch
        .commit()
        .then(() => {
          const message =
            updateType === 'deleteAll'
              ? t('Successfully deleted all notifications.')
              : t('Successfully marked all notifications as read.');
          alert.info(t(message));
        })
        .catch((e) => {
          const message =
            updateType === 'deleteAll'
              ? t('Failed to delete all notifications.')
              : t('Failed to mark all notifications as read.');
          sentry.error({ error: e, topic: 'notifications' });
          alert.error(t(message));
        })
        .finally(unsubscribe);
    });
  };

  return (
    <>
      <NakedUl css={{ display: 'flex' }}>
        <li>
          <IconButton
            {...notificationFilterPopover.getTriggerProps()}
            showLabelOnHover
            label={t('Filter Notifications')}
            disabled={maxLocationsSelected}
          >
            <Icon name='filter-alt' />
            {notificationFilters.length > 0 && (
              <Dot
                color='primary'
                css={{
                  position: 'absolute',
                  right: theme.spacing(0.5),
                  top: theme.spacing(0.5),
                  border: '2px solid white',
                  height: 12,
                  width: 12,
                }}
              />
            )}
          </IconButton>
        </li>
        <PopoverDialog
          {...notificationFilterPopover.getDialogProps()}
          css={css`
            padding: 0;
            width: 300px;
            border-radius: ${theme.borderRadius.medium};
            .filter-checkbox {
              padding: ${theme.spacing(1, 2)};
            }
          `}
        >
          <header
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.colors.neutral10}`,
              padding: theme.spacing(2),
              gap: theme.spacing(2),
            }}
          >
            <Text size='large' weight='bold'>
              {t('Filter Notifications')}
            </Text>
            <TextButton
              css={{ color: theme.colors.primary50 }}
              disabled={notificationFilters.length === 0}
              onClick={() => {
                setNotificationFilters([]);
                allowedNotificationTypes.forEach(({ firestoreType }) => {
                  fieldProps.getFieldProps(firestoreType).onChange({ name: firestoreType, value: false });
                });
              }}
            >
              {t('Clear All')}
            </TextButton>
          </header>
          <NakedUl css={{ margin: theme.spacing(1, 0, 2) }}>
            {allowedNotificationTypes?.map((notificationType) => (
              <li key={notificationType.id}>
                <CheckboxField
                  className='filter-checkbox'
                  {...fieldProps.getFieldProps(notificationType.firestoreType)}
                  label={notificationType.displayName}
                  labelPlacement='left'
                  trackingId={`notify-2.0-tray-filter-${notificationType.displayName}`}
                />
              </li>
            ))}
          </NakedUl>
        </PopoverDialog>
        <li>
          <IconButton {...getTriggerProps()} label={t('Bulk Actions')}>
            <Icon name='more' size={24} />
          </IconButton>
        </li>
        <PopoverMenu {...getMenuProps()}>
          <PopoverMenuItem
            Icon={CheckIconSmall}
            trackingId='notify-2.0-tray-mark-all-as-read'
            disabled={!notifications?.some((notification) => !notification.hasRead) || maxLocationsSelected}
            {...getItemProps({
              index: 0,
              onClick: () => {
                if (!notifications || !firestoreDbQuery.db) {
                  // no notifications to mark as read, we don't need to do anything
                  return;
                }
                handleBatchInteractions('markAllAsRead', firestoreDbQuery.db);
              },
            })}
          >
            {t('Mark all as read')}
          </PopoverMenuItem>
          <PopoverMenuItem
            Icon={SettingsIcon}
            trackingId='notify-2.0-tray-settings'
            {...getItemProps({
              index: 1,
              onClick: () => {
                settingsNavigate({ to: '/personal' });
                closeModal?.();
              },
            })}
          >
            {t('Notification settings')}
          </PopoverMenuItem>
          <PopoverMenuItem
            trackingId='notify-2.0-tray-delete-all'
            disabled={maxLocationsSelected || notifications?.length === 0}
            Icon={TrashIcon}
            {...getItemProps({
              index: 2,
              onClick: deleteConfirmationModalControl.openModal,
            })}
          >
            {t('Delete all notifications')}
          </PopoverMenuItem>
        </PopoverMenu>
      </NakedUl>
      <ConfirmationModal
        {...deleteConfirmationModalControl.modalProps}
        destructive
        message={t('Are you sure you want to delete all notifications?')}
        title={t('Delete Confirmation')}
        onConfirm={() => {
          if (!notifications || !firestoreDbQuery.db) {
            throw new Error('No notifications to delete');
          }
          handleBatchInteractions('deleteAll', firestoreDbQuery.db);
        }}
      />
    </>
  );
};

const NotificationIconsMap: Partial<Record<keyof typeof NotificationType, ReactNode>> = {
  [NotificationType.CHAT_MESSAGE]: <ChatIcon />,
  [NotificationType.FAX]: <FeedbackIcon />,
  [NotificationType.FOLLOW_UP]: <AutomatedMessagesIcon />,
  [NotificationType.FORMS]: <Icon name='forms' />,
  [NotificationType.MISSED_CALL]: <Icon name='phone-missed' />,
  [NotificationType.PHONE_CALL]: <PhoneCallIcon />,
  [NotificationType.REVIEWS]: <ReviewsDashIcon />,
  [NotificationType.REVIEW_COMMENT]: <EditStatusIcon />,
  [NotificationType.SCHEDULE_REQUEST]: <CalendarIcon />,
  [NotificationType.SMS]: <MessageUnreadIcon />,
  [NotificationType.SMS_TAG]: <MessageIcon />,
  [NotificationType.UNKNOWN]: <HelpIcon />,
  [NotificationType.VOICEMAIL]: <PhoneIcon />,
  [NotificationType.VOICEMAIL_TRANSCRIPTION]: <VoicemailIcon />,
  [NotificationType.PAYMENTS]: <Icon name='dollar-sign' />,
  [NotificationType.SMS_TAG_V2]: <Icon name='label' />,
  [NotificationType.CALL_RECORD_TAG]: <Icon name='label' />,
};

type ReviewsPayload = {
  author: string;
  locationCanReply: boolean;
  profileImageUrl: string;
  rating: number;
  review: string;
  reviewId: string;
  siteName: string;
};
type FormsPayload = {
  submittedAt: string;
  submissionId: string;
  lastName: string;
  firstName: string;
  documentName: string;
};
type SMSNotificationPayload = {
  PersonID: string;
  action: string;
  body: string;
  departmentId: string;
  firstName: string;
  lastName: string;
  locationId: string;
  patientNumber: string;
  phoneMobile: string;
  receivedAt: string;
  smsId: string;
  status: string;
  string: string;
  threadIds: string[];
  to: string;
  uuid: string;
  weaveNumber: string;
};

// fill this in later
type FaxPayload = object;

const HistoricalNotification = ({
  actions,
  createdAt,
  hasRead,
  icon,
  locationId,
  notificationAction,
  subtitle,
  title,
  trackingId,
  children,
}: Pick<NotificationInnerProps, 'actions'> & {
  createdAt: string;
  hasRead: boolean;
  icon: React.ReactNode;
  locationId: string;
  notificationAction?: () => void;
  subtitle: string;
  title: string;
  trackingId?: string;
  children?: ReactNode;
}) => {
  const { getMultiLocationName } = useLocationDataShallowStore('getMultiLocationName');
  const location = getMultiLocationName(locationId || '');
  const isUnread = !hasRead;
  let titleToString = onlyText(title);

  if (isPhoneNumber(titleToString)) {
    titleToString = formatPhone(titleToString);
  }

  return (
    <ActionableListRow
      trackingId={trackingId}
      css={css`
        &:hover .location-chip {
          background-color: ${theme.colors.white};
        }
      `}
      {...(notificationAction && {
        onClick: () => notificationAction(),
      })}
      Lead={
        <ListRow.Lead
          css={css`
            position: relative;
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Dot
            color='primary'
            css={[
              css`
                opacity: 0;
                left: -35px;
                transition: all 0.2s ease-in-out;
              `,
              isUnread &&
                css`
                  transition: all 0.2s ease-in-out;
                  left: -23px;
                  opacity: 1;
                `,
            ]}
          />
          {icon}
        </ListRow.Lead>
      }
      Title={
        <ListRow.Content.Title css={{ fontWeight: isUnread ? theme.font.weight.bold : theme.font.weight.inherit }}>
          {titleToString}
        </ListRow.Content.Title>
      }
      Content={
        <>
          {children}
          {location ? (
            <ListRow.Content.Subtitle css={{ paddingBottom: theme.spacing(0.5) }}>
              <Chip.Location className='location-chip'>{location}</Chip.Location>
            </ListRow.Content.Subtitle>
          ) : null}
          <ListRow.Content.TertiaryTitle
            css={[
              css`
                font-size: ${theme.fontSize(14)};
              `,
              styles.multilineTruncate(2),
            ]}
          >
            {subtitle}
          </ListRow.Content.TertiaryTitle>
        </>
      }
      timestamp={dayjs(createdAt).toString()}
      actions={actions}
    />
  );
};

const HistoricalSmsNotification = ({ notification, ...props }: HistoricalNotificationType<NotificationType.SMS>) => {
  const { onClick } = ActionsUI.actions.useMessageAction();
  const notificationTrayContext = useNotificationContext();
  const notificationAction = () => {
    notificationTrayContext.markThreadNotificationsAsRead(
      notification.alert.payload?.threadIds?.[0] || '',
      notification.locationId
    );
    props.closeModal();
    onClick({
      threadId: notification.alert.payload?.threadIds?.[0],
      threadGroupId: notification.alert.payload?.locationId,
      groupIds: notification.alert.payload?.locationId ? [notification.alert.payload?.locationId] : undefined,
      personId: notification.alert.payload?.PersonID,
      personPhone: notification.alert.payload?.phoneMobile || notification.alert.payload?.patientNumber,
      departmentId: notification.alert.payload?.departmentId,
    });
  };
  return (
    <HistoricalNotification
      trackingId='notify-2.0-tray-alert-sms'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={notification.alert?.payload?.body || ''}
      title={
        getTitleFromPayload({
          number: notification?.alert?.payload?.phoneMobile || '',
          firstName: notification?.alert?.payload?.firstName || '',
          lastName: notification?.alert?.payload?.lastName || '',
        }) || ''
      }
      {...props}
    />
  );
};
const HistoricalFormsNotification = ({
  notification,
  ...props
}: HistoricalNotificationType<NotificationType.FORMS>) => {
  const navigate = useNavigate();
  const notificationTrayContext = useNotificationContext();
  const notificationAction = () => {
    notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    navigate({
      to: `/forms/submissions/inbox/all/${notification.alert.payload?.submissionId}?locationId=${notification.locationId}`,
    });
    props.closeModal();
  };

  return (
    <HistoricalNotification
      trackingId='notify-2.0-tray-alert-forms'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={notification.alert.body || ''}
      title={notification.alert.title || ''}
      {...props}
    />
  );
};
const HistoricalFaxNotification = ({ notification, ...props }: HistoricalNotificationType<NotificationType.FAX>) => {
  const navigate = useNavigate();
  const notificationTrayContext = useNotificationContext();
  const notificationAction = () => {
    navigate({ to: `/portal/fax` });
    notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    props.closeModal();
  };

  return (
    <HistoricalNotification
      trackingId='notify-2.0-tray-alert-fax'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={notification.alert.body || ''}
      title={notification.alert.title || ''}
      {...props}
    />
  );
};
const HistoricalReviewsNotification = ({
  notification,
  ...props
}:
  | HistoricalNotificationType<NotificationType.REVIEWS>
  | HistoricalNotificationType<NotificationType.REVIEW_COMMENT>) => {
  const navigate = useNavigate();
  const notificationTrayContext = useNotificationContext();
  const alert = useAlert();
  const { t } = useTranslation('notifications');

  const siteName = notification.alert?.payload?.siteName || '';
  const sanitizedSiteName = ['google', 'facebook', 'private'].includes(siteName) ? siteName : '';
  const notificationAction = () => {
    notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    if (sanitizedSiteName) {
      navigate({
        to: `/reviews/${sanitizedSiteName}/${encodeBase64(
          notification.alert?.payload?.reviewId || ''
        )}?type=${sanitizedSiteName}`,
      });
      props.closeModal();
    } else {
      alert.error(t('Error navigating to reviews'));
    }
  };

  return (
    <HistoricalNotification
      trackingId='notify-2.0-tray-alert-reviews'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={notification.alert.payload?.review || ''}
      title={notification.alert.title || ''}
      {...props}
    />
  );
};
const HistoricalPaymentsNotification = ({
  notification,
  ...props
}: HistoricalNotificationType<NotificationType.PAYMENTS>) => {
  const navigate = useNavigate();
  const { t } = useTranslation('notifications');
  const notificationTrayContext = useNotificationContext();
  const { paymentTypeMap } = usePaymentTypeMap();
  const notificationAction = () => {
    notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    navigate({ to: `/payments/invoices/${notification.alert.payload?.invoiceId}` });
    props.closeModal();
  };
  const paymentMode =
    paymentTypeMap[notification.alert.payload?.origin as PaymentOrigin] ?? notification.alert.payload?.origin;
  const subTitle = sentenceCase(t('{{paymentMode}} payment made', { paymentMode }));

  return (
    <HistoricalNotification
      trackingId='notify-2.0-tray-alert-payments'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={subTitle}
      title={notification.alert.payload?.patientName || ''}
      {...props}
    />
  );
};
const HistoricalSMSTagNotification = ({
  notification,
  ...props
}: HistoricalNotificationType<NotificationType.SMS_TAG_V2>) => {
  const { t } = useTranslation('notifications');
  const { navigateToThread } = useInboxNavigate();
  const notificationTrayContext = useNotificationContext();
  const notificationAction = () => {
    if (!notification.hasRead) notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    if (!notification.alert.payload) return;
    navigateToThread({
      threadId: notification.alert.payload.threadId,
      groupId: notification.alert.payload.groupId,
      personId: notification.alert.payload.personId,
      personPhone: notification.alert.payload.personPhone,
      isNew: false,
      smsId: notification.alert.payload.smsId,
      smsCreatedAt: notification.alert.payload.smsCreatedAt,
    });
    props.closeModal();
  };

  return (
    <HistoricalNotification
      trackingId='sms-tag-notification-center-item'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={
        notification.alert.payload?.smsBody ||
        t('{{count}} attachments', { count: notification.alert.payload?.numMedia ?? 0 })
      }
      title={
        PersonHelpers.getFullName({
          FirstName: notification.alert.payload?.personFirstName,
          LastName: notification.alert.payload?.personLastName,
          PreferredName: notification.alert.payload?.personPreferredName,
        }) || formatPhoneNumber(notification.alert.payload?.personPhone || '')
      }
      {...props}
    >
      {notification.alert.payload && (
        <Chip.Tag
          color={notification.alert.payload?.tagColor}
          css={{
            width: 'min-content',
            justifyContent: 'start',
          }}
        >
          {notification.alert.payload?.tagName}
        </Chip.Tag>
      )}
    </HistoricalNotification>
  );
};

// hook to be used by phones component only
const usePhoneNotificationAction = ({
  notification,
  callRecordId,
  locationId,
  startedAt,
  ...props
}: HistoricalNotificationType<NotificationType.MISSED_CALL | NotificationType.CALL_RECORD_TAG> & {
  callRecordId: string;
  locationId: string;
  startedAt: string;
}) => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const notificationTrayContext = useNotificationContext();
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { selectedLocationIds } = useAppScopeStore();

  const hasAccesstoCallRecordingMap = useMemo(() => {
    return selectedLocationIds.reduce((accessMap, locationId) => {
      accessMap[locationId] = hasSchemaACL(locationId, Permission.CALL_RECORDING_READ);
      return accessMap;
    }, {} as Record<string, boolean>);
  }, [selectedLocationIds]);

  const hasAccesstoCallRecording = useMemo(() => {
    return Object.values(hasAccesstoCallRecordingMap).some((hasAccess) => hasAccess);
  }, [hasAccesstoCallRecordingMap]);

  const notificationAction = async () => {
    if (!notification.hasRead) {
      notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
    }
    if (!notification.alert.payload) return;

    const { payload: params } = notification.alert;
    props.closeModal();
    try {
      const callRecordData = await queryClient.fetchQuery({
        queryKey: [params.locationId, params.callRecordId],
        queryFn: () =>
          PhoneCallsApi.getCallRecord({
            callRecordId: params.callRecordId,
            locationId: params.locationId,
            startTime: params.startedAt,
            endTime: params.startedAt,
            includeCallRecording: true,
          }),
        staleTime: 0,
      });

      setShow(true, 'callDetails', {
        rows: callRecordData.record ? [{ ...callRecordData.record, tags: [] }] : [],
        currentRowId: callRecordData?.record?.id,
        instanceId: null,
        hasAccessToRecordings: hasAccesstoCallRecording,
        toggleRowSelected: () => {},
        callDetailsQueryKey: [],
        sectionFocus: 'details',
      });
    } catch (error) {
      alert.error('Error fetching call record data');
    }
  };

  return { notificationAction };
};

const HistoricalCallRecordTagNotification = ({
  notification,
  ...props
}: HistoricalNotificationType<NotificationType.CALL_RECORD_TAG>) => {
  const { getMultiLocationName } = useLocationDataShallowStore('getMultiLocationName');
  const location = getMultiLocationName(notification.locationId || '');
  const { callerNumber, contactName, direction, startedAt, status, tagsPayload, callRecordId, locationId } =
    notification.alert.payload as CallRecordTagNotification;

  const { notificationAction } = usePhoneNotificationAction({
    notification,
    callRecordId,
    startedAt,
    locationId,
    ...props,
  });
  return (
    <HistoricalNotification
      trackingId='call-record-tag-notification-center-item'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId=''
      notificationAction={notificationAction}
      subtitle=''
      title={contactName || formatPhoneNumber(callerNumber) || 'Unknown'}
      {...props}
    >
      {notification.alert.payload && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(1)};
          `}
        >
          <Chip.Tag
            color={tagsPayload?.[0].color}
            css={{
              width: 'min-content',
              justifyContent: 'start',
            }}
          >
            {tagsPayload?.[0].name}
          </Chip.Tag>
          <Chip.Location className='location-chip'>{location}</Chip.Location>
          <div
            css={css`
              display: flex;
              gap: 4px;
              align-items: center;
            `}
          >
            {direction === CallDirection.DIRECTION_OUTBOUND && <Icon name='phone-outgoing' size={16} />}
            {direction === CallDirection.DIRECTION_INBOUND && status !== CallStatus.CALL_STATUS_MISSED && (
              <Icon name='phone-incoming' size={16} />
            )}
            {direction === CallDirection.DIRECTION_INBOUND && status === CallStatus.CALL_STATUS_MISSED && (
              <Icon name='phone-missed' size={16} />
            )}

            <Text size='medium'>{dayjs(startedAt).format('MMM DD YYYY, hh:mm A')}</Text>
          </div>
        </div>
      )}
    </HistoricalNotification>
  );
};

const HistoricalMissedCallNotification = ({
  notification,
  ...props
}: HistoricalNotificationType<NotificationType.MISSED_CALL>) => {
  const { t } = useTranslation('notifications');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isMissedCallNotificationFeatureEnabled = getFeatureFlagValue('missed-call-notification-feature-enabled');

  const { callerNumber, personContact, startedAt, callRecordId, locationId } = notification.alert
    .payload as MissedCallNotification;

  const { notificationAction } = usePhoneNotificationAction({
    notification,
    callRecordId,
    startedAt,
    locationId,
    ...props,
  });

  if (!isMissedCallNotificationFeatureEnabled) {
    return;
  }

  return (
    <HistoricalNotification
      trackingId='missed-call-notification-center-item'
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      notificationAction={notificationAction}
      subtitle={t('Missed Call')}
      title={personContact || formatPhoneNumber(callerNumber) || t('Unknown')}
      {...props}
    ></HistoricalNotification>
  );
};

// we can add more types here as we add more notifications
type AllowedHistoricalNotificationPayloads = {
  [NotificationType.FAX]: FaxPayload;
  [NotificationType.FORMS]: FormsPayload;
  [NotificationType.REVIEWS]: ReviewsPayload;
  [NotificationType.REVIEW_COMMENT]: ReviewsPayload;
  [NotificationType.SMS]: SMSNotificationPayload;
  [NotificationType.PAYMENTS]: PaymentsNotification['payload'];
  [NotificationType.SMS_TAG_V2]: DesktopNotificationDetails['desktopSmsTagV2Alert'];
  [NotificationType.CALL_RECORD_TAG]: DesktopNotificationDetails['callRecordTagAlert'];
  [NotificationType.MISSED_CALL]: DesktopNotificationDetails['missedCallAlert'];
};

type UpdatedNotification = {
  [K in keyof NotificationDoc]: K extends 'type' ? keyof AllowedHistoricalNotificationPayloads : NotificationDoc[K];
};

type UpdatedAlert<T extends keyof AllowedHistoricalNotificationPayloads> = {
  [K in keyof NotificationDoc['alert']]: K extends 'payload'
    ? AllowedHistoricalNotificationPayloads[T]
    : NotificationDoc['alert'][K];
};

type UpdatedNotificationAlertType<T extends UpdatedNotification['type']> = {
  [K in keyof UpdatedNotification]: K extends 'alert' ? UpdatedAlert<T> : K extends 'type' ? T : UpdatedNotification[K];
};

type UpdatedNotificationType<
  T extends UpdatedNotification,
  P extends UpdatedNotification['type'] = T['type']
> = P extends P ? UpdatedNotificationAlertType<P> : never;

// because the shape of `payload` is unique to each notification type,
// this type is essentially a way to conditionally get type safety
// for alert.payload based on notification.type
type NotificationComponentProps = UpdatedNotificationType<UpdatedNotification>;

type HistoricalNotificationType<T extends keyof AllowedHistoricalNotificationPayloads> = {
  notification: UpdatedNotificationAlertType<T>;
  actions: Required<VariantRowProps>['actions'];
  icon: React.ReactNode;
  closeModal: () => void;
};
// if we don't have an action defined for a notification, we still want to show it
const FallbackNotification = ({ notification, ...props }: HistoricalNotificationType<any>) => {
  return (
    <HistoricalNotification
      createdAt={notification.createdAt}
      hasRead={notification.hasRead}
      locationId={notification.locationId || ''}
      subtitle={notification.alert.body || ''}
      title={notification.alert.title || ''}
      {...props}
    />
  );
};
const NotificationComponentSwitcher = ({
  notification,
  ...props
}: {
  notification: NotificationComponentProps;
  closeModal: () => void;
  icon: React.ReactNode;
  actions: Required<VariantRowProps>['actions'];
}) => {
  switch (notification.type) {
    case NotificationType.SMS:
      return <HistoricalSmsNotification notification={notification} {...props} />;
    case NotificationType.REVIEWS:
      return <HistoricalReviewsNotification notification={notification} {...props} />;
    case NotificationType.REVIEW_COMMENT:
      return <HistoricalReviewsNotification notification={notification} {...props} />;
    case NotificationType.FORMS:
      return <HistoricalFormsNotification notification={notification} {...props} />;
    case NotificationType.FAX:
      return <HistoricalFaxNotification notification={notification} {...props} />;
    case NotificationType.PAYMENTS:
      return <HistoricalPaymentsNotification notification={notification} {...props} />;
    case NotificationType.SMS_TAG_V2:
      return <HistoricalSMSTagNotification notification={notification} {...props} />;
    case NotificationType.CALL_RECORD_TAG:
      return <HistoricalCallRecordTagNotification notification={notification} {...props} />;
    case NotificationType.MISSED_CALL:
      return <HistoricalMissedCallNotification notification={notification} {...props} />;
    default: {
      const _exhaustive: never = notification;
      console.warn('Tried to render a notification component with no matching signature', _exhaustive);
      return <FallbackNotification notification={notification} {...props} />;
    }
  }
};

export const NotificationList = ({
  notifications,
  closeModal,
}: {
  notifications: ListNotificationsResponse['notifications'];
  closeModal?: () => void;
}) => {
  const content: ReactChild[] = [];
  const user = getUser();
  const { inView, ref } = useInView();
  const [hasBeenTracked, setHasBeenTracked] = useState(false);
  const { selectedLocationIds } = useAppScopeStore();

  const showTrackElement = notifications && notifications.length > NOTIFICATIONS_THAT_FIT_IN_VIEWPORT_WITHOUT_SCROLLING;

  useEffect(() => {
    if (inView && !hasBeenTracked) {
      // avoid tracking repeatedly if user is scrolling up and down at the bottom of the list
      setHasBeenTracked(true);
      pendo.track('2.0-notification-tray-reached-bottom-of-list', {
        totalNotifications: notifications?.length || '',
        visitorId: user?.userID || '',
        locationIds: selectedLocationIds,
        viewportHeight: window.innerHeight,
        userAgent: navigator.userAgent,
        location: window.location.href,
      });
    }
  }, [inView]);

  const groupedNotifications = groupNotificationsByTime(notifications);

  Object.entries(groupedNotifications).forEach(([key, value]) => {
    if (value.length > 0) {
      content.push(
        <div key={key}>
          <Text size='small' color='light' css={{ margin: theme.spacing(2, 4) }}>
            {DATE_TIME_LABELS[key as DateTimeKeys]}
          </Text>
          {value.map((notification: NotificationDoc) => (
            <NotificationWithActions key={notification.id} notification={notification} closeModal={closeModal} />
          ))}
        </div>
      );
    }
  });

  return (
    <div style={{ overflowY: 'auto' }}>
      {content}
      {showTrackElement && <div ref={ref} />}
    </div>
  );
};

const NotificationWithActions = ({
  notification,
  closeModal,
}: {
  notification: NotificationDoc;
  closeModal?: () => void;
}) => {
  const firestoreDbQuery = useFirestoreDbQuery();
  const notificationTrayContext = useNotificationContext();
  const { t } = useTranslation('notifications');
  const alert = useAlert();
  const notificationSettings = NotificationQueries.useNotificationSettingsQuery({
    locationId: notification.locationId,
  });

  const notificationSettingsMutation = NotificationQueries.useNotificationSettingsMutation({
    onSuccess: (_, mutateArgs) => {
      const notification = notificationSettings.data?.find((notification) => notification.id === mutateArgs.id);
      alert.success(
        t(`Successfully turned off {{notificationName}} notifications.`, { notificationName: notification?.name })
      );
      notificationSettings.refetch();
    },
  });
  // map the firestore notification type to the response body on usernotificationsettings
  const foundNotification = notificationSettings?.data?.find((n) => {
    if (!n.notificationType) return;
    return notification.type === (NotificationType_index[n.notificationType] as unknown as NotificationType);
  });
  const notificationIsOn = foundNotification?.sendNotification;

  const notificationTurnOffMessage = useMemo(() => {
    switch (notification?.type) {
      case NotificationType.PAYMENTS: {
        const notificationName = foundNotification?.name ?? notification?.type;
        return t('Turn off these {{notificationName}} notifications', { notificationName });
      }
      default:
        return t('Turn off these notifications');
    }
  }, [notification?.type, foundNotification]);

  return (
    <NotificationComponentSwitcher
      closeModal={() => closeModal?.()}
      notification={notification as NotificationComponentProps}
      icon={NotificationIconsMap[notification.type]}
      actions={[
        {
          label: notification.hasRead ? t('Mark unread') : t('Mark as read'),
          action: () => {
            notificationTrayContext.toggleNotificationAsRead(notification.id, notification.hasRead);
          },
          Icon: CheckIconSmall,
        },
        ...(notificationIsOn
          ? [
              {
                label: notificationTurnOffMessage,
                Icon: SettingsIcon,
                action: () => {
                  notificationSettingsMutation.mutate({
                    id: foundNotification.notificationType,
                    value: false,
                  });
                },
              },
            ]
          : []),
        {
          label: t('Delete this notification'),
          Icon: TrashIcon,
          action: async () => {
            if (!notification.id || !firestoreDbQuery.db) return;
            const docToDeleteRef = doc(firestoreDbQuery?.db, 'user_notification', notification.id);
            const docSnap = await getDoc(docToDeleteRef);

            if (!docSnap.exists()) {
              console.error('Unable to find document');
            } else {
              await deleteDoc(docToDeleteRef);
            }
          },
        },
      ]}
    />
  );
};
