import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { useFetchBTRoiScheduleRate } from '../hooks';

export const ScheduleRateBanner = ({
  difference,
  highestConversion,
}: ReturnType<typeof useFetchBTRoiScheduleRate>['scheduleRate']) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.comparisonWrapper}>
      <Icon color='success' name='award' />
      <Text size='medium'>
        {difference < 0 ? (
          <Trans t={t} value={Math.abs(difference)}>
            Your Overall Appointment Schedule Rate is{' '}
            <strong>{{ value: formatters.percent.appendPercent(Math.abs(difference)) }} higher</strong> than the Weave
            Average Appointment Schedule Rate
          </Trans>
        ) : (
          <Trans
            t={t}
            values={{
              ...highestConversion,
              difference: formatters.percent.appendPercent(Math.abs(difference)),
            }}
          >
            Your Appointment Schedule Rate for the week of {{ timePeriod: highestConversion.timePeriod }} was{' '}
            <strong>
              {{
                difference: formatters.percent.appendPercent(Math.abs(difference)),
              }}{' '}
              higher
            </strong>{' '}
            than the Weave Average Appointment Schedule Rate, making it the highest conversion{' '}
            {{ timeType: highestConversion.timeType }}
          </Trans>
        )}
      </Text>
    </div>
  );
};

const styles = {
  comparisonWrapper: css`
    align-items: center;
    background-color: ${theme.colors.success5};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.success50};
    display: flex;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
  `,
};
