import { FormPacketSelectorHooks } from '@frontend/form-packet-selector';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useSendFormsStore } from '../providers';

interface GenerateLinkFnResult {
  success: boolean;
}

type GenerateLinkFn = (deliveryMethod: string) => Promise<GenerateLinkFnResult>;

interface UseGeneratedLinkResults {
  generateLink: GenerateLinkFn;
}

export const useGeneratedLink = (): UseGeneratedLinkResults => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const {
    personId,
    appointmentId,
    setIsGeneratingSolictedLink,
    setSolicitedLink,
    setSubmissionToken,
    locationId,
    getGenerateLinkPayload,
  } = useSendFormsStore([
    'personId',
    'appointmentId',
    'setIsGeneratingSolictedLink',
    'setSolicitedLink',
    'setSubmissionToken',
    'locationId',
    'getGenerateLinkPayload',
  ]);
  const { generateSolicitedLink } = FormPacketSelectorHooks.useSolicitedLink({
    locationId,
    personId,
  });

  async function generateLink(deliveryMethod: string): Promise<GenerateLinkFnResult> {
    const { acceptedFiles, formOrPacketToSend } = getGenerateLinkPayload();
    if (!formOrPacketToSend || !formOrPacketToSend.id) {
      setSolicitedLink('');
      setIsGeneratingSolictedLink(false);
      return { success: false };
    }

    setIsGeneratingSolictedLink(true);
    try {
      const response = await generateSolicitedLink({
        attachments: acceptedFiles,
        appointmentId,
        formOrPacketToSend,
        deliveryMethod,
      });

      if (!response.linkToSend) {
        setSolicitedLink('');
        setSubmissionToken('');
        setIsGeneratingSolictedLink(false);
        return { success: false };
      }

      setSolicitedLink(response.linkToSend);
      setSubmissionToken(response.submissionToken);
      setIsGeneratingSolictedLink(false);
      return { success: true };
    } catch (error) {
      alert.error(t('Error occurred while generating the form link. {{error}}', { error }));
      return { success: false };
    }
  }

  return { generateLink };
};
