import { EmptyProviderType } from './types';
import { DeptPhoneNumberTypes } from '.';

// split the numbers by its voice/sms support capability
export const splitNumbersByCapability = (phoneNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[]) => {
  const availableSMSOnlyNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
  const availableVoiceOnlyNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
  const availableVoiceSMSNumbers: DeptPhoneNumberTypes.DepartmentPhoneNumberType[] = [];
  phoneNumbers.forEach((number) => {
    const smsSupported = number.smsConfig.provider !== EmptyProviderType.SMS_PROVIDER_UNSPECIFIED;
    const voiceSupported = number.voiceConfig?.provider !== EmptyProviderType.VOICE_PROVIDER_UNSPECIFIED;
    if (smsSupported && voiceSupported) {
      availableVoiceSMSNumbers.push(number);
    } else if (smsSupported) {
      availableSMSOnlyNumbers.push(number);
    } else if (voiceSupported) {
      availableVoiceOnlyNumbers.push(number);
    }
  });
  return {
    availableSMSOnlyNumbers,
    availableVoiceOnlyNumbers,
    availableVoiceSMSNumbers,
  };
};
