import {
  createContext,
  Dispatch,
  FC,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { DepartmentScheduleResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/schedule.pb';
import { cloneDeep } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { DepartmentsApi } from '@frontend/api-departments';
import { InstructionsTypes } from '@frontend/api-phone-tree';
import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { VoiceMailboxApi } from '@frontend/api-voicemail-boxes';
import { MaintenanceMode } from '@frontend/empty-states#svg';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, SpinningLoader, Tabs, Text, TextLink, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { defaultMediaID } from '../../utils/phone-utils';
import { MediaPickerOptionValues } from '../media-picker';
import { OfficeHoursPortalDepartments } from '../office-hours/office-hours.component.old';
import { OverrideSettingsContainer } from './phone-routing-section/routing-section/override-settings';
import { RoutingRingForm } from './phone-routing-section/routing-section/routing-callgroup-form';
import { RoutingCallQueueForm } from './phone-routing-section/routing-section/routing-callqueue-form';
import { defaultInstruction } from './phone-routing-section/routing-section/routing-default-instruction';
import { useUpdateScheduleWithRouting } from './phone-routing-section/routing-section/routing-hooks';
import { RoutingOtherOptionForm } from './phone-routing-section/routing-section/routing-other-form';
import { RoutingPhoneTreeForm } from './phone-routing-section/routing-section/routing-phone-tree-form';
import {
  CallGroupInstructionSet,
  CallQueueInstructionSet,
  getDataReducerInitialState,
  InstructionPayload,
  InstructionReducer,
  IVRMenuInstructionSet,
  OtherInstructionSet,
  RoutingPayload,
} from './phone-routing-section/routing-section/routing-reducers';

type ContextType = { routingData: RoutingPayload; setRoutingData: Dispatch<SetStateAction<RoutingPayload>> };
export const RoutingSettingsContext = createContext<ContextType>({} as ContextType);

const RoutingSettingsProvider = ({ children, initialState }: { children: ReactNode; initialState: RoutingPayload }) => {
  const [routingData, setRoutingData] = useState<RoutingPayload>(initialState);

  return (
    <RoutingSettingsContext.Provider value={{ routingData, setRoutingData }}>
      {children}
    </RoutingSettingsContext.Provider>
  );
};

type Props = {
  departmentId: string | undefined;
};

export const PhoneRoutingSettings: FC<React.PropsWithChildren<Props>> = ({ departmentId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phoneRoutingData, setPhoneRoutingData] = useState<RoutingPayload>(getDataReducerInitialState);
  const deptId: string = departmentId ?? '';

  const { t } = useTranslation('phone');
  const { navigate } = useSettingsNavigate();

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasCallRoutesFlag = getFeatureFlagValue('call-routes-settings');

  const { data: vmOverride, isLoading: isVMOLoading } = useLocalizedQuery({
    queryKey: queryKeys.departmentVMOverride(deptId),
    queryFn: () => DepartmentsApi.getVoicemailOverride({ departmentId }),
    refetchOnWindowFocus: true,
  });

  const { data: fwdOverride, isLoading: isFwdOverrideLoading } = useLocalizedQuery({
    queryKey: queryKeys.departmentForwardingOverride(deptId),
    queryFn: () => DepartmentsApi.getCallForwardingOverride({ departmentId }),
  });

  const { data: schedules, isLoading: loadingSchedules } = useLocalizedQuery({
    queryKey: queryKeys.listDepartmentSchedules(deptId),
    queryFn: ({ queryKey }) => {
      return ScheduleApi.listSchedulesByDepartment({ departmentId: queryKey[3] }).then<
        ScheduleTypes.ScheduleWithRouting[]
      >((schedulesResponse: any) => schedulesResponse.schedules);
    },
  });

  useEffect(() => {
    setIsLoading(isVMOLoading || isFwdOverrideLoading || loadingSchedules);
  }, [isVMOLoading, isFwdOverrideLoading, loadingSchedules]);

  useEffect(() => {
    setPhoneRoutingData((prevData) => ({
      ...prevData,
      voicemailOverride: {
        enabled: vmOverride?.vmoSetting?.vmoEnabled ?? false,
        mediaId: vmOverride?.vmoSetting?.mediaItemId || '',
      },
      forwardingOverride: {
        enabled: fwdOverride?.forwardingEnabled ?? false,
        forwardingNumberID: fwdOverride?.forwardingNumberId ?? '',
      },
      schedules: schedules ?? [],
      changedSchedules: schedules ?? [],
      currentScheduleId: '',
    }));
  }, [vmOverride, fwdOverride, schedules]);

  if (hasCallRoutesFlag) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <MaintenanceMode
          css={css`
            height: 500px;
          `}
        />
        <Trans t={t}>
          <Text color='subdued'>
            This content can now be accessed in the new{' '}
            <TextLink onClick={() => navigate({ to: '/phone/call-routes' })}>Call Routes</TextLink> experience.
          </Text>
        </Trans>
      </div>
    );
  }

  if (!isLoading) {
    return (
      <>
        <RoutingSettingsProvider initialState={phoneRoutingData}>
          <RoutingContainer departmentId={deptId} phoneRoutingData={phoneRoutingData} />
        </RoutingSettingsProvider>
      </>
    );
  }

  return <SpinningLoader />;
};

export const RoutingContainer = ({
  departmentId,
  phoneRoutingData,
}: {
  departmentId: string;
  phoneRoutingData: RoutingPayload;
}) => {
  const alerts = useAlert();
  const { routingData, setRoutingData } = useContext(RoutingSettingsContext);
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const [newBreak, setNewBreak] = useState<DepartmentScheduleResponse>();
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  useEffect(() => {
    setRoutingData(phoneRoutingData);
  }, [phoneRoutingData]);

  const { mutate: updateSchedule } = useUpdateScheduleWithRouting(departmentId, {
    onSuccess: () => {
      alerts.success('Saved Routing Settings');
      queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentSchedules(departmentId)]);
      queryClient.invalidateQueries(queryKeys.listDepartments());
      /// invalidate the queries used in the departments page
      queryClient.invalidateQueries([locationId, ...queryKeys.listDepartmentsWithSchedules()]);
    },
    onError: () => {
      alerts.error(`Routing Update Failed`);
    },
  });

  const onSave = () => {
    const currentSchedule = routingData.changedSchedules?.find(
      (item) => item.scheduleId === routingData.currentScheduleId
    );
    if (!!currentSchedule) {
      updateSchedule(currentSchedule);
    }
  };

  return (
    <div>
      <OfficeHoursPortalDepartments departmentId={departmentId} setNewBreak={setNewBreak} />
      <OverrideSettingsContainer departmentId={departmentId} />
      <RoutingSettingsContainer newBreak={newBreak} onNewBreakClick={setNewBreak} />
      <div>
        <PrimaryButton
          size='large'
          css={css`
            width: 200px;
            margin-top: ${theme.spacing(2)};
          `}
          onClick={onSave}
          trackingId='phone-portal-mainLine-btn-saveRouting'
        >
          {t('Save Changes')}
        </PrimaryButton>
      </div>
    </div>
  );
};

// To manipulate media values in intermediate stage for VMO dropdown
export const getMediaValues = (mediaID: string) => {
  if (mediaID === defaultMediaID || mediaID === MediaPickerOptionValues.DEFAULT_GREETING) {
    return '';
  }
  return mediaID;
};

enum TabTypes {
  CallGroup = 'CallGroup',
  PhoneTree = 'PhoneTree',
  CallQueue = 'CallQueue',
  Other = 'Other',
}

export const RoutingSettingsContainer = ({
  newBreak,
  onNewBreakClick,
}: {
  newBreak?: DepartmentScheduleResponse;
  onNewBreakClick?: (value: React.SetStateAction<DepartmentScheduleResponse | undefined>) => void;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  const { routingData, setRoutingData } = useContext(RoutingSettingsContext);
  const schedules = routingData.schedules;
  const [currentScheduleId, setCurrentScheduleId] = useState(
    schedules?.find((schedule) => schedule.type === ScheduleTypes.ScheduleType.Open)?.scheduleId || ''
  );

  const currentSchedule = useMemo(() => {
    return schedules?.find((schedule) => schedule.scheduleId === currentScheduleId);
  }, [currentScheduleId]);

  useEffect(() => {
    if (!currentScheduleId || !schedules.map((sched) => sched.scheduleId).includes(currentScheduleId)) {
      setCurrentScheduleId(
        schedules?.find((schedule) => schedule.type === ScheduleTypes.ScheduleType.Open)?.scheduleId ?? ''
      );
    }
  }, [schedules?.length]);

  const sortedSchedules = useMemo(
    () =>
      schedules.sort((a, b) => {
        if (a.type === ScheduleTypes.ScheduleType.Open) {
          return -1;
        } else if (a.type === ScheduleTypes.ScheduleType.Closed) {
          return 0;
        } else {
          //sort them by how many instances per week they occur
          return b.rules.length - a.rules.length;
        }
      }),
    [schedules]
  );

  const onTabChange = (id: string) => {
    setCurrentScheduleId(id);
    if (!!newBreak && !!onNewBreakClick) onNewBreakClick(undefined);
  };

  const onScheduleUpdate = (activeSchedule: ScheduleTypes.ScheduleWithRouting) => {
    const newSchedules = cloneDeep(schedules) as ScheduleTypes.ScheduleWithRouting[];
    const currentScheduleIndex = schedules.findIndex((item) => item.scheduleId === currentScheduleId);
    newSchedules[currentScheduleIndex] = activeSchedule;
    const data: RoutingPayload = {
      ...routingData,
      changedSchedules: newSchedules,
      currentScheduleId: currentScheduleId,
    };
    setRoutingData(data);
  };

  return (
    <section>
      <Heading
        level={2}
        css={css`
          margin-top: ${theme.spacing(6)};
          & .react-loader {
            position: absolute;
          }
        `}
      >
        {t('Routing Settings')}
        {/* {isLoading && <SpinningLoader size="small" />} */}
      </Heading>
      <Text color='light'>
        {t(
          'Once your hours are set, decide what should happen when a call comes in—ring the department devices, go to a phone tree, a call queue, or another routing option.'
        )}
      </Text>
      <Tabs initialTab={sortedSchedules?.[0]?.scheduleId} onChange={onTabChange}>
        <Tabs.Bar>
          {sortedSchedules.map((schedule, i) => {
            return (
              <Tabs.Tab
                key={i}
                id={schedule.scheduleId}
                controls={schedule.scheduleId}
                css={
                  schedule.scheduleId === newBreak?.schedule?.scheduleId &&
                  css`
                    ::after {
                      content: '';
                      height: 7px;
                      width: 7px;
                      background-color: ${theme.colors.primary50} !important;
                      border-radius: 50%;
                      pointer-events: none;
                      top: ${theme.spacing(0.5)};
                      right: ${theme.spacing(1)};
                      position: absolute;
                    }
                  `
                }
                trackingId={`phone-portal-mainline-${schedule.name.toLowerCase()}-tab`}
              >
                {schedule.name}
              </Tabs.Tab>
            );
          })}
        </Tabs.Bar>
        {!!currentSchedule && <RoutingForm schedule={currentSchedule} onUpdate={onScheduleUpdate} />}
      </Tabs>
    </section>
  );
};

/**
 * Context for maintaining local state between routing tabs
 */
type InstructionContextType = {
  instructions: InstructionPayload;
  setInstructions: Dispatch<SetStateAction<InstructionPayload>>;
};
export const InstructionSetContext = createContext<InstructionContextType>({} as InstructionContextType);

const InstructionProvider = ({ children, initialState }: { children: ReactNode; initialState: InstructionPayload }) => {
  const [instructions, setInstructions] = useState<InstructionPayload>(initialState);

  return (
    <InstructionSetContext.Provider value={{ instructions, setInstructions }}>
      {children}
    </InstructionSetContext.Provider>
  );
};

type RoutingFormProps = {
  schedule: ScheduleTypes.ScheduleWithRouting;
  onUpdate: (activeSchedule: ScheduleTypes.ScheduleWithRouting) => void;
};

const isOtherInstructionSet = (instructionType: InstructionsTypes.Instruction) => {
  return (
    instructionType !== InstructionsTypes.Instruction.CallGroup &&
    instructionType !== InstructionsTypes.Instruction.CallQueue &&
    instructionType !== InstructionsTypes.Instruction.IVRMenu
  );
};

export const RoutingForm: React.FC<React.PropsWithChildren<RoutingFormProps>> = ({ schedule, onUpdate }) => {
  const initialInstructions = schedule?.callRoute.instructions;
  const [activeInstructions, setActiveInstructions] = useState<InstructionsTypes.AnyInstruction[]>(
    schedule?.callRoute.instructions
  );

  const { data: voicemailBoxes } = useLocalizedQuery({
    queryKey: queryKeys.voiceMailboxes(),
    queryFn: VoiceMailboxApi.list,
  });

  const InstructionsInitialState: InstructionPayload = useMemo(() => {
    const data = {
      callGroup: (initialInstructions[0]?.type === InstructionsTypes.Instruction.CallGroup
        ? initialInstructions
        : [
            defaultInstruction.callGroup(),
            defaultInstruction.voicemailPrompt.skipGreeting(voicemailBoxes?.[0].mailboxID),
          ]) as CallGroupInstructionSet,
      callQueue: (initialInstructions[0]?.type === InstructionsTypes.Instruction.CallQueue
        ? initialInstructions
        : [defaultInstruction.callQueue()]) as CallQueueInstructionSet,
      ivrMenu: (initialInstructions[0]?.type === InstructionsTypes.Instruction.IVRMenu
        ? initialInstructions
        : [defaultInstruction.ivr()]) as IVRMenuInstructionSet,
      other: (isOtherInstructionSet(initialInstructions[0]?.type) && initialInstructions.length
        ? initialInstructions
        : [defaultInstruction.voicemailPrompt.skipGreeting(voicemailBoxes?.[0].mailboxID)]) as OtherInstructionSet,
    };
    return data;
  }, [initialInstructions, voicemailBoxes]);

  const [localState, dispatch] = useReducer(InstructionReducer, InstructionsInitialState);

  useEffect(() => {
    setActiveInstructions(schedule?.callRoute.instructions);
    dispatch({ type: 'seed-update', payload: InstructionsInitialState });
  }, [schedule, voicemailBoxes]);

  const routingTab = useMemo(() => {
    switch (activeInstructions[0]?.type) {
      case InstructionsTypes.Instruction.CallGroup:
        return TabTypes.CallGroup as TabTypes;
      case InstructionsTypes.Instruction.CallQueue:
        return TabTypes.CallQueue as TabTypes;
      case InstructionsTypes.Instruction.IVRMenu:
        return TabTypes.PhoneTree as TabTypes;
      default:
        return TabTypes.Other as TabTypes;
    }
  }, [activeInstructions[0]?.type]);

  const updateSchedule = (activeInstructionSet: InstructionsTypes.AnyInstruction[]) => {
    const data: ScheduleTypes.ScheduleWithRouting = {
      ...schedule,
      callRoute: {
        instructions: activeInstructionSet,
      },
    };
    onUpdate(data);
  };

  useEffect(() => {
    changeTab(routingTab);
  }, [localState]);

  useEffect(() => {
    updateSchedule(activeInstructions);
  }, [activeInstructions]);

  const changeTab = (tab: TabTypes) => {
    switch (tab) {
      case TabTypes.CallGroup:
        return setActiveInstructions(localState.callGroup);
      case TabTypes.CallQueue:
        return setActiveInstructions(localState.callQueue);
      case TabTypes.PhoneTree:
        return setActiveInstructions(localState.ivrMenu);
      default:
        return setActiveInstructions(localState.other);
    }
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          margin-top: ${theme.spacing(4)};
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        <RoutingSetting
          title='Ring Call Group'
          description='Incoming calls will ring assigned devices'
          active={routingTab === TabTypes.CallGroup}
          onClick={() => changeTab(TabTypes.CallGroup)}
          data-trackingid='phone-portal-mainLine-callGroup-tab'
        />
        <RoutingSetting
          title='Phone Tree'
          description='Route incoming calls to a phone tree'
          active={routingTab === TabTypes.PhoneTree}
          onClick={() => changeTab(TabTypes.PhoneTree)}
        />
        <RoutingSetting
          title='Call Queue'
          description='Route incoming calls to a call queue'
          active={routingTab === TabTypes.CallQueue}
          onClick={() => changeTab(TabTypes.CallQueue)}
        />
        <RoutingSetting
          title='Other Routing Options'
          description='Send to voicemail, message, or forward call'
          active={routingTab === TabTypes.Other}
          onClick={() => changeTab(TabTypes.Other)}
        />
      </div>
      <div>
        <InstructionProvider initialState={localState}>
          {routingTab === TabTypes.CallGroup && (
            <RoutingRingForm
              scheduleName={schedule.name}
              instructions={localState.callGroup as CallGroupInstructionSet}
              dispatchState={dispatch}
            />
          )}
          {routingTab === TabTypes.PhoneTree && (
            <RoutingPhoneTreeForm
              scheduleName={schedule.name}
              instructions={localState.ivrMenu as IVRMenuInstructionSet}
              dispatchState={dispatch}
            />
          )}
          {routingTab === TabTypes.CallQueue && (
            <RoutingCallQueueForm
              instructions={localState.callQueue as CallQueueInstructionSet}
              dispatchState={dispatch}
            />
          )}
          {routingTab === TabTypes.Other && (
            <RoutingOtherOptionForm
              scheduleName={schedule.name}
              instructions={localState.other as OtherInstructionSet}
              dispatchState={dispatch}
            />
          )}
        </InstructionProvider>
      </div>
    </>
  );
};

type RoutingSettingProps = {
  title: string;
  description: string;
  active: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const RoutingSetting: React.FC<React.PropsWithChildren<RoutingSettingProps>> = ({
  title,
  description,
  active,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      data-trackingid={`phone-portal-mainline-${title.replace(/\s/g, '').toLowerCase()}-tab`}
      css={css`
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 5px;
        border-style: none;
        background-color: ${theme.colors.white};
        margin-left: ${theme.spacing(1.5)};
        margin-right: ${theme.spacing(1.5)};
        ${active ? `border: 2px solid ${theme.colors.primary50};` : ''}
        box-shadow: 0 4px 10px 0 rgba(222,226,227,0.8);
        box-sizing: border-box;
        flex-grow: 1;
        :focus {
          outline: none;
        }
        :hover {
          cursor: pointer;
        }
        &:first-of-type {
          margin-left: 0px;
        }
        &:last-of-type {
          margin-right: 0px;
        }
      `}
    >
      <Text
        size='large'
        weight='bold'
        css={css`
          margin-bottom: ${theme.spacing(0.5)};
        `}
      >
        {title}
      </Text>
      <Text size='small' color='light'>
        {description}
      </Text>
    </button>
  );
};
