import { ReactElement, ReactNode } from 'react';
import { useEditor } from '@craftjs/core';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { BaseFieldProps, contextFactory, DynamicFieldAttributes, useFormField } from '@frontend/design-system';
import { useActivePanel, useHtmlRenderer, useInsertSection, useStateManagement } from '../hooks';
import { ComposerDocument, ComposerPanel, ComposerParts, ComposerMode, DeviceSize } from '../types';

type ContentComposerContextState = {
  activePanel: ComposerPanel;
  cancelRenderHtml: () => void;
  composerMode: ComposerMode;
  deviceSizeProps?: BaseFieldProps<DeviceSize>; // TODO: Replace this with CSS classes
  getValue: () => ComposerDocument | '';
  insertSection: (element: ReactElement, index?: number) => void;
  locationIds: string[];
  parts: ComposerParts;
  promptProps: BaseFieldProps<string>; // TODO: Move this completely out of the content composer, but modify components to support it
  renderHtml: (mode?: string) => Promise<string | undefined>;
  selectedSectionId?: string;
  setActivePanel: (panel: Exclude<ComposerPanel, 'settings'>) => void;
  setValue: (document: ComposerDocument) => void;
  subjectDynamicFields?: DynamicFieldAttributes[];
  templateTypeSlug: TemplateType_Slug;
  textDynamicFields?: DynamicFieldAttributes[];
};

export const [ContentComposerContext, useContentComposer] = contextFactory<ContentComposerContextState>(
  'useContentComposer must be used within an ContentComposer'
);

type ContentComposerProviderProps = {
  children: ReactNode;
  composerMode: ComposerMode;
  deviceSizeProps?: BaseFieldProps<DeviceSize>;
  initialValue?: ComposerDocument;
  locationIds: string[];
  parts: ComposerParts;
  subjectDynamicFields?: DynamicFieldAttributes[];
  templateTypeSlug: TemplateType_Slug;
  textDynamicFields?: DynamicFieldAttributes[];
};

export const ContentComposerProvider = ({
  children,
  composerMode,
  deviceSizeProps,
  initialValue,
  locationIds,
  parts,
  subjectDynamicFields,
  templateTypeSlug,
  textDynamicFields,
}: ContentComposerProviderProps) => {
  const { getValue, setValue } = useStateManagement(initialValue, parts, composerMode);
  const {
    HtmlRenderer,
    htmlRendererProps,
    startRender: renderHtml,
    cancelRender: cancelRenderHtml,
  } = useHtmlRenderer();
  const { activePanel, setActivePanel } = useActivePanel();
  const insertSection = useInsertSection();
  const promptProps = useFormField({ type: 'text' });

  const { selectedSectionId } = useEditor((state) => {
    const [selectedSectionId] = state.events.selected;
    return { selectedSectionId };
  });

  return (
    <ContentComposerContext.Provider
      value={{
        activePanel,
        cancelRenderHtml,
        composerMode,
        deviceSizeProps,
        getValue,
        insertSection,
        locationIds,
        parts,
        promptProps,
        renderHtml,
        selectedSectionId,
        setActivePanel,
        setValue,
        subjectDynamicFields,
        templateTypeSlug,
        textDynamicFields,
      }}
    >
      {children}
      <HtmlRenderer {...htmlRendererProps} />
    </ContentComposerContext.Provider>
  );
};
