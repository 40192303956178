import {
  AuthService,
  ExchangeTokenResponse,
  VerifyAuthResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import {
  LoginFeatures,
  GetLoginFeaturesResponse,
} from '@weave/schema-gen-ts/dist/schemas/login-features/v1/service.pb';
import {
  AuthConfig,
  AuthStorage,
  OidcClientConfig,
  localStorageHelper,
  PortalUser,
  SignInMethod,
  getDecodedWeaveToken,
  getWeaveToken,
  unsetCachedToken,
  setWeaveToken,
  getExpireLength,
} from '@frontend/auth-helpers';
import appConfig, { getInitialParams } from '@frontend/env';
import { sentry } from '@frontend/tracking';
import { AuthFlow, SignInProps, SignOutProps, ClientTokens, HandleCallbackProps } from './AuthFlow';
import { APIFetchNoAuth } from './fetch';
import { LegacyClient } from './legacy.class';
import { OidcClient } from './oidc.class';
import { OktaClient } from './okta.class';

const oidc_code_hint = 'ory_ac_';
const { oktaId, backendApi, oidcIssuer, oidcClientID } = getInitialParams();
const redirectUri = `${window.location.origin}${appConfig.BASE_PATH ? `/${appConfig.BASE_PATH}` : ''}/sign-in/callback`;
const appSource = appConfig.APP_SOURCE || 'weave2';
const oktaIssuer = appConfig.OKTA_ISSUER || 'https://auth.weaveconnect.com/oauth2/default';

export class Authn {
  private static instance: Authn;
  private authMethods: SignInMethod[] = ['okta', 'oidc', 'legacy'];
  private currentAuthMethod: SignInMethod = 'oidc';
  private hasCheckedAuthMethod = false;
  // These get defined in the constructor
  private oidcClient: OidcClient | undefined;
  private oktaClient: AuthFlow | undefined;
  private legacyClient: AuthFlow | undefined;
  private config: AuthConfig = {};

  private constructor() {
    this.setConfig(this.config);
  }

  static getInstance(): Authn {
    if (!Authn.instance) {
      Authn.instance = new Authn();
    }

    return Authn.instance;
  }

  public setConfig(incomingConfig: AuthConfig): void {
    this.config.oktaIssuer = incomingConfig.oktaIssuer || oktaIssuer;
    this.config.oktaClientID = incomingConfig.oktaClientID || oktaId;
    this.config.oktaRedirectUri = incomingConfig.oktaRedirectUri || redirectUri;
    this.config.oidcIssuer = incomingConfig.oidcIssuer || oidcIssuer;
    this.config.oidcClientID = incomingConfig.oidcClientID || oidcClientID;
    this.config.oidcRedirectUri = incomingConfig.oidcRedirectUri || redirectUri;
    this.config.backendAPI = incomingConfig.backendAPI || backendApi;
    this.config.appSource = incomingConfig.appSource || appSource;
    this.initializeValues();
  }

  private initializeValues(): void {
    if (!this.config.oidcIssuer) {
      this.authMethods = ['okta', 'legacy'];
    } else {
      const opts: OidcClientConfig = {
        issuer: new URL(this.config.oidcIssuer),
        scopes: ['openid', 'email'],
      };

      if (!!this.config.oidcClientID) {
        opts.clientID = this.config.oidcClientID;
        opts.redirectUri = new URL(this.config.oidcRedirectUri || '');
      }

      this.oidcClient = new OidcClient(opts);
    }

    this.oktaClient = new OktaClient({
      issuer: this.config.oktaIssuer || '',
      clientId: this.config.oktaClientID || '',
      redirectUri: this.config.oktaRedirectUri || '',
      scopes: ['openid', 'email', 'profile'],
    });
    this.legacyClient = new LegacyClient();
  }

  public async getSignInMethod(): Promise<SignInMethod> {
    if (this.hasCheckedAuthMethod) {
      return Promise.resolve(this.currentAuthMethod);
    }
    // if we are in preview, we should always default to legacy auth
    // TODO: preview.weavelab.xyz is the old way of serving previews. This will be going away soon
    // f.weavedev.net will be replacing preview.weavelab.xyz
    const origin = window.location.origin;
    if (
      origin.includes('preview.weavelab.xyz') ||
      origin.includes('f.weavedev.net') ||
      localStorageHelper.get('backendEnv') === 'dev'
    ) {
      this.hasCheckedAuthMethod = true;
      this.currentAuthMethod = 'legacy';
      return Promise.resolve('legacy');
    }
    const lastSignInMethod = localStorageHelper.get(AuthStorage.sign_in_method);
    if (
      (!!lastSignInMethod && lastSignInMethod === 'okta') ||
      lastSignInMethod === 'legacy' ||
      lastSignInMethod === 'oidc'
    ) {
      this.hasCheckedAuthMethod = true;
      this.currentAuthMethod = lastSignInMethod;
      return Promise.resolve(lastSignInMethod);
    }
    const fingerprint = localStorageHelper.get(AuthStorage.sign_in_fingerprint);
    return LoginFeatures.GetLoginFeatures(APIFetchNoAuth<GetLoginFeaturesResponse>(this.config.backendAPI as string), {
      fingerprint,
    })
      .then((res: GetLoginFeaturesResponse) => {
        if (!res) {
          return 'okta';
        }
        if (res.fingerprint) {
          localStorageHelper.create(AuthStorage.sign_in_fingerprint, res.fingerprint);
        }
        this.hasCheckedAuthMethod = true;
        this.currentAuthMethod =
          res.features?.includes('oidc') && this.authMethods.includes('oidc')
            ? 'oidc'
            : res.features?.includes('okta')
            ? 'okta'
            : 'legacy';
        return this.currentAuthMethod;
      })
      .catch(() => {
        return 'oidc'; // default for prod until OIDC is released
      });
  }

  public changeAuthMethod(authMethod?: SignInMethod): SignInMethod {
    if (!!authMethod) {
      localStorageHelper.create(AuthStorage.sign_in_method, authMethod);
      this.currentAuthMethod = authMethod;
      return authMethod;
    }

    // if no auth method is passed in, cycle through the available auth methods
    const currentMethodIndex = this.authMethods.indexOf(this.currentAuthMethod);
    const nextMethodIndex = currentMethodIndex === this.authMethods.length - 1 ? 0 : currentMethodIndex + 1;
    const currentSignInMethod: SignInMethod = this.authMethods[nextMethodIndex];
    localStorageHelper.create(AuthStorage.sign_in_method, currentSignInMethod);
    this.currentAuthMethod = currentSignInMethod;
    return currentSignInMethod;
  }

  public async fetchUser() {
    const token = getWeaveToken();
    const decodedToken = getDecodedWeaveToken();
    if (!decodedToken) {
      throw new Error('No token found');
    }
    const options: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const resp = await fetch(`${this.config.backendAPI}/portal/users/${decodedToken.user_id}`, options);
      const json: { data: PortalUser } = await resp.json();
      if (!json.data) {
        console.error('User Not Found');
        return undefined;
      }

      localStorageHelper.create(AuthStorage.user, json.data);
      return json.data;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  private getAuthClient(): AuthFlow {
    let authClient: AuthFlow | undefined;

    switch (this.currentAuthMethod) {
      case 'oidc':
        authClient = this.oidcClient;
        break;
      case 'okta':
        authClient = this.oktaClient;
        break;
      case 'legacy':
        authClient = this.legacyClient;
        break;
      default:
        if (this.authMethods.includes('oidc')) {
          // depending on the mode we should default to oidc or legacy
          authClient = this.oidcClient;
        } else {
          authClient = this.legacyClient;
        }
        break;
    }

    if (!authClient) {
      throw new Error(`Tried to get auth client for auth method ${this.currentAuthMethod} but it was not defined`);
    }

    return authClient;
  }

  public async exchangeToken(tokens: ClientTokens, source?: string): Promise<string> {
    try {
      const clientSource = source ?? this.config.appSource;
      const res = await AuthService.ExchangeToken(
        APIFetchNoAuth<ExchangeTokenResponse>(this.config.backendAPI as string),
        {
          tokenId: tokens.IDToken,
          expiration: `${getExpireLength()}`, // defaults to 4h but can be overridden by localstorage
          source: clientSource,
        }
      );
      if (res.token) {
        unsetCachedToken(); // just in case there is already a token in memory
        setWeaveToken(res.token);
        return res.token;
      } else {
        throw new Error('No token returned');
      }
    } catch (err) {
      sentry.error({
        topic: 'auth',
        error: err,
        addContext: {
          name: 'auth',
          context: {
            errMessage: 'OIDC Login Token Exchange',
          },
        },
      });
      throw err;
    }
  }

  public async refreshWeaveToken() {
    const token = getWeaveToken();
    if (!token) {
      throw new Error('No existing weave token');
    }

    const options: RequestInit = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({ Expiration: `${getExpireLength()}` }),
    };
    return fetch(`${this.config.backendAPI}/portal/token/refresh`, options)
      .then((res) => res.json())
      .then((res) => res.data)
      .then((newToken: string) => {
        if (!!newToken) {
          unsetCachedToken();
          setWeaveToken(newToken);
          return newToken;
        }
        throw new Error('Refresh token failed');
      });
  }

  public getTokens(): Promise<ClientTokens> {
    return this.getAuthClient().getTokens();
  }

  public async handleCallback(props?: HandleCallbackProps): Promise<ClientTokens> {
    // based on the code in the query params we should identify which auth method to use
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code && code.indexOf(oidc_code_hint) != -1) {
      this.changeAuthMethod('oidc');
    }
    return this.getAuthClient()
      .handleCallback(props)
      .then((tokens) => {
        if (tokens?.IDToken) {
          return this.exchangeToken(tokens, props?.clientId).then((weaveJWT: string) => {
            if (weaveJWT) {
              tokens.weaveToken = weaveJWT;
            }
            return tokens;
          });
        }
        return {};
      });
  }

  public isUserAuthenticated(): boolean {
    return this.getAuthClient().isUserAuthenticated();
  }

  public signIn(props?: SignInProps): Promise<VerifyAuthResponse> {
    return this.getAuthClient().signIn(props);
  }

  // signOut will result in a browser redirect, the promise will never resolve
  // if you need side effects to run before redirection you can provide a preRedirectSideEffects func in props
  public async signOut(props?: SignOutProps): Promise<never> {
    // wrapping this with getSignInMethod to ensure that the most recently used sign in method is used
    await this.getSignInMethod();
    localStorageHelper.delete(AuthStorage.user);
    localStorageHelper.delete(AuthStorage.weave_token);
    localStorageHelper.delete(AuthStorage.decoded_weave);
    localStorageHelper.delete(AuthStorage.sign_in_method);
    return this.getAuthClient().signOut(props);
  }

  public async frontChannelLogout(): Promise<void> {
    await this.getSignInMethod();
    localStorageHelper.delete(AuthStorage.user);
    localStorageHelper.delete(AuthStorage.weave_token);
    localStorageHelper.delete(AuthStorage.decoded_weave);
    localStorageHelper.delete(AuthStorage.sign_in_method);
    localStorageHelper.delete(AuthStorage.oidc_token_storage);
  }
}

export const authnClient = Authn.getInstance();
