import { useEffect } from 'react';
import { ButtonSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import ContentEditable from 'react-contenteditable';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { ComposerSection, Corners } from '../../types';
import { getCornersStyle, getFrameStyle } from '../../utils';
import { ReviewButtonOutput } from '../outputs';
import { ButtonSettings } from '../settings';
import { CustomReviewButtonTool } from '../tools';
import { BaseComposerSection, CustomReviewButtonsToolbar } from './floating-toolbar';

export const CustomReviewButtonSection: ComposerSection<ButtonSection> = ({
  buttonColor,
  corners,
  frame,
  link,
  textColor,
}) => {
  const { t } = useTranslation('content-composer');
  const { isSelected, setProps } = useComposerSection<ButtonSection>();

  const fieldProps = useFormField({
    type: 'text',
    value: link?.text,
  });

  useEffect(() => {
    const newLink = { ...(link ? link : { linkType: '', url: '' }), text: fieldProps.value };
    setProps((props) => (props.link = newLink));
  }, [fieldProps.value]);

  return (
    <BaseComposerSection
      title={t('Custom Review Button')}
      canCopy={false}
      css={getFrameStyle(frame)}
      Toolbar={isSelected && <CustomReviewButtonsToolbar link={link} />}
    >
      <ContentEditable
        id={fieldProps.id}
        html={fieldProps.value}
        onChange={fieldProps.onChange}
        css={[
          getCornersStyle(corners as Corners | undefined),
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: buttonColor,
            color: textColor,
            padding: theme.spacing(1, 2),
            fontWeight: theme.font.weight.bold,
            outline: 'none',
            height: theme.spacing(5),
            '&:hover, &:focus': {
              boxShadow: theme.shadows.floating,
            },
          },
        ]}
      />
    </BaseComposerSection>
  );
};

CustomReviewButtonSection.craft = {
  props: {
    buttonColor: theme.colors.primary50.toUpperCase(),
    corners: 'pill',
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    link: {
      linkType: 'custom',
      text: i18next.t('Click to Edit Button', { ns: 'content-composer' }),
      url: '',
    },
    sectionType: 'CustomReviewButtonSection',
    textColor: theme.colors.white.toUpperCase(),
  },
  related: {
    output: ReviewButtonOutput,
    settings: ButtonSettings,
    tool: CustomReviewButtonTool,
  },
};
