import { useEffect, useState } from 'react';
import {
  FooterSection as FooterSectionType,
  Link,
} from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { useComposerSection, useLinks } from '../../hooks';
import { ComposerSection } from '../../types';
import { getFrameStyle } from '../../utils';
import { EmailSvg } from '../assets/email-svg';
import { FacebookSvg } from '../assets/facebook-svg';
import { GoogleSvg } from '../assets/google-svg';
import { PhoneSvg } from '../assets/phone-svg';
import { useContentComposer } from '../content-composer-provider';
import { FooterOutput } from '../outputs';
import { FooterSettings } from '../settings';
import { FooterTool } from '../tools';
import { BaseComposerSection, FooterToolbar } from './floating-toolbar';

export type FooterMode = 'default' | 'options' | 'link' | 'custom';

const Pipe = () => (
  <div
    css={{
      width: 1,
      height: 12,
      background: theme.colors.neutral50,
      margin: theme.spacing(0, 2),
    }}
  />
);

export const FooterSection: ComposerSection<FooterSectionType> = ({
  bold = false,
  fontType = 'Arial',
  frame,
  italic = false,
  links,
  textColor = theme.colors.black,
  textSize = 16,
}) => {
  const { t } = useTranslation('content-composer');
  const [mode, setMode] = useState<FooterMode>('default');
  const [selectedLink, setSelectedLink] = useState<Link>();

  const { address, businessName } = useLinks();
  const { isSelected, setProps } = useComposerSection<FooterSectionType>();

  const { composerMode } = useContentComposer();
  const isEmailComposer = composerMode !== 'feedback';

  const fontStyles = {
    fontWeight: bold ? 'bold' : 'normal',
    fontStyle: italic ? 'italic' : 'normal',
    fontFamily: fontType,
    fontSize: textSize + 'px',
    color: textColor,
    textDecoration: 'underline',
  };

  useEffect(() => {
    if (!isSelected && mode !== 'default') {
      setMode('default');
    }
  }, [isSelected]);

  const handleUnselectClick = () => {
    setSelectedLink(undefined);
    setMode('options');
  };

  const handleLinkUpdate = (link: Link, modeType: FooterMode) => {
    setSelectedLink(link);
    setMode(modeType);
  };

  const alphaNumericRegex = /[^a-z0-9]/gi;
  const createId = (link: Link) =>
    link.linkType + '-' + link.url.replace(alphaNumericRegex, '') + '-' + link.text?.replace(alphaNumericRegex, '');

  return (
    <BaseComposerSection
      title={t('Footer')}
      canCopy={false}
      canDelete={!isEmailComposer}
      disableDrag={isEmailComposer}
      css={{
        flexDirection: 'column',
        alignItems: frame?.alignment === 'left' ? 'flex-start' : frame?.alignment === 'right' ? 'flex-end' : 'center',
        width: '100%',
        ...getFrameStyle(frame),
      }}
      Toolbar={
        isSelected && (
          <FooterToolbar
            links={links}
            mode={mode}
            setMode={setMode}
            selectedLink={selectedLink}
            setSelectedLink={setSelectedLink}
            settings={{
              bold,
              fontType,
              italic,
              textSize,
              textColor,
            }}
          />
        )
      }
    >
      {links && !!links.length && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1.5),
            columnGap: theme.spacing(1),
          }}
        >
          {links.map((link, index) => (
            <div key={index} css={{ position: 'relative' }}>
              {isSelected && (
                <NakedButton
                  className={createId(link)}
                  css={{
                    position: 'absolute',
                    top: link.linkType === 'custom' ? theme.spacing(-0.5) : 0,
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                    background: theme.colors.white,
                    borderRadius: theme.borderRadius.small,
                    padding: theme.spacing(0, 0.5),
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                    zIndex: theme.zIndex.middle + 1,
                  }}
                  onClick={() => {
                    if (selectedLink?.url === link.url) setMode('options');
                    setProps((props) => (props.links = links?.filter((l) => l.url !== link.url)));
                  }}
                >
                  <Icon name='x' />
                </NakedButton>
              )}
              {link.linkType === 'custom' ? (
                <NakedButton css={[buttonStyling, fontStyles]} onClick={() => handleLinkUpdate(link, 'custom')}>
                  {link?.text}
                </NakedButton>
              ) : (
                <NakedButton
                  onClick={() =>
                    link.linkType === 'phone' || link.linkType === 'email'
                      ? handleUnselectClick()
                      : handleLinkUpdate(link, 'link')
                  }
                >
                  {link.linkType === 'email' && <EmailSvg fillColor={textColor} />}
                  {link.linkType === 'phone' && <PhoneSvg fillColor={textColor} />}
                  {link.linkType === 'facebook' && <FacebookSvg fillColor={textColor} />}
                  {link.linkType === 'google' && <GoogleSvg fillColor={textColor} />}
                </NakedButton>
              )}
            </div>
          ))}
        </div>
      )}
      <NakedButton
        css={[
          buttonStyling,
          fontStyles,
          {
            marginBottom: theme.spacing(2),
            textDecoration: 'none',
            cursor: 'initial',
          },
        ]}
      >
        {address}
      </NakedButton>
      <NakedButton
        css={[
          fontStyles,
          {
            marginBottom: theme.spacing(2),
            textDecoration: 'none',
            cursor: 'initial',
          },
        ]}
      >
        {businessName}
      </NakedButton>
      {isEmailComposer && (
        <div css={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1) }}>
          <NakedButton css={fontStyles}>{t('Preference Center')}</NakedButton>
          <Pipe />
          <NakedButton css={fontStyles}>{t('Unsubscribe')}</NakedButton>
        </div>
      )}
    </BaseComposerSection>
  );
};

FooterSection.craft = {
  props: {
    bold: false,
    fontType: 'Arial',
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    italic: false,
    links: [],
    sectionType: 'FooterSection',
    textColor: theme.colors.black.toUpperCase(),
    textSize: 16,
  },
  related: {
    settings: FooterSettings,
    output: FooterOutput,
    tool: FooterTool,
  },
};

const buttonStyling = { width: 'auto' };
