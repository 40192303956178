import { FC, useRef, useEffect, useState, useCallback } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from '@frontend/i18n';
import { TextField, FormRow, EmailField, Text, ContentLoader } from '@frontend/design-system';
import { useEmailFields } from '../../../hooks';
import { useSendFormsStore } from '../../../providers';
import {
  containerStyle,
  lastRowStyle,
  bodyContainerStyle,
  emailBodyFormRowStyle,
  errorMessageStyle,
} from './email-message-mode.styles';

export const FormsDirectDeliveryEmailForm: FC = () => {
  const { t } = useTranslation('forms', { keyPrefix: 'SEND_FORMS_COMPOSE_MESSAGE_STEP' });
  const { emailBody, updateEmailBody, emailSubjectFieldProps, emailFieldProps } = useEmailFields();
  const emailTemplateRef = useRef('');
  const templateWrapperRef = useRef<HTMLDivElement>(null);
  const [isEmailBodyInitialized, setIsEmailBodyInitialized] = useState(false);
  const { isGeneratingSolicitedLink } = useSendFormsStore(['isGeneratingSolicitedLink']);

  const emailBodyEventListener = useCallback(() => {
    const content = templateWrapperRef.current?.innerHTML ?? '';
    updateEmailBody(content ? DOMPurify.sanitize(content) : content);
  }, [updateEmailBody]);

  useEffect(() => {
    const elRef = templateWrapperRef.current;
    if (!elRef) {
      return;
    }
    elRef.addEventListener('keyup', emailBodyEventListener);
    return () => {
      elRef.removeEventListener('keyup', emailBodyEventListener);
    };
  }, []);

  useEffect(() => {
    const elRef = templateWrapperRef.current;
    if (isEmailBodyInitialized || !elRef) {
      return;
    }
    if (emailBody.trim() !== '') {
      emailTemplateRef.current = emailBody;
      elRef.innerHTML = DOMPurify.sanitize(emailBody);
      setIsEmailBodyInitialized(true);
    }
  }, [emailBody, isEmailBodyInitialized]);

  if (isGeneratingSolicitedLink) {
    return <ContentLoader show />;
  }

  return (
    <form css={containerStyle}>
      <FormRow>
        <EmailField {...emailFieldProps} label={t('EMAIL_INPUT_LABEL')} name='email' />
      </FormRow>
      <FormRow>
        <TextField {...emailSubjectFieldProps} label={t('EMAIL_SUBJECT_LABEL')} name='email-subject' />
      </FormRow>
      <FormRow css={[lastRowStyle, emailBodyFormRowStyle]}>
        <div
          ref={templateWrapperRef}
          contentEditable
          className={emailBody.length === 0 ? 'error' : ''}
          css={bodyContainerStyle}
        ></div>
        {emailBody.length === 0 && (
          <Text color='error' size='small' css={errorMessageStyle}>
            {t('EMAIL_BODY_REQUIRED')}
          </Text>
        )}
      </FormRow>
    </form>
  );
};
