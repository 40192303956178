import { createElement } from 'react';
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core';
import { useContentComposer } from '../components/content-composer-provider';
import { ComposerSectionMeta, ComposerSectionProps, SetComposerSectionMeta, SetComposerSectionProps } from '../types';

export const useComposerSection = <T>() => {
  const {
    actions,
    selected,
    nodes,
    query: { parseFreshNode },
  } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
        node: query.node(currentNodeId).get(),
      };
    }

    return {
      selected,
      nodes: state.nodes[ROOT_NODE]?.data.nodes,
    };
  });

  const {
    actions: { setProp: setProps, setCustom: setMeta },
    id,
    meta,
    props,
    sectionHTML,
  } = useNode((node) => ({
    id: node.id,
    props: node.data.props as T & ComposerSectionProps,
    meta: node.data.custom as ComposerSectionMeta,
    sectionHTML: node.dom,
  }));

  const { insertSection, parts } = useContentComposer();

  const copySection = () => {
    const sectionType = props.sectionType;
    const foundPart = Object.entries(parts).find(([key]) => key === sectionType);
    if (foundPart) {
      const [_key, Part] = foundPart;
      const index = nodes?.indexOf(id) ?? (nodes ? nodes.length - 1 : -1);
      if (typeof Part === 'function') {
        const element = createElement(Part, props);
        insertSection(element, index + 1);
      }
    }
  };

  const deleteSection = () => (selected?.isDeletable ? actions.delete(selected.id) : undefined);

  const replaceSection = (newProps: Record<string, any>) => {
    if (selected?.node) {
      const index = nodes?.indexOf(id);
      // creates a copy of the node to be replaced by a new one
      const parsed = parseFreshNode(selected.node).toNode();
      parsed.data.props = { ...props, ...newProps };
      actions.delete(selected.id); // delete the original node
      setTimeout(() => actions.add(parsed, ROOT_NODE, index), 0); // creates a new node and inserts it at the original index
    }
  };

  return {
    copySection,
    deleteSection,
    id,
    isSelected: selected?.id === id,
    meta,
    props,
    replaceSection,
    sectionHTML,
    setMeta: setMeta as SetComposerSectionMeta,
    setProps: setProps as SetComposerSectionProps<T>,
  };
};
