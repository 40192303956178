import { DashboardWidgetFC } from '@frontend/grid-dashboard';
import { FormsSharedWidget } from './forms-shared-widget';

/**
 * @dashboard-widget
 *
 * id: forms-submission
 * title: Forms Submissions
 * description: Show forms submitted by patients throughout the week and a graph indicating their submission timeline.
 * icon: forms-small
 */
export const FormsSubmissionWidget: DashboardWidgetFC = () => {
  return <FormsSharedWidget showCreateFormAction />;
};

FormsSubmissionWidget.config = {
  size: {
    large: 'small-narrow',
    medium: 'small-narrow',
    small: 'tiny-slim',
    extraSmall: 'tiny-slim',
  },
  feature: 'forms',
};
