import { FC, useEffect } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useAppFlagStore } from '@frontend/shared';
import { StateRefHelpers } from '@frontend/state-ref-helpers';
import { theme } from '@frontend/theme';
import { Text, PopoverMenu, PopoverMenuItem, usePopoverMenu, Button } from '@frontend/design-system';
import { useInboxTemplateImporterShallowStore } from '../stores';
import { TemplateFlowPopoverItem } from '../types';
import TemplateImportPreview from './template-import-callout-preview.svg?react';

type TemplatesPopoverProps = Pick<
  ReturnType<typeof usePopoverMenu<HTMLButtonElement>>,
  'getMenuProps' | 'getItemProps'
> & {
  templateItems: TemplateFlowPopoverItem[];
  locationId: string;
  closePopover: () => void;
};

export const TemplatesPopover: FC<TemplatesPopoverProps> = ({
  getMenuProps,
  getItemProps,
  templateItems,
  locationId,
  closePopover,
}) => {
  const { t } = useTranslation('inbox-templates');
  const { featureFlags } = useAppFlagStore();
  const { selectedOrgId } = useAppScopeStore();
  const user = getUser();
  const { navigate } = useSettingsNavigate();
  const { getUserHasBeenShownImportUI, addUserIdToShownImportUI } = useInboxTemplateImporterShallowStore(
    'addUserIdToShownImportUI',
    'getUserHasBeenShownImportUI'
  );
  const hasBeenShownImportCalloutInPast14Days = !!user && getUserHasBeenShownImportUI(selectedOrgId, user?.userID);
  const hasBeenShownImportCalloutEver =
    !!user && getUserHasBeenShownImportUI(selectedOrgId, user?.userID, new Date(0).toISOString());
  const showImportUIFF = !!featureFlags[locationId]?.get('enable-all-apps-view')?.value;
  const showImportUIACL = hasSchemaACL(locationId, Permission.MANUAL_MESSAGE_TEMPLATES_MANAGE);
  // Shows the templates settings/importer button without callout in last 14 days
  const showImportButton = showImportUIACL && showImportUIFF && hasBeenShownImportCalloutInPast14Days;
  // Shows the primary button/image callout on first open of the inbox templates popover
  const showImportCallout = showImportUIACL && showImportUIFF && user && !hasBeenShownImportCalloutEver;
  const menuProps = getMenuProps();
  const [previousIsOpen, isOpen] = StateRefHelpers.usePreviousValue(menuProps.isOpen);

  const openImporter = () => {
    navigate({ to: '/messaging/templates/import', search: { locationId, closeOnCancel: true } });
    closePopover();
  };

  const getItemStyles = ({
    isClickable,
    showSeparator,
  }: {
    isClickable: boolean;
    showSeparator: boolean;
  }): Interpolation<Theme> => ({
    padding: theme.spacing(0, 2, 0, 1),
    cursor: isClickable ? 'pointer' : undefined,
    borderTop: showSeparator ? `1px solid ${theme.colors.neutral20}` : undefined,
  });

  useEffect(() => {
    if (previousIsOpen && !isOpen && user && showImportCallout) {
      addUserIdToShownImportUI(selectedOrgId, user.userID);
    }
  }, [menuProps.isOpen]);

  return (
    <PopoverMenu
      {...menuProps}
      css={{
        minWidth: 'max-content',
        width: 'max-content',
        justifyContent: showImportCallout ? 'start' : undefined,
        borderRadius: showImportCallout ? undefined : theme.spacing(0.5),
        padding: showImportCallout ? theme.spacing(2, 2, 4) : theme.spacing(0.5, 0),
      }}
    >
      {showImportCallout ? (
        <>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
              width: 250,
              marginBottom: theme.spacing(1),
            }}
          >
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Text weight='bold'>{t('Missing Something?')}</Text>
              <Button
                iconName='x'
                onClick={closePopover}
                variant='secondary'
                trackingId='inbox-templates-importer-callout:close'
              />
            </div>
            <figure
              css={{
                borderRadius: theme.borderRadius.medium,
                boxShadow: `inset 0 0 4px 0 #00000040`,
                padding: theme.spacing(2),
                backgroundColor: theme.colors.neutral5,
              }}
            >
              <TemplateImportPreview width='100%' height='100%' />
            </figure>
            <Text>
              {t(
                'Out with the old, in with the new… but not without your favorite things! Import your go-to templates from Legacy by clicking the button below — or start fresh and create your first New Weave template.'
              )}
            </Text>
          </div>

          <Button
            iconName='download'
            onClick={openImporter}
            css={{
              width: 'fit-content',
              marginBottom: theme.spacing(1),
            }}
            trackingId='inbox-templates-importer-callout:open-importer'
          >
            {t('Import Legacy Templates')}
          </Button>
          <Button
            variant='secondary'
            iconName='plus'
            css={{
              width: 'fit-content',
            }}
            onClick={() => {
              navigate({ to: '/messaging/templates/create' });
              closePopover();
            }}
            trackingId='inbox-templates-importer-callout:create-new-template'
          >
            {t('Create New Template')}
          </Button>
        </>
      ) : (
        <>
          {templateItems?.map(({ iconName, label, onClick, trackingId, showLabelOnHover }, index) => {
            return (
              <PopoverMenuItem
                key={`${typeof label === 'string' ? label : iconName + trackingId}-${index}`}
                css={getItemStyles({ isClickable: !!onClick, showSeparator: false })}
                trackingId={trackingId}
                {...getItemProps({
                  index,
                  onClick,
                  hoverLabel: showLabelOnHover && typeof label === 'string' ? label : undefined,
                  showLabelOnHover,
                  disabled: !onClick,
                })}
              >
                <Icon name={iconName} size={20} color='light' />
                {label}
              </PopoverMenuItem>
            );
          })}
          {showImportButton && (
            <>
              <PopoverMenuItem
                css={getItemStyles({ isClickable: true, showSeparator: true })}
                {...getItemProps({
                  index: templateItems.length,
                  onClick: openImporter,
                  trackingId: 'inbox-templates-importer-long-tail:open-importer',
                })}
              >
                <Icon name='download' size={20} color='light' />
                {t('Import Legacy Templates')}
              </PopoverMenuItem>
              <PopoverMenuItem
                css={getItemStyles({ isClickable: true, showSeparator: false })}
                {...getItemProps({
                  index: templateItems.length + 1,
                  onClick: () => {
                    navigate({ to: '/messaging/templates' });
                    closePopover();
                  },
                  trackingId: 'inbox-templates-importer-long-tail:templates-settings',
                })}
              >
                {t('Template Settings...')}
              </PopoverMenuItem>
            </>
          )}
        </>
      )}
    </PopoverMenu>
  );
};
