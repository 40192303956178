import { css } from '@emotion/react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import type { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { Text } from '@frontend/design-system';
import { useNotificationContext } from '../notification-provider';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'missed-call'>>;

export const MissedCallNotificationComponent = ({ isHistorical, emit, notification, ...rest }: Props) => {
  const { t } = useTranslation();
  const { personContact, callerNumber, callRecordId, locationId } = notification.payload;
  const notificationTrayContext = useNotificationContext();

  const markRead = () =>
    notificationTrayContext.markCallRecordNotificationsAsRead(callRecordId, NotificationType.MISSED_CALL, locationId);

  const actions: NotificationActions = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
        markRead();
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New Missed Call')}
        body={
          <section
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            `}
          >
            <Text
              weight='bold'
              size='medium'
              css={css`
                margin: 0;
              `}
            >
              {isNaN(Number(personContact)) ? personContact : formatPhoneNumber(callerNumber)}
            </Text>
            <Text
              size='medium'
              css={css`
                margin: 0;
              `}
            >
              {t('Missed Call')}
            </Text>
          </section>
        }
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
