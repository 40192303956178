import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { ModalProps } from '@frontend/design-system';
import { RegisterTerminalModalSteps } from './hooks';
import { RegisterTerminalModalAllSteps, StepperProps, getStepperSteps } from './register-terminal-modal-all-steps';

interface RegisterTerminalsProps {
  modalProps: Omit<ModalProps, 'children'>;
  locationId: string | undefined;
  stripeLocationId: string | undefined;
  paymentsUrl: string | null;
  closeModal: () => void;
  onTerminalRegistered: () => void;
}

export const RegisterTerminals = ({
  modalProps,
  locationId,
  stripeLocationId,
  paymentsUrl,
  closeModal,
  onTerminalRegistered,
}: RegisterTerminalsProps) => {
  return (
    <MultiStepModal
      modalProps={modalProps}
      closeModal={closeModal}
      initialStep={RegisterTerminalModalSteps.InstallBattery}
      stepper={getStepperSteps()}
      maxWidth={parseFloat(theme.spacing(97))}
      stepperWidth={parseFloat(theme.spacing(20))}
      hideBackButton
    >
      <RegisterTerminalModalAllSteps
        onComplete={closeModal}
        onRegisterTerminal={onTerminalRegistered}
        Stepper={Stepper}
        paymentsUrl={paymentsUrl}
        locationId={locationId}
        stripeLocationId={stripeLocationId}
      />
    </MultiStepModal>
  );
};

const Stepper: React.FC<StepperProps> = ({ id, label, children }) => (
  <MultiStepModal.Step id={id.toString()} key={id} title={label}>
    {children}
  </MultiStepModal.Step>
);
